// import "./ContactUs.css";
// import { TfiEmail } from "react-icons/tfi";
// import { FaPhoneAlt } from "react-icons/fa";
// import { FaLocationDot } from "react-icons/fa6";
// import { FaFacebook } from "react-icons/fa";
// import { FaInstagram } from "react-icons/fa";
// import { IoLogoYoutube } from "react-icons/io";
// import { IoIosArrowForward } from "react-icons/io";
// import { useState } from "react";

// const ContactUs = () => {
//   const [status, setStatus] = useState("Submit");

//   const [info, setInfo] = useState({ name: '', subject: '', email: '',mobileNumber: '', message: '' });
  
//   const handleChange = (event) => {
//     setInfo({
//       ...info,
//       [event.target.name]: event.target.value
//     })
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     console.log(info);
//     setStatus("Sending...");

//     let response = await fetch(`/api/contact`, {
//       method: "POST",
//       headers: {
//         'Access-Control-Allow-Origin': '*',
//         'Accept': 'application/json',
//         //'Content-Type': 'application/json',
//         "Content-Type": "application/json;charset=utf-8",
//       },
//       body: JSON.stringify(info),
//       //body: JSON.stringify(details),
//     });
//     //console.log(details);
//     console.log(info);
//     setStatus("Submit");
//     setInfo({ name: '', subject: '', email: '',mobileNumber:'', message: '' });
//     let result = await response.json();
//     //alert(result.status);
//   };

//   return (
//     <div>
//       <section class="mr_page_title mr_index_index_title breadcrumb-area">
//         <div class="container">
//           <div class="row">
//             <div class="col-md-12">
//               <div class="breadcrumbs">
//                 <h1><b>CONTACT US</b></h1>
//               </div>
//             </div>
//           </div>
//         </div>
//         <div class="breadcrumb-botton">
//           <div class="container">
//             <div class="row">
//               <div class="col-md-12">
//                 <ul class="left pull-left">
//                   <li class="breadcrumb-item ">
//                     {" "}
//                     <a href="/">
//                       Home &nbsp;
//                     </a>
//                     <IoIosArrowForward/>
//                   </li>
//                   <li class="breadcrumb-item "> ContactUs</li>{" "}
//                 </ul>
               
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>

//       <div
//         data-elementor-type="wp-page"
//         data-elementor-id="522"
//         class="elementor elementor-522"
//         style={{marginTop:"20px"}}
//       >
//         <section
//           class="elementor-section elementor-top-section elementor-element elementor-element-7a4483c container elementor-section-boxed elementor-section-height-default elementor-section-height-default"
//           data-id="7a4483c"
//           data-element_type="section"
//         >
//           <div class="elementor-container elementor-column-gap-no">
//             <div
//               class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-9ce4a65"
//               data-id="9ce4a65"
//               data-element_type="column"
//             >
//               <div class="elementor-widget-wrap elementor-element-populated">
//                 <div
//                   class="elementor-element elementor-element-ae70617 elementor-widget elementor-widget-heading"
//                   data-id="ae70617"
//                   data-element_type="widget"
//                   data-widget_type="heading.default"
//                 >
//                   <div class="elementor-widget-container">
//                     <h2 class="elementor-heading-title elementor-size-default" style={{fontSize:"30px",marginTop:"30px",color:"black"}}>
//                       <b>Get In Touch</b>
//                     </h2>{" "}
//                   </div>
//                 </div>
//                 <div
//                   class="elementor-element elementor-element-8fc21ea elementor-widget-divider--view-line elementor-widget elementor-widget-divider"
//                   data-id="8fc21ea"
//                   data-element_type="widget"
//                   data-widget_type="divider.default"
//                 >
//                   <div class="elementor-widget-container">
//                     <div class="elementor-divider">
//                       <span class="elementor-divider-separator"></span>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </section>

//         <section
//           class="elementor-section elementor-top-section elementor-element elementor-element-17146b0 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
//           data-id="17146b0"
//           data-element_type="section"
//           data-settings='{"background_background":"classic"}'
//         >
//           <div class="elementor-container elementor-column-gap-no">
//             <div
//               class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-4ba5d84 responsive_contact_form"
//               data-id="4ba5d84"
//               data-element_type="column"
           
//             >
//               <div class="elementor-widget-wrap elementor-element-populated">
//                 <div
//                   class="elementor-element elementor-element-d28b34e elementor-widget elementor-widget-shortcode"
//                   data-id="d28b34e"
//                   data-element_type="widget"
//                   data-widget_type="shortcode.default"
//                 >
//                   <div class="elementor-widget-container">
//                     <div class="elementor-shortcode">
//                       <div
//                         class="wpcf7 no-js"
//                         id="wpcf7-f3111-p522-o1"
//                         lang="en-US"
//                         dir="ltr"
//                       >
//                         <div class="screen-reader-response">
//                           <p
//                             role="status"
//                             aria-live="polite"
//                             aria-atomic="true"
//                           ></p>{" "}
//                           <ul></ul>
//                         </div>



//                         <form
//                           action="/wp/industry/contact/#wpcf7-f3111-p522-o1"
//                           method="post"
//                           class="wpcf7-form init"
//                           aria-label="Contact form"
//                           novalidate="novalidate"
//                           data-status="init"
//                           onSubmit={handleSubmit}
//                         >
                         
//                           <section class="contact-form-area">
//                             <div class="container">
//                               <div class="row">
//                                 <div class="col-lg-12 col-md-12">
//                                   <div class="contact-form">
//                                     <div
//                                       id="contact-form"
//                                       name="contact_form"
//                                       class="default-form"
//                                     >
//                                       <h2></h2>
//                                       <div class="row">
//                                         <div class="col-md-6">
//                                           <p>
//                                             <span
//                                               class="wpcf7-form-control-wrap"
//                                               data-name="text-972"
//                                             >
//                                               <input
//                                                 size="40"
//                                                 class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
//                                                 aria-required="true"
//                                                 aria-invalid="false"
//                                                 placeholder="Your Name"
                                               
//                                                 type="text"
//                                                 name="name" id="name"
//                                                 pattern="/[A-Z a-z]/"
//                                                 value={info.name} onChange={handleChange}
//                                               />
//                                             </span>
//                                           </p>
//                                         </div>
//                                         <div class="col-md-6">
//                                           <p>
//                                             <span
//                                               class="wpcf7-form-control-wrap"
//                                               data-name="email-303"
//                                             >
//                                               <input
//                                                 size="40"
//                                                 class="wpcf7-form-control wpcf7-email wpcf7-validates-as-required wpcf7-text wpcf7-validates-as-email"
//                                                 aria-required="true"
//                                                 aria-invalid="false"
//                                                 placeholder="Your Mail*"
                                             
//                                                 type="email"
//                                                 name="email" id="email"
//                                                 pattern="/\S+@\S+\.\S+/"
//                                                 value={info.email} onChange={handleChange}
//                                               />
//                                             </span>
//                                           </p>
//                                         </div>
//                                       </div>
//                                       <div class="row">
//                                         <div class="col-md-6">
//                                           <p>
//                                             <span
//                                               class="wpcf7-form-control-wrap"
//                                               data-name="tel-491"
//                                             >
//                                               <input
//                                                 size="40"
//                                                 class="wpcf7-form-control wpcf7-tel wpcf7-validates-as-required wpcf7-text wpcf7-validates-as-tel"
//                                                 aria-required="true"
//                                                 aria-invalid="false"
//                                                 placeholder="Phone"
//                                                 value={info.mobileNumber}
//                                                 onChange={handleChange}
//                                                 pattern='/[0-9]{10}/'
//                                                 name="mobileNumber"
//                                                 id="mobileNumber"
//                                                 type="tel"
                                               
//                                               />
//                                             </span>
//                                           </p>
//                                         </div>
//                                         <div class="col-md-6">
//                                           <p>
//                                             <span
//                                               class="wpcf7-form-control-wrap"
//                                               data-name="text-347"
//                                             >
//                                               <input
//                                                 size="40"
//                                                 class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
//                                                 aria-required="true"
//                                                 aria-invalid="false"
//                                                 placeholder="Subject"
                                            
//                                                 type="text"
//                                                 name="subject" id="subject"
//                                                 value={info.subject} onChange={handleChange}
//                                               />
//                                             </span>
//                                           </p>
//                                         </div>
//                                       </div>
//                                       <div class="row">
//                                         <div class="col-md-12">
//                                           <p>
//                                             <span
//                                               class="wpcf7-form-control-wrap"
//                                               data-name="textarea-288"
//                                             >
//                                               <textarea
//                                                 cols="40"
//                                                 rows="10"
//                                                 class="wpcf7-form-control wpcf7-textarea wpcf7-validates-as-required"
//                                                 aria-required="true"
//                                                 aria-invalid="false"
//                                                 placeholder="Your Message.."
//                                                 name="message" id="message"
//                                                 value={info.message} onChange={handleChange}
//                                               ></textarea>
//                                             </span>
//                                           </p>
//                                         </div>
//                                       </div>
//                                       <div class="row">
//                                         <div class="col-md-12">
//                                           <p>
//                                             {/* <input
//                                               id="form_botcheck"
//                                               name="form_botcheck"
//                                               class="form-control"
//                                               type="hidden"
//                                               value=""
//                                             /> */}
                                           
                                            
//                                             <button className="thm-btn bg-clr1" type="submit" id="sendMessageButton">{status}</button>
//                                           </p>
//                                         </div>
//                                       </div>
//                                     </div>
//                                   </div>
//                                 </div>
//                               </div>
//                             </div>
//                           </section>
//                           <div
//                             class="wpcf7-response-output"
//                             aria-hidden="true"
//                           ></div>
//                         </form>




//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div
//               class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-5ba2f61 container"
//               data-id="5ba2f61"
//               data-element_type="column"
//               style={{marginLeft:"50px"}}
//             >
//               <div class="elementor-widget-wrap elementor-element-populated">
//                 <div
//                   class="elementor-element elementor-element-a9b4098 elementor-widget elementor-widget-heading"
//                   data-id="a9b4098"
//                   data-element_type="widget"
//                   data-widget_type="heading.default"
//                 >
//                   <div class="elementor-widget-container">
//                     <h2 class="elementor-heading-title elementor-size-default" style={{fontSize:"30px",color:"black"}}>
//                       <b>Quick Contact</b>
//                     </h2>{" "}
//                   </div>
//                 </div>
//                 <div
//                   class="elementor-element elementor-element-6a6ad70 elementor-widget elementor-widget-heading"
//                   data-id="6a6ad70"
//                   data-element_type="widget"
//                   data-widget_type="heading.default"
//                 >
//                   <div class="elementor-widget-container">
//                     <h2 class="elementor-heading-title elementor-size-default" style={{color:"black"}}>
//                       If you have any questions simply use the following contact
//                       details
//                     </h2>{" "}
//                   </div>
//                 </div>
//                 <div
//                   class="elementor-element elementor-element-db3ead1 elementor-position-left elementor-mobile-position-left elementor-view-default elementor-vertical-align-top elementor-widget elementor-widget-icon-box"
//                   data-id="db3ead1"
//                   data-element_type="widget"
//                   data-widget_type="icon-box.default"
//                 >
//                   <div class="elementor-widget-container">
//                     <div class="elementor-icon-box-wrapper">
//                       <div class="elementor-icon-box-icon">
//                         <a
//                           href="#"
//                           class="elementor-icon elementor-animation-"
//                           tabindex="-1"
//                         >
//                           <FaLocationDot style={{fontSize:"20px",marginTop:"5px",marginLeft:"14px"}}/>{" "}
//                         </a>
//                       </div>
//                       <div class="elementor-icon-box-content">
//                         <h3 class="elementor-icon-box-title" style={{color:"black"}}>
//                           <a href="/">Devasandra Industrial ITI Estate, Whitefield Road</a>
//                         </h3>
//                         <p class="elementor-icon-box-description" style={{color:"black"}}>
//                         Mahadevpura Post, Bangalore-560048{" "}
//                         </p>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//                 <div
//                   class="elementor-element elementor-element-c9f5cb2 elementor-position-left elementor-mobile-position-left elementor-view-default elementor-vertical-align-top elementor-widget elementor-widget-icon-box"
//                   data-id="c9f5cb2"
//                   data-element_type="widget"
//                   data-widget_type="icon-box.default"
//                 >
//                   <div class="elementor-widget-container">
//                     <div class="elementor-icon-box-wrapper">
//                       <div class="elementor-icon-box-icon">
//                         <a
//                           href=""
//                           class="elementor-icon elementor-animation-"
//                           tabindex="-1"
//                         >
//                           <FaPhoneAlt style={{fontSize:"20px",marginTop:"5px",marginLeft:"14px"}}/>{" "}
//                         </a>
//                       </div>
//                       <div class="elementor-icon-box-content">
//                         <h3 class="elementor-icon-box-title " style={{color:"black"}}>
//                           <a href="/">Phone: +91 9686976679 </a>
//                         </h3>
//                         <p class="elementor-icon-box-description">
                          
//                         </p>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//                 <div
//                   class="elementor-element elementor-element-245a670 elementor-position-left elementor-mobile-position-left elementor-view-default elementor-vertical-align-top elementor-widget elementor-widget-icon-box"
//                   data-id="245a670"
//                   data-element_type="widget"
//                   data-widget_type="icon-box.default"
//                 >
//                   <div class="elementor-widget-container">
//                     <div class="elementor-icon-box-wrapper">
//                       <div class="elementor-icon-box-icon">
//                         <a
//                           href=""
//                           class="elementor-icon elementor-animation-"
//                           tabindex="-1"
//                         >
                         
//                          <TfiEmail style={{fontSize:"20px",marginTop:"5px",marginLeft:"14px"}}/>{" "}
//                         </a>
//                       </div>
//                       <div class="elementor-icon-box-content">
//                         <h3 class="elementor-icon-box-title" style={{color:"black"}}>
//                           <a href="mailto:dieamanager@gmail.com" target="_black">Email: dieamanager@gmail.com </a>
//                         </h3>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//                 <div
//                   class="elementor-element elementor-element-1e50873 e-grid-align-left e-grid-align-mobile-left contact_social_icons elementor-shape-rounded elementor-grid-0 elementor-widget elementor-widget-social-icons"
//                   data-id="1e50873"
//                   data-element_type="widget"
//                   data-widget_type="social-icons.default"
//                 >
//                   <div class="elementor-widget-container">
//                     <div class="elementor-social-icons-wrapper elementor-grid">
//                       <span class="elementor-grid-item">
//                         <a
//                           class="elementor-icon elementor-social-icon elementor-social-icon-facebook-f elementor-animation-grow elementor-repeater-item-0584344"
//                           href="#"
//                           target="_blank"
//                         >
//                           <span class="elementor-screen-only">Facebook-f</span>
//                           <FaFacebook style={{fontSize:"18px",marginTop:"14px",marginLeft:"14px"}}/>{" "}
//                         </a>
//                       </span>

//                       <span class="elementor-grid-item">
//                         <a
//                           class="elementor-icon elementor-social-icon elementor-social-icon-twitter elementor-animation-grow elementor-repeater-item-8b41a52"
//                           href="#"
//                           target="_blank"
//                         >
//                           <span class="elementor-screen-only">Instagram</span>
//                           <FaInstagram style={{fontSize:"18px",marginTop:"14px",marginLeft:"14px"}}/>{" "}
//                         </a>
//                       </span>
                     
                     
//                       <span class="elementor-grid-item">
//                         <a
//                           class="elementor-icon elementor-social-icon elementor-social-icon-youtube elementor-animation-grow elementor-repeater-item-97b05c3"
//                           href="#"
//                           target="_blank"
//                         >
//                           <span class="elementor-screen-only">Youtube</span>
//                           <IoLogoYoutube  style={{fontSize:"18px",marginTop:"14px",marginLeft:"14px"}}/>{" "}
//                         </a>
//                       </span>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </section>

//         <section
//           class="elementor-section elementor-top-section elementor-element elementor-element-29b6dfd elementor-section-full_width elementor-section-height-default elementor-section-height-default"
//           data-id="29b6dfd"
//           data-element_type="section"
//         >
//           <div class="elementor-container elementor-column-gap-no">
//             <div
//               class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-ed90f3e"
//               data-id="ed90f3e"
//               data-element_type="column"
//             >
//               <div class="elementor-widget-wrap elementor-element-populated">
//                 <div
//                   class="elementor-element elementor-element-cec98cf elementor-widget elementor-widget-google_maps"
//                   data-id="cec98cf"
//                   data-element_type="widget"
//                   data-widget_type="google_maps.default"
//                 >
//                   <div class="elementor-widget-container">
//                     <div class="elementor-custom-embed">
//                       <iframe
//                         loading="lazy"
//                         src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15550.342886599576!2d77.6986314!3d12.9983282!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae110a8e81a2a1%3A0x10adfaa3a1cfa278!2sITI%20Industrial%20Estate!5e0!3m2!1sen!2sin!4v1710495417715!5m2!1sen!2sin"
//                         title="Devasandra Industrial ITI Estate Whitefield Road, Mahadevpura Post,
//                         Bangalore-560048"
//                         aria-label="Devasandra Industrial ITI Estate Whitefield Road, Mahadevpura Post,
//                         Bangalore-560048"
//                       ></iframe>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </section>
//       </div>
//     </div>
//   );
// };

// export default ContactUs;

import "./ContactUs.css";
import { TfiEmail } from "react-icons/tfi";
import { FaPhoneAlt } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { FaFacebook } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { IoLogoYoutube } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import { useState } from "react";
import  { useNavigate } from "react-router-dom";

const ContactUs = () => {
  const [status, setStatus] = useState("Submit");
  const [info, setInfo] = useState({ name: '', subject: '', email: '', mobileNumber: '', message: '' });
  const [errors, setErrors] = useState({});
  const navigate=useNavigate()

  const handleChange = (event) => {
    setInfo({
      ...info,
      [event.target.name]: event.target.value
    });
  };

  const validateForm = () => {
    let formErrors = {};
    if (!info.name.trim()) formErrors.name = "Name is required";
    if (!info.email) {
      formErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(info.email)) {
      formErrors.email = "Email address is invalid";
    }
    if (!info.mobileNumber) {
      formErrors.mobileNumber = "Phone number is required";
    } else if (!/^[0-9]{10}$/.test(info.mobileNumber)) {
      formErrors.mobileNumber = "Phone number is invalid";
    }
    if (!info.subject.trim()) formErrors.subject = "Subject is required";
    if (!info.message.trim()) formErrors.message = "Message is required";

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setStatus("Sending...");
    let response = await fetch(`/api/contact`, {
      method: "POST",
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Accept': 'application/json',
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(info),
    });

    setStatus("Submit");
    setInfo({ name: '', subject: '', email: '', mobileNumber: '', message: '' });
    let result = await response.json();
    navigate("/ThankYou");
    //alert(result.status);
  };

  return (
    <div>
      <section className="mr_page_title mr_index_index_title breadcrumb-area">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="breadcrumbs">
                <h1><b>CONTACT US</b></h1>
              </div>
            </div>
          </div>
        </div>
        <div className="breadcrumb-botton">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <ul className="left pull-left">
                  <li className="breadcrumb-item">
                    <a href="/">
                      Home &nbsp;
                    </a>
                    <IoIosArrowForward />
                  </li>
                  <li className="breadcrumb-item"> ContactUs</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="elementor elementor-522" style={{ marginTop: "20px" }}>
        <section className="elementor-section elementor-top-section elementor-element container elementor-section-boxed elementor-section-height-default elementor-section-height-default">
          <div className="elementor-container elementor-column-gap-no">
            <div className="elementor-column elementor-col-100 elementor-top-column elementor-element">
              <div className="elementor-widget-wrap elementor-element-populated">
                <div className="elementor-element elementor-widget elementor-widget-heading">
                  <div className="elementor-widget-container">
                    <h2 className="elementor-heading-title elementor-size-default" style={{ fontSize: "30px", marginTop: "30px", color: "black" }}>
                      <b>Get In Touch</b>
                    </h2>
                  </div>
                </div>
                <div className="elementor-element elementor-widget-divider--view-line elementor-widget elementor-widget-divider">
                  <div className="elementor-widget-container">
                    <div className="elementor-divider">
                      <span className="elementor-divider-separator"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="elementor-section elementor-top-section elementor-element elementor-section-boxed elementor-section-height-default elementor-section-height-default">
          <div className="elementor-container elementor-column-gap-no">
            <div className="elementor-column elementor-col-50 elementor-top-column elementor-element responsive_contact_form">
              <div className="elementor-widget-wrap elementor-element-populated">
                <div className="elementor-element elementor-widget elementor-widget-shortcode">
                  <div className="elementor-widget-container">
                    <div className="elementor-shortcode">
                      <div className="wpcf7 no-js" id="wpcf7-f3111-p522-o1" lang="en-US" dir="ltr">
                        <div className="screen-reader-response">
                          <p role="status" aria-live="polite" aria-atomic="true"></p>
                          <ul></ul>
                        </div>
                        {status !== "Submitted" && (
                        <form className="wpcf7-form init" aria-label="Contact form" novalidate="novalidate" data-status="init" onSubmit={handleSubmit}>
                          <section className="contact-form-area">
                            <div className="container">
                              <div className="row">
                                <div className="col-lg-12 col-md-12">
                                  <div className="contact-form">
                                    <div id="contact-form" name="contact_form" className="default-form">
                                      <h2></h2>
                                      <div className="row">
                                        <div className="col-md-6">
                                          <p>
                                            <input
                                              size="40"
                                              className={`wpcf7-form-control wpcf7-text wpcf7-validates-as-required ${errors.name ? 'is-invalid' : ''}`}
                                              aria-required="true"
                                              aria-invalid="false"
                                              placeholder="Your Name"
                                              type="text"
                                              name="name" id="name"
                                              value={info.name} onChange={handleChange}
                                            />
                                            {errors.name && <div className="invalid-feedback">{errors.name}</div>}
                                          </p>
                                        </div>
                                        <div className="col-md-6">
                                          <p>
                                            <input
                                              size="40"
                                              className={`wpcf7-form-control wpcf7-email wpcf7-validates-as-required wpcf7-text wpcf7-validates-as-email ${errors.email ? 'is-invalid' : ''}`}
                                              aria-required="true"
                                              aria-invalid="false"
                                              placeholder="Your Mail*"
                                              type="email"
                                              name="email" id="email"
                                              value={info.email} onChange={handleChange}
                                            />
                                            {errors.email && <div className="invalid-feedback">{errors.email}</div>}
                                          </p>
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col-md-6">
                                          <p>
                                            <input
                                              size="40"
                                              className={`wpcf7-form-control wpcf7-tel wpcf7-validates-as-required wpcf7-text wpcf7-validates-as-tel ${errors.mobileNumber ? 'is-invalid' : ''}`}
                                              aria-required="true"
                                              aria-invalid="false"
                                              placeholder="Phone"
                                              value={info.mobileNumber}
                                              onChange={handleChange}
                                              name="mobileNumber"
                                              id="mobileNumber"
                                              type="tel"
                                            />
                                            {errors.mobileNumber && <div className="invalid-feedback">{errors.mobileNumber}</div>}
                                          </p>
                                        </div>
                                        <div className="col-md-6">
                                          <p>
                                            <input
                                              size="40"
                                              className={`wpcf7-form-control wpcf7-text wpcf7-validates-as-required ${errors.subject ? 'is-invalid' : ''}`}
                                              aria-required="true"
                                              aria-invalid="false"
                                              placeholder="Subject"
                                              type="text"
                                              name="subject" id="subject"
                                              value={info.subject} onChange={handleChange}
                                            />
                                            {errors.subject && <div className="invalid-feedback">{errors.subject}</div>}
                                          </p>
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col-md-12">
                                          <p>
                                            <textarea
                                              cols="40"
                                              rows="10"
                                              className={`wpcf7-form-control wpcf7-textarea wpcf7-validates-as-required ${errors.message ? 'is-invalid' : ''}`}
                                              aria-required="true"
                                              aria-invalid="false"
                                              placeholder="Your Message"
                                              name="message" id="message"
                                              value={info.message} onChange={handleChange}
                                            ></textarea>
                                            {errors.message && <div className="invalid-feedback">{errors.message}</div>}
                                          </p>
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col-md-12">
                                          <p>
                                          <button className="thm-btn bg-clr1" type="submit" id="sendMessageButton">{status}</button>
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                        </form>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-5ba2f61 container"
              data-id="5ba2f61"
              data-element_type="column"
              style={{marginLeft:"50px"}}
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <div
                  class="elementor-element elementor-element-a9b4098 elementor-widget elementor-widget-heading"
                  data-id="a9b4098"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div class="elementor-widget-container">
                    <h2 class="elementor-heading-title elementor-size-default" style={{fontSize:"30px",color:"black"}}>
                      <b>Quick Contact</b>
                    </h2>{" "}
                  </div>
                </div>
                <div
                  class="elementor-element elementor-element-6a6ad70 elementor-widget elementor-widget-heading"
                  data-id="6a6ad70"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div class="elementor-widget-container">
                    <h2 class="elementor-heading-title elementor-size-default" style={{color:"black"}}>
                      If you have any questions simply use the following contact
                      details
                    </h2>{" "}
                  </div>
                </div>
                <div
                  class="elementor-element elementor-element-db3ead1 elementor-position-left elementor-mobile-position-left elementor-view-default elementor-vertical-align-top elementor-widget elementor-widget-icon-box"
                  data-id="db3ead1"
                  data-element_type="widget"
                  data-widget_type="icon-box.default"
                >
                  <div class="elementor-widget-container">
                    <div class="elementor-icon-box-wrapper">
                      <div class="elementor-icon-box-icon">
                        <a
                          href="#"
                          class="elementor-icon elementor-animation-"
                          tabindex="-1"
                        >
                          <FaLocationDot style={{fontSize:"20px",marginTop:"5px",marginLeft:"14px"}}/>{" "}
                        </a>
                      </div>
                      <div class="elementor-icon-box-content">
                        <h3 class="elementor-icon-box-title" style={{color:"black"}}>
                          <a href="/">Devasandra Industrial ITI Estate, Whitefield Road</a>
                        </h3>
                        <p class="elementor-icon-box-description" style={{color:"black"}}>
                        Mahadevpura Post, Bangalore-560048{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="elementor-element elementor-element-c9f5cb2 elementor-position-left elementor-mobile-position-left elementor-view-default elementor-vertical-align-top elementor-widget elementor-widget-icon-box"
                  data-id="c9f5cb2"
                  data-element_type="widget"
                  data-widget_type="icon-box.default"
                >
                  <div class="elementor-widget-container">
                    <div class="elementor-icon-box-wrapper">
                      <div class="elementor-icon-box-icon">
                        <a
                          href=""
                          class="elementor-icon elementor-animation-"
                          tabindex="-1"
                        >
                          <FaPhoneAlt style={{fontSize:"20px",marginTop:"5px",marginLeft:"14px"}}/>{" "}
                        </a>
                      </div>
                      <div class="elementor-icon-box-content">
                        <h3 class="elementor-icon-box-title " style={{color:"black"}}>
                          <a href="/">Phone: +91 9686976679 </a>
                        </h3>
                        <p class="elementor-icon-box-description">
                          
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="elementor-element elementor-element-245a670 elementor-position-left elementor-mobile-position-left elementor-view-default elementor-vertical-align-top elementor-widget elementor-widget-icon-box"
                  data-id="245a670"
                  data-element_type="widget"
                  data-widget_type="icon-box.default"
                >
                  <div class="elementor-widget-container">
                    <div class="elementor-icon-box-wrapper">
                      <div class="elementor-icon-box-icon">
                        <a
                          href=""
                          class="elementor-icon elementor-animation-"
                          tabindex="-1"
                        >
                         
                         <TfiEmail style={{fontSize:"20px",marginTop:"5px",marginLeft:"14px"}}/>{" "}
                        </a>
                      </div>
                      <div class="elementor-icon-box-content">
                        <h3 class="elementor-icon-box-title" style={{color:"black"}}>
                          <a href="mailto:dieamanager@gmail.com" target="_black">Email: dieamanager@gmail.com </a>
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="elementor-element elementor-element-1e50873 e-grid-align-left e-grid-align-mobile-left contact_social_icons elementor-shape-rounded elementor-grid-0 elementor-widget elementor-widget-social-icons"
                  data-id="1e50873"
                  data-element_type="widget"
                  data-widget_type="social-icons.default"
                >
                  <div class="elementor-widget-container">
                    <div class="elementor-social-icons-wrapper elementor-grid">
                      <span class="elementor-grid-item">
                        <a
                          class="elementor-icon elementor-social-icon elementor-social-icon-facebook-f elementor-animation-grow elementor-repeater-item-0584344"
                          href="#"
                          target="_blank"
                        >
                          <span class="elementor-screen-only">Facebook-f</span>
                          <FaFacebook style={{fontSize:"18px",marginTop:"14px",marginLeft:"14px"}}/>{" "}
                        </a>
                      </span>

                      <span class="elementor-grid-item">
                        <a
                          class="elementor-icon elementor-social-icon elementor-social-icon-twitter elementor-animation-grow elementor-repeater-item-8b41a52"
                          href="#"
                          target="_blank"
                        >
                          <span class="elementor-screen-only">Instagram</span>
                          <FaInstagram style={{fontSize:"18px",marginTop:"14px",marginLeft:"14px"}}/>{" "}
                        </a>
                      </span>
                     
                     
                      <span class="elementor-grid-item">
                        <a
                          class="elementor-icon elementor-social-icon elementor-social-icon-youtube elementor-animation-grow elementor-repeater-item-97b05c3"
                          href="#"
                          target="_blank"
                        >
                          <span class="elementor-screen-only">Youtube</span>
                          <IoLogoYoutube  style={{fontSize:"18px",marginTop:"14px",marginLeft:"14px"}}/>{" "}
                        </a>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          class="elementor-section elementor-top-section elementor-element elementor-element-29b6dfd elementor-section-full_width elementor-section-height-default elementor-section-height-default"
          data-id="29b6dfd"
          data-element_type="section"
        >
          <div class="elementor-container elementor-column-gap-no">
            <div
              class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-ed90f3e"
              data-id="ed90f3e"
              data-element_type="column"
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <div
                  class="elementor-element elementor-element-cec98cf elementor-widget elementor-widget-google_maps"
                  data-id="cec98cf"
                  data-element_type="widget"
                  data-widget_type="google_maps.default"
                >
                  <div class="elementor-widget-container">
                    <div class="elementor-custom-embed">
                      <iframe
                        loading="lazy"
                        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15550.342886599576!2d77.6986314!3d12.9983282!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae110a8e81a2a1%3A0x10adfaa3a1cfa278!2sITI%20Industrial%20Estate!5e0!3m2!1sen!2sin!4v1710495417715!5m2!1sen!2sin"
                        title="Devasandra Industrial ITI Estate Whitefield Road, Mahadevpura Post,
                        Bangalore-560048"
                        aria-label="Devasandra Industrial ITI Estate Whitefield Road, Mahadevpura Post,
                        Bangalore-560048"
                      ></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default ContactUs;


