import React from 'react'
import About from '../Components/About';
import News from '../Components/News';
import Testimonial from '../Components/Testimonial';
import Topheader from '../Components/Topheader';
import Welcome from '../Components/Welcome';
import Slider from '../Components/Slider'
import Footer from '../Components/Footer'


const Home = () => {
    return (
        
        <div>
          
            <Slider/>
            <Welcome />
            
            
        </div>
        
    )
}

export default Home
