// import React, { useState, useEffect } from 'react';
// import { FaArrowUp } from "react-icons/fa";
// import { CiSearch } from "react-icons/ci";
// import './Companies.css';
// import './Admin.css'
// import './w.css'

// const tableHeadings = ["Serial No.", "Company", "CEO", "Phone", "Email", "Website"];

// function Companies() {
//   const [users, setUsers] = useState([]);
//   const [searchTerm, setSearchTerm] = useState("");

//   useEffect(() => {
//     fetch('/company')
//       .then(response => response.json())
//       .then(data => setUsers(data))
//       .catch(error => console.error('Error fetching users:', error));
//   }, []);

//   const handleSearch = (e) => {
//     setSearchTerm(e.target.value.toLowerCase());
//   };


//   const filteredUsers = users.filter(user =>
//     (user.company_name && user.company_name.toLowerCase().includes(searchTerm)) ||
//     (user.CEO && user.CEO.toLowerCase().includes(searchTerm)) ||
//     (user.Phone && user.Phone.includes(searchTerm)) ||
//     (user.Email && user.Email.toLowerCase().includes(searchTerm)) ||
//     (user.website_link && user.website_link.toLowerCase().includes(searchTerm))
//   );

  
//   const sortedUsers = filteredUsers.slice().sort((a, b) => a.company_name.localeCompare(b.company_name));

//   return (
//     <main className="table " id="customers_table" >
//       <section className="table__header" style={{textAlign:'center'}}>
//         <h1 style={{ marginLeft: '35px'}}>DIEA COMPANIES LIST </h1>
//         <div className="input-group" style={{justifyContent:"end"}}>
//           <input type="search" placeholder="Search..." onChange={handleSearch} style={{marginTop:"0px"}} value={searchTerm}/>
//           <CiSearch style={{marginTop:"-30px"}}/>
//         </div>
//       </section>

//       <section className="table-responsive responsive_w">
//         <table className="table table-bordered" style={{ width: '100%' }}>
//           <thead>
//             <tr>
//               {tableHeadings.map((heading, index) => (
//                 <th key={index} >{heading}<FaArrowUp/></th>
//               ))}
//             </tr>
//           </thead>

//           <tbody>
//             {sortedUsers.map((user, index) => (
//               <tr key={user._id} style={{ backgroundColor: index % 2 === 0 ? "transparent" : "#0000000b" ,fontWeight:"bold"}}>
//                 <td>{index + 1}</td>
//                 <td className='responsive_company'>{user.company_name}</td>
//                 <td>{user.CEO}</td>
//                 <td>{user.Phone}</td>
//                 <td>{user.Email}</td>
//                 <td>
//                   <a href={user.website_link} target="_blank" rel="noopener noreferrer">
//                     {user.website_link}
//                   </a>
//                 </td>
//               </tr>
//             ))}
//           </tbody>
//         </table>
//       </section>
//     </main>
//   );
// }

// export default Companies;







import React, { useState, useEffect } from 'react';
import { FaArrowUp } from "react-icons/fa";
import { CiSearch } from "react-icons/ci";
import './w.css'

const tableHeadings = ["S.No.", "shed.No", "Company", "CEO", "Phone", "Email", "Website"];

function Companies() {
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortOrder, setSortOrder] = useState('asc');
  const [sortColumn, setSortColumn] = useState('company_name');

  useEffect(() => {
    fetch('/company')
      .then(response => response.json())
      .then(data => setUsers(data))
      .catch(error => console.error('Error fetching users:', error));
  }, []);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  const handleSort = (column) => {
    if (column === sortColumn) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortOrder('asc');
    }
  };

  const sortedUsers = users
    .filter(user =>
      (user.company_name && user.company_name.toLowerCase().includes(searchTerm)) ||
      (user.CEO && user.CEO.toLowerCase().includes(searchTerm)) ||
      (user.Phone && user.Phone.includes(searchTerm)) ||
      (user.Email && user.Email.toLowerCase().includes(searchTerm)) ||
      (user.website_link && user.website_link.toLowerCase().includes(searchTerm))
    )
    .slice()
    .sort((a, b) => {
      if (sortColumn === 'company_name') {
        if (sortOrder === 'asc') {
          return a.company_name.localeCompare(b.company_name);
        } else {
          return b.company_name.localeCompare(a.company_name);
        }
      } else {
        return 0; // No sorting for other columns
      }
    });

  return (
    <main className="table " id="customers_table" >
      <section className="table__header" style={{textAlign:'center'}}>
        <h1 style={{ marginLeft: '570px'}}>DIEA COMPANIES LIST </h1>
        <div class="elementor-element elementor-element-b88e1b2 elementor-widget-divider--view-line elementor-widget elementor-widget-divider" data-id="b88e1b2" data-element_type="widget" data-widget_type="divider.default"><div class="elementor-widget-container"><span class="elementor-divider-separator"></span></div></div>
        <div className="input-group" style={{justifyContent:"end"}}>
          <input type="search" placeholder="Search..." onChange={handleSearch} style={{marginTop:"0px"}} value={searchTerm}/>
          <CiSearch style={{marginTop:"-30px"}}/>
        </div>
      </section>

      <section className="table-responsive">
        <table className="table table-bordered" style={{ width: '100%' }}>
          <thead>
            <tr>
              {tableHeadings.map((heading, index) => (
                <th key={index} onClick={() => handleSort(index === 2 ? 'company_name' : null)}>
                  {heading}
                  {index === 2 && <FaArrowUp style={{ transform: sortOrder === 'asc' ? 'rotate(180deg)' : 'none' }} />}
                </th>
              ))}
            </tr>
          </thead>

          <tbody>
            {sortedUsers.map((user, index) => (
              <tr key={user._id} style={{ backgroundColor: index % 2 === 0 ? "transparent" : "#0000000b" ,fontWeight:"bold"}}>
                <td style={{width:"100px"}}>{index + 1}</td>
                <td>{user.shedNo}</td>
                <td className='responsive_company'>{user.company_name}</td>
                <td>{user.CEO}</td>
                <td>{user.Phone}</td>
                <td>{user.Email}</td>
                <td>
                  <a href={user.website_link} target="_blank" rel="noopener noreferrer">
                    {user.website_link}
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </section>
    </main>
  );
}

export default Companies;