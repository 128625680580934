import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
const NavBar = ({ loggedIn, onLogout, onLogin }) => {
  const [isSticky, setIsSticky] = useState(false);
  useEffect(() => {
    function mainmenu() {
      if ($(".main-menu li.dropdown ul").length) {
        $(".main-menu li.dropdown").append("<div class='dropdown-btn'></div>");

        $(".main-menu li.dropdown .dropdown-btn").on("click", function () {
          $(this).prev("ul").slideToggle(500);
        });
      }
    }
    mainmenu();

    function stickyHeader() {
      if ($(".stricky").length) {
        const strickyScrollPos = 100;
        $(window).on("scroll", function () {
          if ($(this).scrollTop() > strickyScrollPos) {
            setIsSticky(true);
          } else {
            setIsSticky(false);
          }
        });
      }
    }
    stickyHeader();

    return () => {
      // Clean-up code if needed
      $(window).off("scroll");
    };
  }, []);

  function scrollToTop() {
    // Implement scrollToTop function if needed
  }

  return (
    <div>
      <section
        class="elementor-section elementor-top-section elementor-element elementor-element-40d094d elementor-section-boxed elementor-section-height-default elementor-section-height-default"
        data-id="40d094d"
        data-element_type="section"
        data-settings='{"background_background":"classic"}'
      >
        <div class="elementor-container elementor-column-gap-no" style={{maxWidth:"1800px"}}>
          <div
            class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-1ae20b9"
            data-id="1ae20b9"
            data-element_type="column"
          >
            <div class="elementor-widget-wrap elementor-element-populated">
              <div
                class="elementor-element elementor-element-8d4acf8 elementor-widget__width-initial elementor-widget elementor-widget-wpsection_menu"
                data-id="8d4acf8"
                data-element_type="widget"
                data-widget_type="wpsection_menu.default"
              >
                <div class="elementor-widget-container">
                  <section class="mainmenu-area stricky">
                    <div class="container">
                      <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                          <nav class="main-menu pull-left">
                            <div class="navbar-header">
                              <button
                                type="button"
                                class="navbar-toggle"
                                data-toggle="collapse"
                                data-target=".navbar-collapse"
                              >
                                <span class="icon-bar"></span>
                                <span class="icon-bar"></span>
                                <span class="icon-bar"></span>
                              </button>
                            </div>
                            <div class="navbar-collapse collapse clearfix">
                              <ul class="navigation clearfix">
                                
                                  {" "}
                                  <li
                                    id="menu-item-465"
                                    class="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-17 current_page_item menu-item-465 active"
                                  >
                                    <a
                                      title="HOME"
                                      href="/"
                                      class="hvr-underline-from-left1"
                                      data-scroll
                                      data-options="easing: easeOutQuart"
                                    >
                                      HOME
                                    </a>
                                  </li>
                               
                          
                                 
                                <li
                                  id="menu-item-667"
                                  class="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-667 dropdown"
                                >
                                  <Link
                                    title="ABOUTUS"
                                    to="/about"
                                    data-toggle="dropdown1"
                                    class="hvr-underline-from-left1"
                                   
                                    data-scroll
                                    data-options="easing: easeOutQuart"
                                  >
                                    AboutUs
                                  </Link>
                                </li>

                               

                                <li
                                  id="menu-item-513"
                                  class="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-513 dropdown"
                                >
                                  <Link
                                    title="COMPANIES"
                                    to="/Companies"
                                    data-toggle="dropdown1"
                                    class="hvr-underline-from-left1"
                                    
                                    data-scroll
                                    data-options="easing: easeOutQuart"
                                  >
                                    Companies
                                  </Link>
                                </li>
                                <li
                                  id="menu-item-667"
                                  class="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-667 dropdown"
                                >
                                  <Link
                                    title="SERVICES"
                                    to="/Solution"
                                    data-toggle="dropdown1"
                                    class="hvr-underline-from-left1"
                                   
                                    data-scroll
                                    data-options="easing: easeOutQuart"
                                  >
                                    Services
                                  </Link>
                                </li>

                                <li
                                  id="menu-item-526"
                                  class="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-526 dropdown"
                                >
                                  <Link
                                    title="GALLERY"
                                    to="/Gallery"
                                    data-toggle="dropdown1"
                                    class="hvr-underline-from-left1"
                                   
                                    data-scroll
                                    data-options="easing: easeOutQuart"
                                  >
                                    Gallery
                                  </Link>
                                </li>

                                

                                <li
                                  id="menu-item-524"
                                  class="menu-item menu-item-type-post_type menu-item-object-page menu-item-524"
                                >
                                  <Link
                                    title="CONTACT US"
                                    to="/ContactUs"
                                    class="hvr-underline-from-left1"
                                    data-scroll
                                    data-options="easing: easeOutQuart"
                                  >
                                    CONTACT US
                                  </Link>
                                </li>

                                <li
                                   id="menu-item-524"
                                   class="menu-item menu-item-type-post_type menu-item-object-page menu-item-524"
                                 >
                                
                                {loggedIn && (
                                     <>
                                   
                                         <Link to="/admin" className="nav-link hvr-underline-from-left1" data-scroll data-options="easing: easeOutQuart">
                                           admin panel
                                         </Link>
                                       
                                    </>
                                  )}

                                   </li>


                              </ul>
                            </div>
                          </nav>

                          <div class="mainmenu-right-box pull-right">

                            <div class="quote-button">
                              {/* <a href="#">
                                <i class="fas fa-share" aria-hidden="true"></i>
                                Request Quote
                              </a> */}
                            </div>

                            <div class="search-option">
                              <div class="outer-search-box">
                                <div class="seach-toggle">

                                <div className="navbar-nav ml-auto">
                                  {loggedIn && (
                                    <>
                                     
                                      <li  class="responsive_nav responsive_my_nav">
                                         <Link
                                           to="/"
                                          onClick={onLogout}
                                          className="nav-link "
                                        >
                                           <b>Logout</b>
                                         </Link>
                                       </li>
                                     </>
                                   )}
                                  {!loggedIn && (
                                    <li class="responsive_nav" style={{paddingRight:"10px"}}>
                                      <Link to="/Login" className="nav-link">
                                         <b style={{marginLeft:'-6px'}}>Admin</b>
                                      </Link>
                                     
                                     </li>
                                   )}
                                 </div>
                                </div>

                                <ul class="search-box">
                                  <li>
                                    <form
                                      method="post"
                                      action="http://st.ourhtmldemo.com/new/Industry-Preview/index.html"
                                    >
                                      <div class="form-group">
                                        <input
                                          type="search"
                                          name="search"
                                          placeholder="Search Here"
                                          required
                                        />
                                        <button type="submit">
                                          <i class="fas fa-search"></i>
                                        </button>
                                      </div>
                                    </form>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default NavBar;
