import React from 'react'
import './Team.css'

const Team = () => {
  return (
    <div>
         <div data-elementor-type="wp-page" data-elementor-id="2841" class="elementor elementor-2841">


         <section
                class="elementor-section elementor-top-section elementor-element elementor-element-bb58afb elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                data-id="bb58afb" data-element_type="section">
                <div class="elementor-container elementor-column-gap-default">
                    <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-3eb3e01"
                        data-id="3eb3e01" data-element_type="column">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <div class="elementor-element elementor-element-16e60b2 elementor-widget elementor-widget-heading"
                                data-id="16e60b2" data-element_type="widget" data-widget_type="heading.default">
                                <div class="elementor-widget-container">
                                    <h2 class="elementor-heading-title elementor-size-default">Meet DIEA OFFICE BEARERS</h2>
                                   
                                </div>
                                
                            </div>
                            <div class="elementor-element elementor-element-b88e1b2 elementor-widget-divider--view-line elementor-widget elementor-widget-divider"
                                data-id="b88e1b2" data-element_type="widget" data-widget_type="divider.default">
                                <div class="elementor-widget-container">
                                    <div class="elementor-divider">
                                        <span class="elementor-divider-separator">
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
            <section
                class="elementor-section elementor-top-section elementor-element elementor-element-600cd7b container elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                data-id="600cd7b" data-element_type="section"
                data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                <div class="elementor-container elementor-column-gap-no">

                    <div class="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-59665e7"
                        data-id="59665e7" data-element_type="column">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <div class="elementor-element elementor-element-f21aaf9 elementor-widget elementor-widget-wpsection_team_service"
                                data-id="f21aaf9" data-element_type="widget"
                                data-widget_type="wpsection_team_service.default">
                                <div class="elementor-widget-container">

                                    <section class="team-area">
                                    <div class="card_team">
                                            <div class="card__content_team">
                                            
                                            <div class="col-md-12 col-sm-12 col-xs-12">
                                                <div class="single-team-member hvr-float-shadow text-center">
                                                    <div class="img-holder" style={{borderRadius:"20px"}}>
                                                        <img decoding="async"
                                                            src="./images/Ram.jpeg"
                                                            alt="Awesome Image" style={{height:"200px"}} />
                                                       
                                                    </div>
                                                    <div class="text-holder">
                                                        <div class="top">
                                                            <h3>Sri Ramesh Menon P</h3>
                                                            <h3>President</h3>
                                                           
                                                        </div>
                                                       
                                                    </div>
                                                </div>
                                            </div>
                                            </div></div>
                                    </section>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-86f80a5"
                        data-id="86f80a5" data-element_type="column">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <div class="elementor-element elementor-element-d86dad5 elementor-widget elementor-widget-wpsection_team_service"
                                data-id="d86dad5" data-element_type="widget"
                                data-widget_type="wpsection_team_service.default">
                                <div class="elementor-widget-container">
                                    <section class="team-area">
                                    <div class="card_team">
                                            <div class="card__content_team">
                                      
                                            <div class="col-md-12 col-sm-12 col-xs-12">
                                                <div class="single-team-member hvr-float-shadow text-center">
                                                    <div class="img-holder" style={{borderRadius:"20px"}}>
                                                        <img decoding="async"
                                                            src="./images/sheshi.jpeg"
                                                            alt="Awesome Image" style={{height:"200px"}}/>
                                                        {/* <div class="overlay-style-one">
                                                            
                                                        </div> */}
                                                    </div>
                                                    <div class="text-holder">
                                                        <div class="top">
                                                            <h3>Sri Sheshadri Gowda K</h3>
                                                            <h3>Vice-President</h3>
                                                           
                                                        </div>
                                                       
                                                    </div>
                                                </div>
                                            </div>
                                            </div></div>
                                    </section>




                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-10d07e9"
                        data-id="10d07e9" data-element_type="column">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <div class="elementor-element elementor-element-12a19bb elementor-widget elementor-widget-wpsection_team_service"
                                data-id="12a19bb" data-element_type="widget"
                                data-widget_type="wpsection_team_service.default">
                                <div class="elementor-widget-container">
                                    <section class="team-area">
                                    <div class="card_team">
                                            <div class="card__content_team">
                                            <div class="col-md-12 col-sm-12 col-xs-12">
                                                <div class="single-team-member hvr-float-shadow text-center">
                                                    <div class="img-holder" style={{borderRadius:"20px"}}>
                                                        <img decoding="async"
                                                            src="./images/sheriff.jpeg"
                                                            alt="Awesome Image" style={{height:"200px"}}/>
                                                        {/* <div class="overlay-style-one">
                                                            
                                                        </div> */}
                                                    </div>
                                                    <div class="text-holder">
                                                        <div class="top">
                                                            <h3>A A Sheriff </h3>
                                                            <h3>Immediate Past President</h3>
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div></div>
                                    </section>




                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-4afd489"
                        data-id="4afd489" data-element_type="column">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <div class="elementor-element elementor-element-36df10d elementor-widget elementor-widget-wpsection_team_service"
                                data-id="36df10d" data-element_type="widget"
                                data-widget_type="wpsection_team_service.default">
                                <div class="elementor-widget-container">
                                    <section class="team-area">
                                    <div class="card_team">
                                            <div class="card__content_team">
                                            <div class="col-md-12 col-sm-12 col-xs-12">
                                                <div class="single-team-member hvr-float-shadow text-center">
                                                    <div class="img-holder" style={{borderRadius:"20px"}}>
                                                        <img decoding="async"
                                                            src="./images/gopi.jpeg"
                                                            alt="Awesome Image"   style={{height:"200px"}}/>
                                                        {/* <div class="overlay-style-one">
                                                            
                                                        </div> */}
                                                    </div>
                                                    <div class="text-holder">
                                                        <div class="top">
                                                            <h3>Sri Gopinathan N C</h3>
                                                            <h3>Hon.Secretary</h3>
                                                          
                                                        </div>
                                                        <div class="botton">
                                                           
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                       </div>
                                       </div>
                                    </section>




                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-4afd489"
                        data-id="4afd489" data-element_type="column">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <div class="elementor-element elementor-element-36df10d elementor-widget elementor-widget-wpsection_team_service"
                                data-id="36df10d" data-element_type="widget"
                                data-widget_type="wpsection_team_service.default">
                                <div class="elementor-widget-container">
                                    <section class="team-area">
                                    <div class="card_team">
                                            <div class="card__content_team">
                                            <div class="col-md-12 col-sm-12 col-xs-12">
                                                <div class="single-team-member hvr-float-shadow text-center">
                                                    <div class="img-holder" style={{borderRadius:"20px"}}>
                                                        <img decoding="async"
                                                            src="./images/Vijay.JPG"
                                                            alt="Awesome Image"  style={{height:"205px"}}/>
                                                        {/* <div class="overlay-style-one">
                                                            
                                                        </div> */}
                                                    </div>
                                                    <div class="text-holder">
                                                        <div class="top">
                                                            <h3>Sri Vijaya Mehta</h3>
                                                            <h3>Join Secretary</h3>
                                                          
                                                        </div>
                                                        <div class="botton">
                                                           
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                       </div>
                                       </div>
                                    </section>




                                </div>
                            </div>
                        </div>
                    </div>

                    
                    <div class="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-4afd489"
                        data-id="4afd489" data-element_type="column">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <div class="elementor-element elementor-element-36df10d elementor-widget elementor-widget-wpsection_team_service"
                                data-id="36df10d" data-element_type="widget"
                                data-widget_type="wpsection_team_service.default">
                                <div class="elementor-widget-container">
                                    <section class="team-area">
                                    <div class="card_team">
                                            <div class="card__content_team">
                                            <div class="col-md-12 col-sm-12 col-xs-12">
                                                <div class="single-team-member hvr-float-shadow text-center">
                                                    <div class="img-holder" style={{borderRadius:"20px"}}>
                                                        <img decoding="async"
                                                            src="./images/ill.JPEG"
                                                            alt="Awesome Image"  style={{height:"205px"}}/>
                                                        {/* <div class="overlay-style-one">
                                                            
                                                        </div> */}
                                                    </div>
                                                    <div class="text-holder">
                                                        <div class="top">
                                                            <h3>Sri Ilango K</h3>
                                                            <h3>Treasurer</h3>
                                                          
                                                        </div>
                                                        <div class="botton">
                                                           
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                       </div>
                                       </div>
                                    </section>




                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
         </div>
      
    </div>
  )
}

export default Team
