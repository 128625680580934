import React, { useEffect } from 'react';

import ReactSlider from 'react-slick'; // Rename the imported component
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import $ from 'jquery';
import './Slider.css'

const Slider = () => { // Rename the functional component
    useEffect(() => {
        // Initialize Owl Carousel
        if (window.$('.main-slider-carousel').length) {
            window.$('.main-slider-carousel').owlCarousel({
                loop: true,
                margin: 0,
                nav: true,
                animateOut: 'slideOutDown',
                animateIn: 'fadeIn',
                active: true,
                smartSpeed: 1000,
                autoplay: 5000,
                responsive: {
                    0: {
                        items: 1
                    },
                    600: {
                        items: 1
                    },
                    1200: {
                        items: 1
                    }
                }
            });
        }
        
        // Clean up function
        return () => {
            if (window.$('.main-slider-carousel').data('owl.carousel')) {
                window.$('.main-slider-carousel').owlCarousel('destroy');
            }
        };
    }, []);

  return (
    <div>
       <section class="elementor-section elementor-top-section elementor-element elementor-element-844f0e0 elementor-section-full_width elementor-section-height-default elementor-section-height-default" data-id="844f0e0" data-element_type="section">
                <div class="elementor-container elementor-column-gap-default">
                    <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-ab7c0f7" data-id="ab7c0f7" data-element_type="column">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <div class="elementor-element elementor-element-1bec070 elementor-widget elementor-widget-wpsection_bannar_three" data-id="1bec070" data-element_type="widget" data-widget_type="wpsection_bannar_three.default">
                                <div class="elementor-widget-container">
                                    <section class="main-slider2 ">
                                        <div class="container-fluid">
                                            <ul class="main-slider-carousel owl-carousel owl-theme slide-nav">

                                                <li class="slider-wrapper">
                                                    <div class="image">
                                                        <img src="./images/a2.webp" alt=""/>
                                                    </div>
                                                    <div class="slider-caption text-center">
                                                        <div class="container">
                                                        <h1>Discover Our Cutting-Edge<br/>Industrial Solutions</h1>
<div class="text">Our industries leads the way in innovation, <br/>delivering unparalleled excellence to our valued customers.</div>





                                                           
                                                        </div>
                                                    </div>
                                                    <div class="slide-overlay"></div>
                                                </li>

                                                <li class="slider-wrapper">
                                                    <div class="image">
                                                        <img src="./images/s3.jpg" alt=""/>
                                                    </div>
                                                    <div class="slider-caption text-center">
                                                        <div class="container">
                                                        <h1>Building Blocks of Innovation</h1>
                                                            <div class="text">High-Quality Automobile Components for a Seamless Driving Experience.</div>
                                                        
                                                            {/* <div class="link-btn"><a href="https://html.tonatheme.com/wp/industry/solution/" class="theme-btn btn-style-one">Our Solutions</a><a href="https://html.tonatheme.com/wp/industry/about-company/" class="theme-btn btn-style-seven">Read More</a></div> */}
                                                        </div>

                                                    </div>
                                                    <div class="slide-overlay"></div>
                                                </li>

                                                <li class="slider-wrapper">
                                                    <div class="image">
                                                        <img src="./images/m6.jpg" alt=""/>
                                                    </div>
                                                    <div class="slider-caption text-center">
                                                        <div class="container">
                                                            <h1>Provide Solutions<br/>For All Industries</h1>
                                                            <div class="text">Industries has consistently embraced innovation to provide a superior<br/>level of excellence for all over valuable customers.</div>
                                                            {/* <div class="link-btn"><a href="https://html.tonatheme.com/wp/industry/solution/" class="theme-btn btn-style-one">Our Solutions</a><a href="https://html.tonatheme.com/wp/industry/about-company/" class="theme-btn btn-style-seven">Read More</a></div> */}
                                                        </div>
                                                    </div>
                                                    <div class="slide-overlay"></div>
                                                </li>


                                                <li class="slider-wrapper">
                                                    <div class="image" >
                                                        <img src="./images/sli22.png" alt="" />
                                                    </div>
                                                    <div class="slider-caption text-center">
                                                        <div class="container">
                                                            <h1>Quality Components for Unmatched Performance</h1>
                                                            <div class="text">Explore Our Range of Engineered Excellence for Every Automotive Need</div>
                                                            {/* <div class="link-btn"><a href="https://html.tonatheme.com/wp/industry/solution/" class="theme-btn btn-style-one">Our Solutions</a><a href="https://html.tonatheme.com/wp/industry/about-company/" class="theme-btn btn-style-seven">Read More</a></div> */}
                                                        </div>
                                                    </div>
                                                    <div class="slide-overlay"></div>
                                                </li>

                                                

                                               

                                            </ul>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

         
          
    </div>
  )
}

export default Slider; // Export the renamed component
