import React from 'react'
import { Link } from 'react-router-dom';
import './Welcome.css'

const Welcome = () => {
   
    return (
        <div>
            <div data-elementor-type="wp-page" data-elementor-id="17" class="elementor elementor-17">
                <div class="my_bg">
                <section
                    class="elementor-section elementor-top-section elementor-element elementor-element-d59ea0c elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                    data-id="d59ea0c" data-element_type="section">
                        
                    <div class="elementor-container elementor-column-gap-no">
                        <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-0646942"
                            data-id="0646942" data-element_type="column">
                            <div class="elementor-widget-wrap elementor-element-populated">
                                <section
                                    class="elementor-section elementor-inner-section elementor-element elementor-element-8608f00 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                    data-id="8608f00" data-element_type="section">
                                    <div class="elementor-container elementor-column-gap-no">
                                        <div class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-1964c64"
                                            data-id="1964c64" data-element_type="column">
                                            <div class="elementor-element-populated">
                                                <div class="elementor-element elementor-element-9b72947 elementor-widget elementor-widget-heading"
                                                    data-id="9b72947" data-element_type="widget"
                                                    data-widget_type="heading.default">
                                                    <div class="elementor-widget-container">

                                                        <h2 class="elementor-heading-title elementor-size-default">Welcome
                                                            <br /> to DIEA</h2>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div 
                                            data-id="7b09069" data-element_type="column">
                                            <div class="elementor-widget-wrap elementor-element-populated">
                                                <div class="elementor-element elementor-element-c4b253a elementor-widget elementor-widget-heading"
                                                    data-id="c4b253a" data-element_type="widget"
                                                    data-widget_type="heading.default">
                                                    <div class="elementor-widget-container">
                                                    <h2 class="elementor-heading-title elementor-size-default" style={{textAlign:'justify',color:'#403f3f',marginLeft:"20px"}}>In the year 2000, DYAVASANDRA INDUSTRIAL ESTATE ASSOCIATION (DIEA) was born. Sri R. Gangadhar was instrumental in bringing right thinking people together and form the Association. He was our Founder President. Since then DIEA has achieved lot of land marks. The main objective was providing safety to our member units.
                                                         DIEA introduced security system to man the entry,
                                                          exit points as well as night patrol all over the Estate.
                                                           Consequent to neglect and apathy of the Municipal authorities, 
                                                           the Association took it upon itself to provide lot of services to members. 
                                                           It embarked on collection and disposal of garbage, regular cleaning of the Estate, etc.</h2><br/>
                                                          
                                                           <Link to="/about" style={{ backgroundColor: '#fdc900', color: 'black', padding: '10px 20px', borderRadius: '5px', textDecoration: 'none',marginLeft:"20px" }}>
                                                                                    Learn More
                                                                                    </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </section></div>
               
               <div className='my_bg5'>
                <section
                    class="elementor-section elementor-top-section elementor-element elementor-element-8d47266 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                    data-id="8d47266" data-element_type="section">
                    <div class="elementor-container elementor-column-gap-no">
                        <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-c601709"
                            data-id="c601709" data-element_type="column">
                            <div class="elementor-widget-wrap elementor-element-populated">      
                                <section
                                    class="elementor-section elementor-inner-section elementor-element elementor-element-2fbc9a7 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                    data-id="2fbc9a7" data-element_type="section">
                                    <div class="elementor-container elementor-column-gap-no">
                                        <div class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-c7977cb"
                                            data-id="c7977cb" data-element_type="column">

                                            <div class="elementor-widget-wrap elementor-element-populated">
                                                <div class="elementor-element elementor-element-401f8dd elementor-widget-mobile__width-initial elementor-widget elementor-widget-wpsection_service_block_three"
                                                    data-id="401f8dd" data-element_type="widget"
                                                    data-widget_type="wpsection_service_block_three.default">
                                                    <div class="elementor-widget-container">
                                                        <section class="solution-area">
                                                            <div class="container solution-botton">
                                                                <div class="row">
                                                                    <div class="col-md-12">
                                                                   
                                                                        <div class="single-item hvr-float-shadow">
                                                                            <div class="img-holder my_animation_image">
                                                                                <img src="https://html.tonatheme.com/wp/industry/wp-content/uploads/2023/05/solution-thumb-1.jpg"
                                                                                    alt="Awesome Image" style={{width:"200px"}}/>
                                                                            </div>
                                                                            <div class="text-holder">
                                                                            <h3><a href="/Solution"style={{color:'black',fontWeight:'800',fontSize:"large"}}>Mechanical <br/>Services</a></h3>
                                                                                
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                          

                                                        </section>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-c7977cb"
                                            data-id="c7977cb" data-element_type="column">
                                            <div class="elementor-widget-wrap elementor-element-populated">
                                                <div class="elementor-element elementor-element-401f8dd elementor-widget-mobile__width-initial elementor-widget elementor-widget-wpsection_service_block_three"
                                                    data-id="401f8dd" data-element_type="widget"
                                                    data-widget_type="wpsection_service_block_three.default">
                                                    <div class="elementor-widget-container">
                                                        <section class="solution-area">
                                                            <div class="container solution-botton">
                                                                <div class="row">
                                                                    <div class="col-md-12">
                                                                        <div class="single-item hvr-float-shadow">
                                                                            <div class="img-holder my_animation_image">
                                                                                <img src="https://html.tonatheme.com/wp/industry/wp-content/uploads/2023/05/solution-thumb-2.jpg"
                                                                                    alt="Awesome Image" style={{width:"200px"}}/>
                                                                            </div>
                                                                            <div class="text-holder">
                                                                            <h3><a href="/Solution"style={{color:'black',fontWeight:'800',fontSize:"large"}}>Electrical <br/>Services</a></h3>
                                                                                
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </section>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-3b591bb"
                                            data-id="3b591bb" data-element_type="column">
                                            <div class="elementor-widget-wrap elementor-element-populated">
                                                <div class="elementor-element elementor-element-81acdd1 elementor-widget-mobile__width-initial elementor-widget__width-initial elementor-widget elementor-widget-wpsection_service_block_three"
                                                    data-id="81acdd1" data-element_type="widget"
                                                    data-widget_type="wpsection_service_block_three.default">
                                                    <div class="elementor-widget-container">
                                                        <section class="solution-area">
                                                            <div class="container solution-botton">
                                                                <div class="row">
                                                                    <div class="col-md-12">
                                                                        <div class="single-item hvr-float-shadow">
                                                                            <div class="img-holder my_animation_image">
                                                                                <img src="https://html.tonatheme.com/wp/industry/wp-content/uploads/2023/05/solution-thumb-3.jpg"
                                                                                    alt="Awesome Image" style={{width:"200px"}}/>
                                                                            </div>
                                                                            <div class="text-holder">
                                                                            <h3><a href="/Solution" style={{color:'black',fontWeight:'800',fontSize:"large"}}>Chemical <br/>Services</a></h3>
                                                                                
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </section>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </section></div>

                <div className='my_bg6'>
                <section
                    class="elementor-section elementor-top-section elementor-element elementor-element-adba9fa elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                    data-id="adba9fa" data-element_type="section"
                    data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                       
                    <div class="elementor-container elementor-column-gap-no">
                    
                        <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-ff58482"
                            data-id="ff58482" data-element_type="column">
                            <div class="elementor-widget-wrap elementor-element-populated">
                                <div class="elementor-element elementor-element-630405e elementor-widget elementor-widget-wpsection_mixitup_gallery"
                                    data-id="630405e" data-element_type="widget"
                                    data-widget_type="wpsection_mixitup_gallery.default">
                                    <div class="elementor-widget-container">


                                        <section class="latest-project-area ">
                                            <div class="container">
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="top">
                                                            <div class="sec-title pull-left">
                                                                <h1 style={{color:"black",fontWeight:"bold",marginTop:"3px"}}>Our Services</h1>
                                                                <span class="border"></span>
                                                            </div>
                                                            <ul class="project-filter post-filter pull-right">
                                                                <li class="active" data-filter=".filter-item"><span style={{fontWeight:"bold"}}>VIEW
                                                                    ALL</span></li>
                                                                <li data-filter=".agriculture"><span style={{fontWeight:"bold"}}>Automobile</span></li>
                                                                <li data-filter=".chemical"><span style={{fontWeight:"bold"}}>Mechanical</span></li>
                                                                <li data-filter=".environment"><span style={{fontWeight:"bold"}}>Electrical</span></li>
                                                               
                                                                <li data-filter=".material-science"><span style={{fontWeight:"bold"}}>Others
                                                                    </span></li>
                                                            </ul>
                                                        </div>
                                                        <div class="row project-content masonary-layout filter-layout">



                                                      

                                                            
                                                        <div
                                                                class="filter-item material-science technology mt-4 col-md-3 col-sm-6 col-xs-12 " >
                                                                <div class="single-project-item">
                                                                    <a href='' target='_blank'>
                                                                    <div class="img-holder card_dd">
                                                                        <img decoding="async" width="200" height="220"
                                                                            src="./images/abd.JPG"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>
                                                                        
                                                                    </div><p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>Abad Fisheries Pvt Ltd</p></a>
                                                                </div>
                                                            </div>


                                                            <div
                                                                class="filter-item agriculture technology  mt-4 col-md-3 col-sm-6 col-xs-12 " >
                                                                <div class="single-project-item">
                                                                    <a href='https://aspiration.in/' target='_blank'>
                                                                    <div class="img-holder card_dd">
                                                                        <img decoding="async" width="200" height="220"
                                                                            src="./images/ap.JPG"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>
                                                                        
                                                                    </div><p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>Apeiron Mobility Pvt Ltd</p></a>
                                                                </div>
                                                            </div>



                                                            
                                                        {/* <div
                                                                class="filter-item material-science technology mt-4 col-md-3 col-sm-6 col-xs-12 " >
                                                                <div class="single-project-item">
                                                                    <a href='' target='_blank'>
                                                                    <div class="img-holder card_dd">
                                                                        <img decoding="async" width="200" height="220"
                                                                            src="./images/AspirationLogo.png"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"30px",marginTop:"8px"}}/>
                                                                        
                                                                    </div><p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>Aspiration Advertising Pvt Ltd</p></a>
                                                                </div>
                                                            </div> */}

                                                        <div
                                                                class="filter-item material-science technology mt-4 col-md-3 col-sm-6 col-xs-12 " >
                                                                <div class="single-project-item">
                                                                    <a href='' target='_blank'>
                                                                    <div class="img-holder card_dd">
                                                                        <img decoding="async" width="200" height="220"
                                                                            src="./images/carbon.png"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>
                                                                        
                                                                    </div><p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>Bangalore Carbon</p></a>
                                                                </div>
                                                            </div>

                                                           








                                                            <div
                                                                class="filter-item material-science technology mt-4 col-md-3 col-sm-6 col-xs-12 " >
                                                                <div class="single-project-item">
                                                                    <a href='https://www.baghirathi.org/' target='_blank'>
                                                                    <div class="img-holder card_dd">
                                                                        <img decoding="async" width="200" height="220"
                                                                            src="./images/bag.jpg"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>
                                                                        
                                                                    </div><p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>Baghirathi Travel Solutions Pvt Ltd</p></a>
                                                                </div>
                                                            </div>

                                                            
                                                        <div
                                                                class="filter-item material-science technology mt-4 col-md-3 col-sm-6 col-xs-12 " >
                                                                <div class="single-project-item">
                                                                    <a href='https://www.beanalytic.com' target='_blank'>
                                                                    <div class="img-holder card_dd">
                                                                        <img decoding="async" width="200" height="220"
                                                                            src="./images/ba.jpg"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>
                                                                        
                                                                    </div><p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>BEAnalytics solutions LLP</p></a>
                                                                </div>
                                                            </div>

                                                            <div
                                                                class="filter-item agriculture technology  mt-4 col-md-3 col-sm-6 col-xs-12 " >
                                                                <div class="single-project-item">
                                                                    <a href='' target='_blank'>
                                                                    <div class="img-holder card_dd">
                                                                        <img decoding="async" width="200" height="220"
                                                                            src="./images/bar3.jpg"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>
                                                                        
                                                                    </div><p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>Bharath Press Components   </p></a>
                                                                </div>
                                                            </div>


                                                            <div
                                                                class="filter-item material-science technology mt-4 col-md-3 col-sm-6 col-xs-12 " >
                                                                <div class="single-project-item">
                                                                    <a href='' target='_blank'>
                                                                    <div class="img-holder card_dd">
                                                                        <img decoding="async" width="200" height="220"
                                                                            src="./images/blo1.jpg"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>
                                                                        
                                                                    </div><p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>Blossom Embroideries    </p></a>
                                                                </div>
                                                            </div>

                                                            
                                                            <div
                                                                class="filter-item material-science technology mt-4 col-md-3 col-sm-6 col-xs-12 " >
                                                                <div class="single-project-item">
                                                                    <a href='' target='_blank'>
                                                                    <div class="img-holder card_dd">
                                                                        <img decoding="async" width="200" height="220"
                                                                            src="./images/coren.png"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>
                                                                        
                                                                    </div><p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>Coretec Engineering India</p></a>
                                                                </div>
                                                            </div>
                                                              

                                                              



                                                           

                                                            <div
                                                                class="filter-item material-science technology mt-4 col-md-3 col-sm-6 col-xs-12 " >
                                                                <div class="single-project-item">
                                                                    <a href='https://caddeskmahadevpura.com/' target='_blank'>
                                                                    <div class="img-holder card_dd">
                                                                        <img decoding="async" width="200" height="220"
                                                                            src="./images/caddesk.png"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>
                                                                        
                                                                    </div><p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>Cad Desk</p></a>
                                                                </div>
                                                            </div>

                                                            <div
                                                                class="filter-item agriculture technology  mt-4 col-md-3 col-sm-6 col-xs-12 " >
                                                                <div class="single-project-item">
                                                                    <a href='' target='_blank'>
                                                                    <div class="img-holder card_dd">
                                                                        <img decoding="async" width="200" height="220"
                                                                            src="./images/ci.JPG"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>
                                                                        
                                                                    </div><p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>CNC India</p></a>
                                                                </div>
                                                            </div>

                                                            <div
                                                                class="filter-item material-science technology mt-4 col-md-3 col-sm-6 col-xs-12 " >
                                                                <div class="single-project-item">
                                                                    <a href='' target='_blank'>
                                                                    <div class="img-holder card_dd">
                                                                        <img decoding="async" width="200" height="220"
                                                                            src="./images/CuttingEdge.png"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>
                                                                        
                                                                    </div><p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>Cutting Edge Industries Pvt.Ltd</p></a>
                                                                </div>
                                                            </div>

                                                            <div
                                                                class="filter-item environment technology mt-4 col-md-3 col-sm-6 col-xs-12 ">
                                                                <div class="single-project-item" style={{marginBottom:"5px"}}>
                                                                    <a href='https://www.dtransems.com' target='_blank'>
                                                                    <div class="img-holder  card_dd" >
                                                                        <img fetchpriority="high" decoding="async"
                                                                            width="200" height="220"
                                                                            src="./images/DtransLogo.png"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>
                                                                       
                                                                    </div><p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>Dtrans</p></a>
                                                                </div>
                                                            </div>


                                                            <div
                                                                class="filter-item chemical technology  col-md-3 col-sm-6 col-xs-12 ">
                                                                <div class="single-project-item" style={{marginBottom:"5px"}}>
                                                                    <a href='https://www.excelmetalprocessors.com' target='_blank'>
                                                                    <div class="img-holder card_dd" >
                                                                        <img fetchpriority="high" decoding="async"
                                                                            width="200" height="220"
                                                                            src="./images/ExcelMetal.jpeg"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>
                                                                        
                                                                    </div>
                                                                    <p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>Excel Metal Processors</p>
                                                                    </a>
                                                                </div>
                                                            </div>

                                                            <div
                                                                class="filter-item agriculture technology  mt-4 col-md-3 col-sm-6 col-xs-12 " >
                                                                <div class="single-project-item">
                                                                    <a href='' target='_blank'>
                                                                    <div class="img-holder card_dd">
                                                                        <img decoding="async" width="200" height="220"
                                                                            src="./images/ev.JPG"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>
                                                                        
                                                                    </div><p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>Ever Bright Electro Platers </p></a>
                                                                </div>
                                                            </div>

                                                            
                                                            <div
                                                                class="filter-item agriculture technology  mt-4 col-md-3 col-sm-6 col-xs-12 " >
                                                                <div class="single-project-item">
                                                                    <a href='' target='_blank'>
                                                                    <div class="img-holder card_dd">
                                                                        <img decoding="async" width="200" height="220"
                                                                            src="./images/ei.JPG"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>
                                                                        
                                                                    </div><p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>Evershine Industries </p></a>
                                                                </div>
                                                            </div>

                                                            <div
                                                                class="filter-item material-science technology mt-4 col-md-3 col-sm-6 col-xs-12 " >
                                                                <div class="single-project-item">
                                                                    <a href='https://www.excerptech.com/' target='_blank'>
                                                                    <div class="img-holder card_dd">
                                                                        <img decoding="async" width="200" height="220"
                                                                            src="./images/excerpt.png"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>
                                                                        
                                                                    </div><p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>Excerpt Technologies Pvt.Ltd</p></a>
                                                                </div>
                                                            </div>


                                                            <div
                                                                class="filter-item material-science technology mt-4 col-md-3 col-sm-6 col-xs-12 " >
                                                                <div class="single-project-item">
                                                                    <a href='https://frabraicengineeringco.com' target='_blank'>
                                                                    <div class="img-holder card_dd">
                                                                        <img decoding="async" width="200" height="220"
                                                                            src="./images/fab.JPG"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>
                                                                        
                                                                    </div><p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>Fabrica Engineering Co. </p></a>
                                                                </div>
                                                            </div>


                                                            <div
                                                                class="filter-item material-science technology mt-4 col-md-3 col-sm-6 col-xs-12 " >
                                                                <div class="single-project-item">
                                                                    <a href='	https://sales.blr@fairflow.co.in' target='_blank'>
                                                                    <div class="img-holder card_dd">
                                                                        <img decoding="async" width="200" height="220"
                                                                            src="./images/ff.JPG"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>
                                                                        
                                                                    </div><p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>Fair Flow Ventilation  </p></a>
                                                                </div>
                                                            </div>


                                                            <div
                                                                class="filter-item material-science technology mt-4 col-md-3 col-sm-6 col-xs-12 " >
                                                                <div class="single-project-item">
                                                                    <a href='' target='_blank'>
                                                                    <div class="img-holder card_dd">
                                                                        <img decoding="async" width="200" height="220"
                                                                            src="./images/ft.JPG"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>
                                                                        
                                                                    </div><p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>Fine Tech</p></a>
                                                                </div>
                                                            </div>

                                                            <div
                                                                class="filter-item material-science technology mt-4 col-md-3 col-sm-6 col-xs-12 " >
                                                                <div class="single-project-item">
                                                                    <a href='' target='_blank'>
                                                                    <div class="img-holder card_dd">
                                                                        <img decoding="async" width="200" height="220"
                                                                            src="./images/good.png"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>
                                                                        
                                                                    </div><p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>Good Well</p></a>
                                                                </div>
                                                            </div>

                                                            <div
                                                                class="filter-item material-science technology mt-4 col-md-3 col-sm-6 col-xs-12 " >
                                                                <div class="single-project-item">
                                                                    <a href='' target='_blank'>
                                                                    <div class="img-holder card_dd">
                                                                        <img decoding="async" width="200" height="220"
                                                                            src="./images/ge.JPG"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>
                                                                        
                                                                    </div><p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>Gajalakshmi Engineering </p></a>
                                                                </div>
                                                            </div>

                                                            <div
                                                                class="filter-item material-science technology mt-4 col-md-3 col-sm-6 col-xs-12 " >
                                                                <div class="single-project-item">
                                                                    <a href='' target='_blank'>
                                                                    <div class="img-holder card_dd">
                                                                        <img decoding="async" width="200" height="220"
                                                                            src="./images/harini.png"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>
                                                                        
                                                                    </div><p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>Harini Industry</p></a>
                                                                </div>
                                                            </div>

                                                            <div
                                                                class="filter-item material-science technology mt-4 col-md-3 col-sm-6 col-xs-12 " >
                                                                <div class="single-project-item">
                                                                    <a href='https://www.hansuv.com' target='_blank'>
                                                                    <div class="img-holder card_dd">
                                                                        <img decoding="async" width="200" height="220"
                                                                            src="./images/he.JPG"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>
                                                                        
                                                                    </div><p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>Hansa Enterprises </p></a>
                                                                </div>
                                                            </div>

                                                            <div
                                                                class="filter-item material-science technology mt-4 col-md-3 col-sm-6 col-xs-12 " >
                                                                <div class="single-project-item">
                                                                    <a href='' target='_blank'>
                                                                    <div class="img-holder card_dd">
                                                                        <img decoding="async" width="200" height="220"
                                                                            src="./images/hi.JPG"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>
                                                                        
                                                                    </div><p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>Hi-tech Research  </p></a>
                                                                </div>
                                                            </div>


                                                            <div
                                                                class="filter-item material-science technology mt-4 col-md-3 col-sm-6 col-xs-12 " >
                                                                <div class="single-project-item">
                                                                    <a href='https://htmlhydravlics.com/' target='_blank'>
                                                                    <div class="img-holder card_dd">
                                                                        <img decoding="async" width="200" height="220"
                                                                            src="./images/ht.JPG"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>
                                                                        
                                                                    </div><p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>Htm Hydravlics Pvt Ltd </p></a>
                                                                </div>
                                                            </div>



                                                            <div
                                                                class="filter-item material-science mt-4 technology col-md-3 col-sm-6 col-xs-12 " >
                                                                <div class="single-project-item">
                                                                <a href='https://herbal-creations.com' target='_blank'>
                                                                    
                                                                    <div class="img-holder card_dd" >
                                                                        <img loading="lazy" decoding="async" width="200"
                                                                            height="220"
                                                                            src="./images/HerbalCreationsLogo.png"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>
                                                                       
                                                                    </div><p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>Herbal Creations</p></a>
                                                                </div>
                                                            </div>

                                                            <div
                                                                class="filter-item material-science mt-4 technology col-md-3 col-sm-6 col-xs-12 " >
                                                                <div class="single-project-item">
                                                                    <div class="img-holder card_dd" >
                                                                        <img loading="lazy" decoding="async" width="200"
                                                                            height="220"
                                                                            src="./images/ht1.JPG"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>
                                                                       
                                                                    </div><p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>Ideal Tools </p>
                                                                </div>
                                                            </div>

                                                            <div
                                                                class="filter-item chemical  col-md-3 col-sm-6 col-xs-12 " >
                                                                    
                                                                <div class="single-project-item">
                                                                <a href='https://ifymould.in/' target='_blank'>
                                                                    <div class="img-holder card_dd">
                                                                        <img decoding="async" width="200" height="220"
                                                                            src="./images/IFYLogo.png"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>

                                                                    </div>
                                                                    <p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>Ify Mould Tech</p>
                                                                    </a>
                                                                </div>
                                                            </div>

                                                            
                                                            <div
                                                                class="filter-item material-science technology  col-md-3 col-sm-6 col-xs-12 " >
                                                                    
                                                                <div class="single-project-item">
                                                                <a href='	https://www.impactlab.co.in' target='_blank'>
                                                                    <div class="img-holder card_dd">
                                                                        <img decoding="async" width="200" height="220"
                                                                            src="./images/it.JPG"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>

                                                                    </div>
                                                                    <p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>Impact Test And Calibraction Lab </p>
                                                                    </a>
                                                                </div>
                                                            </div>

                                                            <div
                                                                class="filter-item chemical technology  col-md-3 col-sm-6 col-xs-12 ">
                                                                <div class="single-project-item">
                                                                <a href='https://instruments-india.com/' target='_blank'>
                                                                    <div class="img-holder  card_dd" >
                                                                        
                                                                        <img loading="lazy" decoding="async" width="200"
                                                                            height="220"
                                                                            src="./images/insindialogo.jpeg"
                                                                            class=""
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>
                                                                        
                                                                    </div>
                                                                    <p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>Instruments-India</p>
                                                                    </a>
                                                                </div>
                                                            </div>


                                                            <div
                                                                class="filter-item chemical technology  col-md-3 col-sm-6 col-xs-12 ">
                                                                <div class="single-project-item">
                                                                <a href='https://www.ionexchangeservices.com' target='_blank'>
                                                                    <div class="img-holder  card_dd" >
                                                                        
                                                                        <img loading="lazy" decoding="async" width="200"
                                                                            height="220"
                                                                            src="./images//io.JPG"
                                                                            class=""
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>
                                                                        
                                                                    </div>
                                                                    <p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>Ion Exchange India Ltd</p>
                                                                    </a>
                                                                </div>
                                                            </div>


                                                            <div
                                                                class="filter-item chemical technology  col-md-3 col-sm-6 col-xs-12 ">
                                                                <div class="single-project-item">
                                                                <a href='' target='_blank'>
                                                                    <div class="img-holder  card_dd" >
                                                                        
                                                                        <img loading="lazy" decoding="async" width="200"
                                                                            height="220"
                                                                            src="./images/is.JPG"
                                                                            class=""
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>
                                                                        
                                                                    </div>
                                                                    <p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>Istore Direct Trading Pvt Ltd</p>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                            
                                                            <div
                                                                class="filter-item material-science technology mt-4 col-md-3 col-sm-6 col-xs-12 " >
                                                                <div class="single-project-item">
                                                                    <a href='https://itdeskmahadevpura.com/' target='_blank'>
                                                                    <div class="img-holder card_dd">
                                                                        <img decoding="async" width="200" height="220"
                                                                            src="./images/2.png"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>
                                                                        
                                                                    </div><p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>ITDesk</p></a>
                                                                </div>
                                                            </div>

                                                            <div
                                                                class="filter-item material-science technology mt-4 col-md-3 col-sm-6 col-xs-12 " >
                                                                <div class="single-project-item">
                                                                <a href='https://jaincouv.com' target='_blank'>
                                                                    <div class="img-holder card_dd" >
                                                                        <img decoding="async" width="200" height="220"
                                                                            src="./images/ja.jpg"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>
                                                                        
                                                                    </div><p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>Jainco Industry Chemicals </p></a>
                                                                </div>
                                                            </div>

                                                            <div
                                                                class="filter-item material-science technology mt-4 col-md-3 col-sm-6 col-xs-12 " >
                                                                <div class="single-project-item">
                                                                    <div class="img-holder card_dd" >
                                                                        <img decoding="async" width="200" height="220"
                                                                            src="./images/jklogo.jpg"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>
                                                                        
                                                                    </div><p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>JK Bank</p>
                                                                </div>
                                                            </div>

                                                            <div
                                                                class="filter-item material-science technology mt-4 col-md-3 col-sm-6 col-xs-12 " >
                                                                <div class="single-project-item">
                                                                    <div class="img-holder card_dd" >
                                                                        <img decoding="async" width="200" height="220"
                                                                            src="./images/jy.jpg"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>
                                                                        
                                                                    </div><p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>Jyeshta Industries </p>
                                                                </div>
                                                            </div>

                                                            
                                                            <div
                                                                class="filter-item material-science technology mt-4 col-md-3 col-sm-6 col-xs-12 " >
                                                                <div class="single-project-item">
                                                                    <div class="img-holder card_dd" >
                                                                        <img decoding="async" width="200" height="220"
                                                                            src="./images/kt.jpg"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>
                                                                        
                                                                    </div><p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>Kabelschlepp India Pvt Ltd </p>
                                                                </div>
                                                            </div>
                                                            

                                                             
                                                            <div
                                                                class="filter-item material-science technology mt-4 col-md-3 col-sm-6 col-xs-12 " >
                                                                <div class="single-project-item">
                                                                    <div class="img-holder card_dd" >
                                                                        <img decoding="async" width="200" height="220"
                                                                            src="./images/kc.jpg"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>
                                                                        
                                                                    </div><p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>Kolors Corp </p>
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="filter-item material-science technology mt-4 col-md-3 col-sm-6 col-xs-12 " >
                                                                <div class="single-project-item">
                                                                    <div class="img-holder card_dd" >
                                                                        <img decoding="async" width="200" height="220"
                                                                            src="./images/ki.jpg"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>
                                                                        
                                                                    </div><p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>Krishna Industries  </p>
                                                                </div>
                                                            </div>

                                                            <div
                                                                class="filter-item material-science technology mt-4 col-md-3 col-sm-6 col-xs-12 " >
                                                                <div class="single-project-item">
                                                                    <div class="img-holder card_dd" >
                                                                        <img decoding="async" width="200" height="220"
                                                                            src="./images/li.jpg"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>
                                                                        
                                                                    </div><p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}> Lakshmi Designs  </p>
                                                                </div>
                                                            </div>
                                                            
                                                            <div
                                                                class="filter-item material-science technology mt-4 col-md-3 col-sm-6 col-xs-12 " >
                                                                <div class="single-project-item">
                                                                    <div class="img-holder card_dd" >
                                                                        <img decoding="async" width="200" height="220"
                                                                            src="./images/lt.jpg"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>
                                                                        
                                                                    </div><p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}> Lakshmi Tools And Components  </p>
                                                                </div>
                                                            </div>

                                                            <div
                                                                class="filter-item environment mt-4 col-md-3 col-sm-6 col-xs-12 " >
                                                                <div class="single-project-item">
                                                                    <div class="img-holder card_dd" >
                                                                        <img decoding="async" width="200" height="220"
                                                                            src="./images/mb.jpg"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>
                                                                        
                                                                    </div><p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}> Mahabell Industries India Pvt Ltd </p>
                                                                </div>
                                                            </div>

                                                            
                                                            <div
                                                                class="filter-item material-science technology mt-4 col-md-3 col-sm-6 col-xs-12 " >
                                                                <div class="single-project-item">
                                                                    <div class="img-holder card_dd" >
                                                                        <img decoding="async" width="200" height="220"
                                                                            src="./images/ma.jpg"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>
                                                                        
                                                                    </div><p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}> Mahanthesh Industrial Enterprises  </p>
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="filter-item material-science technology mt-4 col-md-3 col-sm-6 col-xs-12 " >
                                                                <div class="single-project-item">
                                                                    <div class="img-holder card_dd" >
                                                                        <img decoding="async" width="200" height="220"
                                                                            src="./images/me.jpg"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>
                                                                        
                                                                    </div><p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>M.E Forge </p>
                                                                </div>
                                                            </div>
                                                             
                                                            <div
                                                                class="filter-item material-science technology mt-4 col-md-3 col-sm-6 col-xs-12 " >
                                                                <div class="single-project-item">
                                                                    <div class="img-holder card_dd" >
                                                                        <img decoding="async" width="200" height="220"
                                                                            src="./images/mh.jpg"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>
                                                                        
                                                                    </div><p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>M H Tools </p>
                                                                </div>
                                                            </div>
                                                              
                                                            <div
                                                                class="filter-item material-science technology mt-4 col-md-3 col-sm-6 col-xs-12 " >
                                                                <div class="single-project-item">
                                                                <a href='  https://millenniumreddot.in' target='_blank'>
                                                                
                                                                    <div class="img-holder card_dd" >
                                                                        <img decoding="async" width="200" height="220"
                                                                            src="./images/ml.jpg"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>
                                                                        
                                                                    </div><p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>Millennium Red Dot Pvt Ltd  </p></a>
                                                                </div>
                                                            </div>

                                                                
                                                            <div
                                                                class="filter-item material-science technology mt-4 col-md-3 col-sm-6 col-xs-12 " >
                                                                <div class="single-project-item">
                                                                    <div class="img-holder card_dd" >
                                                                        <img decoding="async" width="200" height="220"
                                                                            src="./images/mr.jpg"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>
                                                                        
                                                                    </div><p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>M.R Engineers  </p>
                                                                </div>
                                                            </div>







                                                             
                                                            <div
                                                                class="filter-item agriculture technology  col-md-3 col-sm-6 col-xs-12">
                                                                <div class="single-project-item">
                                                                    <a href='https://www.mathurenterprise.com' target='_blank'>
                                                                    <div class="img-holder  card_dd">
                                                                        <img decoding="async" width="270" height="220"
                                                                            src="./images/MathurEntps.png"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>
                                                                    
                                                                    </div>
                                                                    <p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>MathurEnterprise</p>
                                                                    </a>
                                                                </div>
                                                            </div>

                                                               
                                                            <div
                                                                class="filter-item   col-md-3 col-sm-6 col-xs-12">
                                                                <div class="single-project-item">
                                                                    <a href='' target='_blank'>
                                                                    <div class="img-holder  card_dd">
                                                                        <img decoding="async" width="270" height="220"
                                                                            src="./images/nc.JPG"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>
                                                                    
                                                                    </div>
                                                                    <p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>Ncn Technologies</p>
                                                                    </a>
                                                                </div>
                                                            </div>

                                                            <div
                                                                class="filter-item   col-md-3 col-sm-6 col-xs-12">
                                                                <div class="single-project-item">
                                                                    <a href='' target='_blank'>
                                                                    <div class="img-holder  card_dd">
                                                                        <img decoding="async" width="270" height="220"
                                                                            src="./images/ne.JPG"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>
                                                                    
                                                                    </div>
                                                                    <p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>NewTech Fasteners</p>
                                                                    </a>
                                                                </div>
                                                            </div>

                                                             


                                                            <div
                                                                class="filter-item material-science technology mt-4 col-md-3 col-sm-6 col-xs-12 " >
                                                                <div class="single-project-item">
                                                                    <a href='https://www.lynxindustries@yahoo.com' target='_blank'>
                                                                    <div class="img-holder card_dd">
                                                                        <img decoding="async" width="200" height="220"
                                                                            src="./images/lynx.png"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>
                                                                        
                                                                    </div><p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>Lynx Industries</p></a>
                                                                </div>
                                                            </div>


                                                            
                                                            
                                                            <div
                                                                class="filter-item material-science mt-4 technology  col-md-3 col-sm-6 col-xs-12 ">
                                                                <div class="single-project-item" >
                                                                <a href='https://www.onlineinstruments.co.in' target='_blank'>
                                                                    <div class="img-holder card_dd">
                                                                        <img fetchpriority="high" decoding="async"
                                                                            width="200" height="220"
                                                                            src="./images/onlineinstrument.png"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>
                                                                       
                                                                    </div><p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>Online Instrument</p></a>
                                                                </div>
                                                            </div>

                                                            <div
                                                                class="filter-item   col-md-3 col-sm-6 col-xs-12 ">
                                                                <div class="single-project-item" >
                                                                <a href='' target='_blank'>
                                                                    <div class="img-holder card_dd">
                                                                        <img fetchpriority="high" decoding="async"
                                                                            width="200" height="220"
                                                                            src="./images/or.JPG"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>
                                                                       
                                                                    </div><p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>Orotex Chemicals India Pvt Ltd</p></a>
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="filter-item   col-md-3 col-sm-6 col-xs-12 ">
                                                                <div class="single-project-item" >
                                                                <a href='https://paragonengg.com' target='_blank'>
                                                                    <div class="img-holder card_dd">
                                                                        <img fetchpriority="high" decoding="async"
                                                                            width="200" height="220"
                                                                            src="./images/pa.JPG"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>
                                                                       
                                                                    </div><p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>Paragon Engineering Works  </p></a>
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="filter-item   col-md-3 col-sm-6 col-xs-12 ">
                                                                <div class="single-project-item" >
                                                                <a href='' target='_blank'>
                                                                    <div class="img-holder card_dd">
                                                                        <img fetchpriority="high" decoding="async"
                                                                            width="200" height="220"
                                                                            src="./images/pl.JPG"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>
                                                                       
                                                                    </div><p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>Plasma Pvt Ltd  </p></a>
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="filter-item   col-md-3 col-sm-6 col-xs-12 ">
                                                                <div class="single-project-item" >
                                                                <a href='' target='_blank'>
                                                                    <div class="img-holder card_dd">
                                                                        <img fetchpriority="high" decoding="async"
                                                                            width="200" height="220"
                                                                            src="./images/pr.JPG"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>
                                                                       
                                                                    </div><p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>Precision Moulding Technology </p></a>
                                                                </div>
                                                            </div>

                                                            <div
                                                                class="filter-item   col-md-3 col-sm-6 col-xs-12 ">
                                                                <div class="single-project-item" >
                                                                <a href='	https://ptpcnc.biz' target='_blank'>
                                                                    <div class="img-holder card_dd">
                                                                        <img fetchpriority="high" decoding="async"
                                                                            width="200" height="220"
                                                                            src="./images/pt.JPG"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>
                                                                       
                                                                    </div><p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>Precision Telecon Products</p></a>
                                                                </div>
                                                            </div>
                                                            
                                                            <div
                                                                class="filter-item   col-md-3 col-sm-6 col-xs-12 ">
                                                                <div class="single-project-item" >
                                                                <a href='' target='_blank'>
                                                                    <div class="img-holder card_dd">
                                                                        <img fetchpriority="high" decoding="async"
                                                                            width="200" height="220"
                                                                            src="./images/pm.JPG"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>
                                                                       
                                                                    </div><p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>Press-Mac Industries</p></a>
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="filter-item   col-md-3 col-sm-6 col-xs-12 ">
                                                                <div class="single-project-item" >
                                                                <a href='' target='_blank'>
                                                                    <div class="img-holder card_dd">
                                                                        <img fetchpriority="high" decoding="async"
                                                                            width="200" height="220"
                                                                            src="./images/prm.JPG"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>
                                                                       
                                                                    </div><p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>Pretomatics </p></a>
                                                                </div>
                                                            </div>


                                                            <div
                                                                class="filter-item   col-md-3 col-sm-6 col-xs-12 ">
                                                                <div class="single-project-item" >
                                                                <a href='' target='_blank'>
                                                                    <div class="img-holder card_dd">
                                                                        <img fetchpriority="high" decoding="async"
                                                                            width="200" height="220"
                                                                            src="./images/ps.JPG"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>
                                                                       
                                                                    </div><p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>P&S Industries </p></a>
                                                                </div>
                                                            </div>

                                                            

                                                          
                                                            <div
                                                                class="filter-item chemical mt-4 technology  col-md-3 col-sm-6 col-xs-12 ">
                                                                <div class="single-project-item" >
                                                                <a href='https://www.mvtecnics' target='_blank'>

                                                                    <div class="img-holder card_dd">
                                                                        <img fetchpriority="high" decoding="async"
                                                                            width="200" height="220"
                                                                            src="./images/mv.JPG"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>
                                                                       
                                                                    </div><p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>M.V Technics</p></a>
                                                                </div>
                                                            </div>


                                                            <div
                                                                class="filter-item environment technology mt-4 col-md-3 col-sm-6 col-xs-12 " >
                                                                <div class="single-project-item" >
                                                                    <a href='https://rippllighting.com/' target='_blank'>
                                                                    <div class="img-holder card_dd" >
                                                                        <img loading="lazy" decoding="async" width="200"
                                                                            height="220"
                                                                            src="./images/RipplLight.png"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"4px"}}/>
                                                                        {/* <div class="overlay-style-one">
                                                                            <div class="box">
                                                                                
                                                                            </div>
                                                                        </div> */}
                                                                    </div>
                                                                    <p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>Reflections Interior Pvt.Ltd</p>
                                                                    </a>
                                                                </div>
                                                            </div>

                                                            <div
                                                                class="filter-item material-science technology mt-4 col-md-3 col-sm-6 col-xs-12 " >
                                                                <div class="single-project-item">
                                                                    <a href='	https://quantumacon.com' target='_blank'>
                                                                    <div class="img-holder card_dd">
                                                                        <img decoding="async" width="200" height="220"
                                                                            src="./images/qu1.png"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>
                                                                        
                                                                    </div><p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>Quantum Power System</p></a>
                                                                </div>
                                                            </div>

                                                            <div
                                                                class="filter-item material-science technology mt-4 col-md-3 col-sm-6 col-xs-12 " >
                                                                <div class="single-project-item">
                                                                    <a href='' target='_blank'>
                                                                    <div class="img-holder card_dd">
                                                                        <img decoding="async" width="200" height="220"
                                                                            src="./images/raja.png"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>
                                                                        
                                                                    </div><p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>Rajarajeshwari Industries</p></a>
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="filter-item material-science technology mt-4 col-md-3 col-sm-6 col-xs-12 " >
                                                                <div class="single-project-item">
                                                                    <a href='' target='_blank'>
                                                                    <div class="img-holder card_dd">
                                                                        <img decoding="async" width="200" height="220"
                                                                            src="./images/rc.JPG"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>
                                                                        
                                                                    </div><p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>Renuka Camtech</p></a>
                                                                </div>
                                                            </div>

                                                            <div
                                                                class="filter-item material-science technology mt-4 col-md-3 col-sm-6 col-xs-12 " >
                                                                <div class="single-project-item">
                                                                    <a href='https://www.retools.in' target='_blank'>
                                                                    <div class="img-holder card_dd">
                                                                        <img decoding="async" width="200" height="220"
                                                                            src="./images/re.JPG"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>
                                                                        
                                                                    </div><p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>	Ramesh Enterprises</p></a>
                                                                </div>
                                                            </div>
                                                            {/* <div
                                                                class="filter-item material-science technology mt-4 col-md-3 col-sm-6 col-xs-12 " >
                                                                <div class="single-project-item">
                                                                    <a href='' target='_blank'>
                                                                    <div class="img-holder card_dd">
                                                                        <img decoding="async" width="200" height="220"
                                                                            src="./images/ri.JPG"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>
                                                                        
                                                                    </div><p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>	Rajarajeshwari Industries</p></a>
                                                                </div>
                                                            </div> */}
                                                            <div
                                                                class="filter-item material-science technology mt-4 col-md-3 col-sm-6 col-xs-12 " >
                                                                <div class="single-project-item">
                                                                    <a href='' target='_blank'>
                                                                    <div class="img-holder card_dd">
                                                                        <img decoding="async" width="200" height="220"
                                                                            src="./images/rs.JPG"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>
                                                                        
                                                                    </div><p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>Roovel Solutions Pvt Ltd</p></a>
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="filter-item material-science technology mt-4 col-md-3 col-sm-6 col-xs-12 " >
                                                                <div class="single-project-item">
                                                                    <a href='' target='_blank'>
                                                                    <div class="img-holder card_dd">
                                                                        <img decoding="async" width="200" height="220"
                                                                            src="./images/rscs.JPG"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>
                                                                        
                                                                    </div><p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>Radiant Supply Chain Solutions</p></a>
                                                                </div>
                                                            </div>

                                                            

                                                            <div
                                                                class="filter-item agriculture technology mt-4 col-md-3 col-sm-6 col-xs-12 " >
                                                                <div class="single-project-item">
                                                                    <a href='https://www.rite-equipment.com' target='_blank'>
                                                                    <div class="img-holder card_dd" >
                                                                        
                                                                        <img fetchpriority="high" decoding="async"
                                                                            width="270" height="220"
                                                                            src="./images/RiteEqp.png"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>
                                                                        
                                                                    </div><p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>Rite Equipment</p>
                                                                    </a>
                                                                </div>
                                                            </div>

                                                            <div
                                                                class="filter-item environment technology mt-4 col-md-3 col-sm-6 col-xs-12 ">
                                                                <div class="single-project-item" style={{marginBottom:"5px"}}>
                                                                    <div class="img-holder card_dd">
                                                                        <img fetchpriority="high" decoding="async"
                                                                            width="200" height="220"
                                                                            src="./images/ambertsp.png"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>
                                                                        
                                                                    </div><p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>S.P. Industries</p>
                                                                </div>
                                                            </div>
                                                            

                                                            <div
                                                                class="filter-item environment technology mt-4 col-md-3 col-sm-6 col-xs-12 ">
                                                                <div class="single-project-item" style={{marginBottom:"5px"}}>
                                                                <a href=' https://www.sriengineering.net' target='_blank'>
                                                               
                                                                    <div class="img-holder card_dd">
                                                                        <img fetchpriority="high" decoding="async"
                                                                            width="200" height="220"
                                                                            src="./images/sr.JPG"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>
                                                                        
                                                                    </div><p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>Sri Engineering Tool Room</p></a>
                                                                </div>
                                                            </div>

                                                            <div
                                                                class="filter-item material-science technology mt-4 col-md-3 col-sm-6 col-xs-12 " >
                                                                <div class="single-project-item">
                                                                    <a href='' target='_blank'>
                                                                    <div class="img-holder card_dd">
                                                                        <img decoding="async" width="200" height="220"
                                                                            src="./images/rohid.png"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>
                                                                        
                                                                    </div><p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>Rohid Enterprises</p></a>
                                                                </div>
                                                            </div>
 
                                                            <div
                                                                class="filter-item material-science technology mt-4 col-md-3 col-sm-6 col-xs-12 " >
                                                                <div class="single-project-item">
                                                                    <a href='	https://sainathenterprises.com' target='_blank'>
                                                                    <div class="img-holder card_dd">
                                                                        <img decoding="async" width="200" height="220"
                                                                            src="./images/sainth.png"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>
                                                                        
                                                                    </div><p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>Sainath Enterprises</p></a>
                                                                </div>
                                                            </div>
                                                             
                                                            <div
                                                                class="filter-item agriculture technology  mt-4 col-md-3 col-sm-6 col-xs-12 " >
                                                                <div class="single-project-item">
                                                                    <a href='https://www.saturnindustries.net/' target='_blank'>
                                                                    <div class="img-holder card_dd">
                                                                        <img decoding="async" width="200" height="220"
                                                                            src="./images/sau.JPG"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>
                                                                        
                                                                    </div><p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>Saturn Industries</p></a>
                                                                </div>
                                                            </div>
                                                              
                                                            <div
                                                                class="filter-item material-science technology mt-4 col-md-3 col-sm-6 col-xs-12 " >
                                                                <div class="single-project-item">
                                                                    <a href='	https://sandyind.com' target='_blank'>
                                                                    <div class="img-holder card_dd">
                                                                        <img decoding="async" width="200" height="220"
                                                                            src="./images/sa.JPG"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>
                                                                        
                                                                    </div><p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>Sandy Industries</p></a>
                                                                </div>
                                                            </div>
                                                              
                                                            <div
                                                                class="filter-item material-science technology mt-4 col-md-3 col-sm-6 col-xs-12 " >
                                                                <div class="single-project-item">
                                                                    <a href='' target='_blank'>
                                                                    <div class="img-holder card_dd">
                                                                        <img decoding="async" width="200" height="220"
                                                                            src="./images/sair.JPG"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>
                                                                        
                                                                    </div><p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>Sirago Enterprises LLP</p></a>
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="filter-item material-science technology mt-4 col-md-3 col-sm-6 col-xs-12 " >
                                                                <div class="single-project-item">
                                                                    <a href='https://www.snelectronics.net.in' target='_blank'>
                                                                    <div class="img-holder card_dd">
                                                                        <img decoding="async" width="200" height="220"
                                                                            src="./images/sn.JPG"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>
                                                                        
                                                                    </div><p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>S.N. Electronics	</p></a>
                                                                </div>
                                                            </div>

                                                            <div
                                                                class="filter-item material-science technology mt-4 col-md-3 col-sm-6 col-xs-12 " >
                                                                <div class="single-project-item">
                                                                    <a href='' target='_blank'>
                                                                    <div class="img-holder card_dd">
                                                                        <img decoding="async" width="200" height="220"
                                                                            src="./images/ssp.JPG"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>
                                                                        
                                                                    </div><p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>S.S Polymers	</p></a>
                                                                </div>
                                                            </div>



                                                            <div
                                                                class="filter-item material-science technology mt-4 col-md-3 col-sm-6 col-xs-12 " >
                                                                <div class="single-project-item">
                                                                    <a href='https://www.steelkrafthosptitality.com/' target='_blank'>
                                                                    <div class="img-holder card_dd">
                                                                        <img decoding="async" width="200" height="220"
                                                                            src="./images/sakj.png"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>
                                                                        
                                                                    </div><p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>Sukhjeevan Engineering Works</p></a>
                                                                </div>
                                                            </div>

                                                            <div
                                                                class="filter-item material-science technology mt-4 col-md-3 col-sm-6 col-xs-12 " >
                                                                <div class="single-project-item">
                                                                    <a href='' target='_blank'>
                                                                    <div class="img-holder card_dd">
                                                                        <img decoding="async" width="200" height="220"
                                                                            src="./images/te.JPG"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>
                                                                        
                                                                    </div><p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>Teja Enterprises</p></a>
                                                                </div>
                                                            </div>

                                                            <div
                                                                class="filter-item material-science technology mt-4 col-md-3 col-sm-6 col-xs-12 " >
                                                                <div class="single-project-item">
                                                                    <a href='	https://jkbank.com' target='_blank'>
                                                                    <div class="img-holder card_dd">
                                                                        <img decoding="async" width="200" height="220"
                                                                            src="./images/tjk.JPG"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>
                                                                        
                                                                    </div><p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>The Jammu And Kashmir Bank Ltd</p></a>
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="filter-item material-science technology mt-4 col-md-3 col-sm-6 col-xs-12 " >
                                                                <div class="single-project-item">
                                                                    <a href='' target='_blank'>
                                                                    <div class="img-holder card_dd">
                                                                        <img decoding="async" width="200" height="220"
                                                                            src="./images/tpppl.JPG"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>
                                                                        
                                                                    </div><p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>Tele Press Products Pvt Ltd</p></a>
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="filter-item material-science technology mt-4 col-md-3 col-sm-6 col-xs-12 " >
                                                                <div class="single-project-item">
                                                                    <a href='' target='_blank'>
                                                                    <div class="img-holder card_dd">
                                                                        <img decoding="async" width="200" height="220"
                                                                            src="./images/ttmip.JPG"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>
                                                                        
                                                                    </div><p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>Tool Tech Metforms India Pvt Ltd</p></a>
                                                                </div>
                                                            </div>
                                                            
                                                            <div
                                                                class="filter-item material-science technology mt-4 col-md-3 col-sm-6 col-xs-12 " >
                                                                <div class="single-project-item">
                                                                    <a href='' target='_blank'>
                                                                    <div class="img-holder card_dd">
                                                                        <img decoding="async" width="200" height="220"
                                                                            src="./images/tthpfm.JPG"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>
                                                                        
                                                                    </div><p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>Trinity Transit House Pg For Men</p></a>
                                                                </div>
                                                            </div>

                                                          
                                                            <div
                                                                class="filter-item material-science technology mt-4 col-md-3 col-sm-6 col-xs-12 " >
                                                                <div class="single-project-item">
                                                                    <a href='	https://trimetrictechnology.com' target='_blank'>
                                                                    <div class="img-holder card_dd">
                                                                        <img decoding="async" width="200" height="220"
                                                                            src="./images/trimet.png"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>
                                                                        
                                                                    </div><p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>Trimetric Technology</p></a>
                                                                </div>
                                                            </div>

                                                            <div
                                                                class="filter-item material-science technology mt-4 col-md-3 col-sm-6 col-xs-12 " >
                                                                <div class="single-project-item">
                                                                    <a href='' target='_blank'>
                                                                    <div class="img-holder card_dd">
                                                                        <img decoding="async" width="200" height="220"
                                                                            src="./images/stell.png"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>
                                                                        
                                                                    </div><p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>Steelkraft Hospitality</p></a>
                                                                </div>
                                                            </div>

                                                            <div
                                                                class="filter-item material-science technology mt-4 col-md-3 col-sm-6 col-xs-12 " >
                                                                <div class="single-project-item">
                                                                    <a href='' target='_blank'>
                                                                    <div class="img-holder card_dd">
                                                                        <img decoding="async" width="200" height="220"
                                                                            src="./images/sridd.png"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>
                                                                        
                                                                    </div><p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>Sri Aditya Industries</p></a>
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="filter-item material-science technology mt-4 col-md-3 col-sm-6 col-xs-12 " >
                                                                <div class="single-project-item">
                                                                    <a href='' target='_blank'>
                                                                    <div class="img-holder card_dd">
                                                                        <img decoding="async" width="200" height="220"
                                                                            src="./images/srij.JPG"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>
                                                                        
                                                                    </div><p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>Sri Jayalakshmi Engineering</p></a>
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="filter-item material-science technology mt-4 col-md-3 col-sm-6 col-xs-12 " >
                                                                <div class="single-project-item">
                                                                    <a href='' target='_blank'>
                                                                    <div class="img-holder card_dd">
                                                                        <img decoding="async" width="200" height="220"
                                                                            src="./images/ucf.JPG"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>
                                                                        
                                                                    </div><p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>United Chem Formulatios</p></a>
                                                                </div>
                                                            </div>

                                                            <div
                                                                class="filter-item material-science technology mt-4 col-md-3 col-sm-6 col-xs-12 " >
                                                                <div class="single-project-item">
                                                                    <a href='https://www.umangmarketingblr.com' target='_blank'>
                                                                    <div class="img-holder card_dd">
                                                                        <img decoding="async" width="200" height="220"
                                                                            src="./images/ues.JPG"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>
                                                                        
                                                                    </div><p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>Umang Engineering Services</p></a>
                                                                </div>
                                                            </div>


                                                            
                                                            <div
                                                                class="filter-item material-science technology mt-4 col-md-3 col-sm-6 col-xs-12 " >
                                                                <div class="single-project-item">
                                                                    <a href='' target='_blank'>
                                                                    <div class="img-holder card_dd">
                                                                        <img decoding="async" width="200" height="220"
                                                                            src="./images/ues.JPG"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>
                                                                        
                                                                    </div><p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>Umang Engineering Services</p></a>
                                                                </div>
                                                            </div>


                                                            <div
                                                                class="filter-item material-science technology mt-4 col-md-3 col-sm-6 col-xs-12 " >
                                                                <div class="single-project-item">
                                                                    <a href='https://vbgroup.in' target='_blank'>
                                                                    <div class="img-holder card_dd">
                                                                        <img decoding="async" width="200" height="220"
                                                                            src="./images/vb.png"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>
                                                                        
                                                                    </div><p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>VB Groups</p></a>
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="filter-item  mt-4 col-md-3 col-sm-6 col-xs-12 " >
                                                                <div class="single-project-item">
                                                                    <a href='' target='_blank'>
                                                                    <div class="img-holder card_dd">
                                                                        <img decoding="async" width="200" height="220"
                                                                            src="./images/vp.JPG"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>
                                                                        
                                                                    </div><p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>Vardhaman Pressings </p></a>
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="filter-item  mt-4 col-md-3 col-sm-6 col-xs-12 " >
                                                                <div class="single-project-item">
                                                                    <a href='' target='_blank'>
                                                                    <div class="img-holder card_dd">
                                                                        <img decoding="async" width="200" height="220"
                                                                            src="./images/vpw.JPG"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>
                                                                        
                                                                    </div><p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>	Vijayalakshmi Pattern Works</p></a>
                                                                </div>
                                                            </div>


                                                            <div
                                                                class="filter-item environment mt-4 col-md-3 col-sm-6 col-xs-12 " >
                                                                <div class="single-project-item">
                                                                    <a href='https://www.zkteco.in' target='_blank'>
                                                                    <div class="img-holder card_dd" >
                                                                        <img decoding="async" width="200" height="220"
                                                                            src="./images/ZKTlogo.png"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>
                                                                       
                                                                    </div>
                                                                    <p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>ZKTeco</p>
                                                                    </a>
                                                                </div>
                                                            </div>


                                                            <div
                                                                class="filter-item material-science technology mt-4 col-md-3 col-sm-6 col-xs-12 " >
                                                                <div class="single-project-item">
                                                                    <a href='' target='_blank'>
                                                                    <div class="img-holder card_dd">
                                                                        <img decoding="async" width="200" height="220"
                                                                            src="./images/vega.png"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>
                                                                        
                                                                    </div><p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>Vega Automotive LTD</p></a>
                                                                </div>
                                                            </div>

                                                            <div
                                                                class="filter-item chemical technology mt-4 col-md-3 col-sm-6 col-xs-12 " >
                                                                <div class="single-project-item">
                                                                    <a href='https://www.vimanmultiplug.com' target='_blank'>
                                                                    <div class="img-holder card_dd" >
                                                                        <img decoding="async" width="200" height="220"
                                                                            src="./images/vimanmultiplug-logo.jpg"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>
                                                                       
                                                                    </div>
                                                                    <p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>Viman</p>
                                                                    </a>
                                                                </div>
                                                            </div>


                                                            {/* <div
                                                                class="filter-item chemical  col-md-3 col-sm-6 col-xs-12 " >
                                                                    
                                                                <div class="single-project-item">
                                                                <a href='https://ifymould.in/' target='_blank'>
                                                                    <div class="img-holder card_dd">
                                                                        <img decoding="async" width="200" height="220"
                                                                            src="./images/IFYLogo.png"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>

                                                                    </div>
                                                                    <p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>Ify Mould Tech</p>
                                                                    </a>
                                                                </div>
                                                            </div> */}

                                                            {/* <div
                                                                class="filter-item chemical technology  col-md-3 col-sm-6 col-xs-12 ">
                                                                <div class="single-project-item">
                                                                <a href='https://instruments-india.com/' target='_blank'>
                                                                    <div class="img-holder  card_dd" >
                                                                        
                                                                        <img loading="lazy" decoding="async" width="200"
                                                                            height="220"
                                                                            src="./images/insindialogo.jpeg"
                                                                            class=""
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>
                                                                        
                                                                    </div>
                                                                    <p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>Instruments-India</p>
                                                                    </a>
                                                                </div>
                                                            </div> */}

                                                            {/* <div
                                                                class="filter-item chemical technology  col-md-3 col-sm-6 col-xs-12 ">
                                                                <div class="single-project-item" style={{marginBottom:"5px"}}>
                                                                    <a href='https://www.excelmetalprocessors.com' target='_blank'>
                                                                    <div class="img-holder card_dd" >
                                                                        <img fetchpriority="high" decoding="async"
                                                                            width="200" height="220"
                                                                            src="./images/ExcelMetal.jpeg"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>
                                                                        
                                                                    </div>
                                                                    <p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>Excel Metal Processors</p>
                                                                    </a>
                                                                </div>
                                                            </div> */}

                                                           

                                                            {/* <div
                                                                class="filter-item chemical technology mt-4 col-md-3 col-sm-6 col-xs-12 " >
                                                                <div class="single-project-item">
                                                                    <a href='https://www.vimanmultiplug.com' target='_blank'>
                                                                    <div class="img-holder card_dd" >
                                                                        <img decoding="async" width="200" height="220"
                                                                            src="./images/vimanmultiplug-logo.jpg"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>
                                                                       
                                                                    </div>
                                                                    <p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>Viman</p>
                                                                    </a>
                                                                </div>
                                                            </div> */}

                                                            {/* <div
                                                                class="filter-item environment mt-4 col-md-3 col-sm-6 col-xs-12 " >
                                                                <div class="single-project-item">
                                                                    <a href='https://www.zkteco.in' target='_blank'>
                                                                    <div class="img-holder card_dd" >
                                                                        <img decoding="async" width="200" height="220"
                                                                            src="./images/ZKTlogo.png"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>
                                                                       
                                                                    </div>
                                                                    <p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>ZKTeco</p>
                                                                    </a>
                                                                </div>
                                                            </div> */}

                                                            {/* <div
                                                                class="filter-item environment technology mt-4 col-md-3 col-sm-6 col-xs-12 " >
                                                                <div class="single-project-item" >
                                                                    <a href='https://rippllighting.com/' target='_blank'>
                                                                    <div class="img-holder card_dd" >
                                                                        <img loading="lazy" decoding="async" width="200"
                                                                            height="220"
                                                                            src="./images/RipplLight.png"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"4px"}}/>
                                                                        <div class="overlay-style-one">
                                                                            <div class="box">
                                                                                
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>Reflections Interior Pvt.Ltd</p>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                             */}
                                                            {/* <div
                                                                class="filter-item environment technology mt-4 col-md-3 col-sm-6 col-xs-12 ">
                                                                <div class="single-project-item" style={{marginBottom:"5px"}}>
                                                                    <a href='https://www.dtransems.com' target='_blank'>
                                                                    <div class="img-holder  card_dd" >
                                                                        <img fetchpriority="high" decoding="async"
                                                                            width="200" height="220"
                                                                            src="./images/DtransLogo.png"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>
                                                                       
                                                                    </div><p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>Dtrans</p></a>
                                                                </div>
                                                            </div> */}

                                                          

                                                            {/* <div
                                                                class="filter-item environment technology mt-4 col-md-3 col-sm-6 col-xs-12 ">
                                                                <div class="single-project-item" style={{marginBottom:"5px"}}>
                                                                    <div class="img-holder card_dd">
                                                                        <img fetchpriority="high" decoding="async"
                                                                            width="200" height="220"
                                                                            src="./images/ambertsp.png"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>
                                                                        
                                                                    </div><p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>S.P. Industries</p>
                                                                </div>
                                                            </div> */}

                                                            

                                                           

                                                            {/* <div
                                                                class="filter-item material-science mt-4 technology col-md-3 col-sm-6 col-xs-12 " >
                                                                <div class="single-project-item">
                                                                    <div class="img-holder card_dd" >
                                                                        <img loading="lazy" decoding="async" width="200"
                                                                            height="220"
                                                                            src="./images/HerbalCreationsLogo.png"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>
                                                                       
                                                                    </div><p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>Herbal Creations</p>
                                                                </div>
                                                            </div> */}


                                                            {/* <div
                                                                class="filter-item agriculture technology mt-4 col-md-3 col-sm-6 col-xs-12 " >
                                                                <div class="single-project-item">
                                                                    <a href='https://www.rite-equipment.com' target='_blank'>
                                                                    <div class="img-holder card_dd" >
                                                                        <a href=''>
                                                                        <img fetchpriority="high" decoding="async"
                                                                            width="270" height="220"
                                                                            src="./images/RiteEqp.png"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/></a>
                                                                        
                                                                    </div><p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>Rite Equipment</p>
                                                                    </a>
                                                                </div>
                                                            </div> */}


                                                            {/* <div
                                                                class="filter-item material-science mt-4 technology  col-md-3 col-sm-6 col-xs-12 ">
                                                                <div class="single-project-item" >
                                                                    <div class="img-holder card_dd">
                                                                        <img fetchpriority="high" decoding="async"
                                                                            width="200" height="220"
                                                                            src="./images/onlineinstrument.png"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>
                                                                       
                                                                    </div><p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>Online Instrument</p>
                                                                </div>
                                                            </div> */}

                                                               
                                                            {/* <div
                                                                class="filter-item material-science technology mt-4 col-md-3 col-sm-6 col-xs-12 " >
                                                                <div class="single-project-item">
                                                                    <div class="img-holder card_dd" >
                                                                        <img decoding="async" width="200" height="220"
                                                                            src="./images/jklogo.jpg"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>
                                                                        
                                                                    </div><p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>JK Bank</p>
                                                                </div>
                                                            </div> */}

                                                           
                                                            {/* <div
                                                                class="filter-item material-science technology mt-4 col-md-3 col-sm-6 col-xs-12 " >
                                                                <div class="single-project-item">
                                                                    <a href='https://itdeskmahadevpura.com/' target='_blank'>
                                                                    <div class="img-holder card_dd">
                                                                        <img decoding="async" width="200" height="220"
                                                                            src="./images/2.png"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>
                                                                        
                                                                    </div><p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>ITDesk</p></a>
                                                                </div>
                                                            </div> */}


                                                            {/* <div
                                                                class="filter-item material-science technology mt-4 col-md-3 col-sm-6 col-xs-12 " >
                                                                <div class="single-project-item">
                                                                    <a href='' target='_blank'>
                                                                    <div class="img-holder card_dd">
                                                                        <img decoding="async" width="200" height="220"
                                                                            src="./images/carbon.png"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>
                                                                        
                                                                    </div><p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>Bangalore Carbon</p></a>
                                                                </div>
                                                            </div> */}

                                                            {/* <div
                                                                class="filter-item material-science technology mt-4 col-md-3 col-sm-6 col-xs-12 " >
                                                                <div class="single-project-item">
                                                                    <a href='' target='_blank'>
                                                                    <div class="img-holder card_dd">
                                                                        <img decoding="async" width="200" height="220"
                                                                            src="./images/coren.png"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>
                                                                        
                                                                    </div><p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>Coretec Engineering India</p></a>
                                                                </div>
                                                            </div> */}

                                                            {/* <div
                                                                class="filter-item material-science technology mt-4 col-md-3 col-sm-6 col-xs-12 " >
                                                                <div class="single-project-item">
                                                                    <a href='' target='_blank'>
                                                                    <div class="img-holder card_dd">
                                                                        <img decoding="async" width="200" height="220"
                                                                            src="./images/good.png"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>
                                                                        
                                                                    </div><p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>Good Well</p></a>
                                                                </div>
                                                            </div> */}


                                                            {/* <div
                                                                class="filter-item material-science technology mt-4 col-md-3 col-sm-6 col-xs-12 " >
                                                                <div class="single-project-item">
                                                                    <a href='' target='_blank'>
                                                                    <div class="img-holder card_dd">
                                                                        <img decoding="async" width="200" height="220"
                                                                            src="./images/harini.png"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>
                                                                        
                                                                    </div><p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>Harini Industry</p></a>
                                                                </div>
                                                            </div> */}


                                                            {/* <div
                                                                class="filter-item material-science technology mt-4 col-md-3 col-sm-6 col-xs-12 " >
                                                                <div class="single-project-item">
                                                                    <a href='' target='_blank'>
                                                                    <div class="img-holder card_dd">
                                                                        <img decoding="async" width="200" height="220"
                                                                            src="./images/lynx.png"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>
                                                                        
                                                                    </div><p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>Lynx industries</p></a>
                                                                </div>
                                                            </div> */}

                                                            {/* <div
                                                                class="filter-item material-science technology mt-4 col-md-3 col-sm-6 col-xs-12 " >
                                                                <div class="single-project-item">
                                                                    <a href='' target='_blank'>
                                                                    <div class="img-holder card_dd">
                                                                        <img decoding="async" width="200" height="220"
                                                                            src="./images/qu1.png"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>
                                                                        
                                                                    </div><p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>Quantum Power System</p></a>
                                                                </div>
                                                            </div> */}

                                                            {/* <div
                                                                class="filter-item material-science technology mt-4 col-md-3 col-sm-6 col-xs-12 " >
                                                                <div class="single-project-item">
                                                                    <a href='' target='_blank'>
                                                                    <div class="img-holder card_dd">
                                                                        <img decoding="async" width="200" height="220"
                                                                            src="./images/raja.png"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>
                                                                        
                                                                    </div><p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>Rajarajeshwari Industries</p></a>
                                                                </div>
                                                            </div> */}

                                                            {/* <div
                                                                class="filter-item material-science technology mt-4 col-md-3 col-sm-6 col-xs-12 " >
                                                                <div class="single-project-item">
                                                                    <a href='' target='_blank'>
                                                                    <div class="img-holder card_dd">
                                                                        <img decoding="async" width="200" height="220"
                                                                            src="./images/rohid.png"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>
                                                                        
                                                                    </div><p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>Rohid Enterprises</p></a>
                                                                </div>
                                                            </div> */}

                                                            {/* <div
                                                                class="filter-item material-science technology mt-4 col-md-3 col-sm-6 col-xs-12 " >
                                                                <div class="single-project-item">
                                                                    <a href='' target='_blank'>
                                                                    <div class="img-holder card_dd">
                                                                        <img decoding="async" width="200" height="220"
                                                                            src="./images/sainth.png"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>
                                                                        
                                                                    </div><p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>Sainath Enterprises</p></a>
                                                                </div>
                                                            </div>

                                                            <div
                                                                class="filter-item material-science technology mt-4 col-md-3 col-sm-6 col-xs-12 " >
                                                                <div class="single-project-item">
                                                                    <a href='' target='_blank'>
                                                                    <div class="img-holder card_dd">
                                                                        <img decoding="async" width="200" height="220"
                                                                            src="./images/sakj.png"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>
                                                                        
                                                                    </div><p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>Sukhjeevan Engineering Works</p></a>
                                                                </div>
                                                            </div>

                                                            <div
                                                                class="filter-item material-science technology mt-4 col-md-3 col-sm-6 col-xs-12 " >
                                                                <div class="single-project-item">
                                                                    <a href='' target='_blank'>
                                                                    <div class="img-holder card_dd">
                                                                        <img decoding="async" width="200" height="220"
                                                                            src="./images/sridd.png"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>
                                                                        
                                                                    </div><p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>Sri Aditya Industries</p></a>
                                                                </div>
                                                            </div>

                                                            <div
                                                                class="filter-item material-science technology mt-4 col-md-3 col-sm-6 col-xs-12 " >
                                                                <div class="single-project-item">
                                                                    <a href='' target='_blank'>
                                                                    <div class="img-holder card_dd">
                                                                        <img decoding="async" width="200" height="220"
                                                                            src="./images/stell.png"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>
                                                                        
                                                                    </div><p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>Steelkraft Hospitality</p></a>
                                                                </div>
                                                            </div> */}

                                                            {/* <div
                                                                class="filter-item material-science technology mt-4 col-md-3 col-sm-6 col-xs-12 " >
                                                                <div class="single-project-item">
                                                                    <a href='' target='_blank'>
                                                                    <div class="img-holder card_dd">
                                                                        <img decoding="async" width="200" height="220"
                                                                            src="./images/trimet.png"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>
                                                                        
                                                                    </div><p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>Trimetric Technology</p></a>
                                                                </div>
                                                            </div>

                                                            <div
                                                                class="filter-item material-science technology mt-4 col-md-3 col-sm-6 col-xs-12 " >
                                                                <div class="single-project-item">
                                                                    <a href='' target='_blank'>
                                                                    <div class="img-holder card_dd">
                                                                        <img decoding="async" width="200" height="220"
                                                                            src="./images/vb.png"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>
                                                                        
                                                                    </div><p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>VB Groups</p></a>
                                                                </div>
                                                            </div>


                                                            <div
                                                                class="filter-item material-science technology mt-4 col-md-3 col-sm-6 col-xs-12 " >
                                                                <div class="single-project-item">
                                                                    <a href='' target='_blank'>
                                                                    <div class="img-holder card_dd">
                                                                        <img decoding="async" width="200" height="220"
                                                                            src="./images/vega.png"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>
                                                                        
                                                                    </div><p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>Vega Automotive LTD</p></a>
                                                                </div>
                                                            </div> */}

                                                            {/* <div
                                                                class="filter-item material-science technology mt-4 col-md-3 col-sm-6 col-xs-12 " >
                                                                <div class="single-project-item">
                                                                    <a href='https://www.excerptech.com/' target='_blank'>
                                                                    <div class="img-holder card_dd">
                                                                        <img decoding="async" width="200" height="220"
                                                                            src="./images/excerpt.png"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>
                                                                        
                                                                    </div><p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>Excerpt Technologies Pvt.Ltd</p></a>
                                                                </div>
                                                            </div> */}

                                                            {/* <div
                                                                class="filter-item material-science technology mt-4 col-md-3 col-sm-6 col-xs-12 " >
                                                                <div class="single-project-item">
                                                                    <a href='' target='_blank'>
                                                                    <div class="img-holder card_dd">
                                                                        <img decoding="async" width="200" height="220"
                                                                            src="./images/caddesk.png"
                                                                            class="attachment-post-thumbnail size-post-thumbnail wp-post-image responsive_company_logo"
                                                                            alt="" style={{width:"200px",marginTop:"8px"}}/>
                                                                        
                                                                    </div><p style={{color:"black",fontFamily:"cursive",fontSize:"19px",textAlign:"center"}}>Excerpt Technologies Pvt.Ltd</p></a>
                                                                </div>
                                                            </div> */}
                                                            
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </section></div>
                   
            </div>
        </div>
    )
}

export default Welcome
