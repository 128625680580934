import React from 'react'
import TimelineView from './TimelineView'

const timelineItemsList = [
    {
      id: 'c6aad2fb-7673-45cf-9606-a335acc0cf4b',
      categoryId: 'COURSE',
      
      courseTitle: 'Gangadhar R',
      description:
        'Build your own personal portfolio website and a website to host conferences and events.  Publish your website and share it with your friends, family and beyond.',
      duration: '10 days',
     
    },
    {
      id: 'a19d93d6-bdac-479e-b554-974ef9e6e66c',
      categoryId: 'PROJECT',
      
      projectTitle: 'Rama Subramanya',
      description:
        'A tourism website enables the user to browse through the images of popular destinations.',
      imageUrl: 'https://assets.ccbp.in/frontend/react-js/projects-s4-img.png',
      duration: '1 hr',
      projectUrl: 'https://tourismapp.ccbp.tech/',
    },
    {
      id: '40b82899-fdf7-4a3e-a43a-41a9917b4582',
      categoryId: 'COURSE',
      
      courseTitle: 'Rajendra',
      description:
        'Build a responsive website that appears beautifully on the screen of any size. Develop a personal portfolio website, an E-commerce product listing website and a website to host conferences and events.',
      duration: '12 days',
    
    },
    {
      id: 'ae2ede68-af77-427c-817c-0ce4beeb69c7',
      categoryId: 'PROJECT',
      
      projectTitle: 'Basavaraja T',
      description: 'Food Much Website is a user-centric food tech website.',
      imageUrl: 'https://assets.ccbp.in/frontend/react-js/projects-r2-img.png',
      duration: '2 hrs',
      projectUrl: 'https://fm.ccbp.tech/',
    },
    {
      id: '4938c3d1-81cd-4729-9d2c-dcd50796aa4d',
      categoryId: 'COURSE',
     
      courseTitle: 'Ashwatha narayana',
      description:
        'Learn the fundamental concepts in JavaScript and apply them to build dynamic and interactive web applications like Counter, Guessing Game, Chat Web app, E-commerce web app, etc.',
      duration: '20 days',
     
    },
    {
      id: 'd6c4b3a5-7b1d-4906-aca8-823f44129004',
      categoryId: 'PROJECT',
      
      projectTitle: 'Gopinatha N.C',
      description:
        'This app helps users to track the day to day tasks. Users can create, edit, track the status of each todo item and able to persist them over page reloads.',
      imageUrl:
        'https://assets.ccbp.in/frontend/react-js/appointments-app/appointments-img.png',
      duration: '3 hrs',
      projectUrl: 'https://todossapp.ccbp.tech/',
    },
    {
      id: '0a35abbe-22ca-40a1-81da-613f656b7702',
      categoryId: 'PROJECT',
      
      projectTitle: 'Mr.s Rama',
      description:
        'Using this Wikipedia Search Application users can search and view curated results and can see detailed explanations in Wikipedia by clicking on the specific result.',
      imageUrl: 'https://assets.ccbp.in/frontend/react-js/wiki-logo-img.png',
      duration: '4 hrs',
      projectUrl: 'https://wikiseaarch.ccbp.tech/',
    },
    {
      id: 'd80781c3-980e-4ab9-96ca-9ad1a9cdf93d',
      categoryId: 'COURSE',
      
      courseTitle: 'Pinaki Majumdra',
      description:
        'Learn to develop responsive layouts using CSS Flexbox and CSS Media Queries.',
      duration: '7 days',
     
    },
    {
      id: '7bc3f006-f0f1-4574-924b-17c480556727',
      categoryId: 'PROJECT',
      
      projectTitle: 'Mahalingan',
      description:
        'The landing page of Move Messenger gives you a brief intro of Move Messenger. The landing page is responsive enabling to view it across various devices.',
      imageUrl:
        'https://assets.ccbp.in/frontend/intermediate-rwd/move-messenger-img.png',
      duration: '5 hr',
      projectUrl: 'https://movemessengers.ccbp.tech/',
    },
    {
      id: 'e681e826-260c-4540-9ee5-f53d0e6ecba0',
      categoryId: 'COURSE',
      
      courseTitle: 'Shefiffaa',
      description:
        'Learn how to build dynamic web applications with the React JS library. When you finish this course, you will be comfortable in creating a modern, clean, and maintainable application in React JS, from scratch.',
      duration: '18 days',
     
    },
    {
      id: 'e093c08a-a2ae-413a-814b-e7c83f5f2ac3',
      categoryId: 'PROJECT',
      
      projectTitle: 'K r Subramanian',
      description:
        'Nxt Trendz application is an E-commerce application like Amazon, Flipkart where users can log in and see the list of products with search, filters, sort by, etc.',
      imageUrl: 'https://assets.ccbp.in/frontend/react-js/nxt-trendz-img.png',
      duration: '6 hrs',
      projectUrl: 'https://nxtz.ccbp.tech/',
    },

    {
        id: 'e093c08a-a2ae-413a-814b-e7c83f5f2ac3',
        categoryId: 'PROJECT',
        
        projectTitle: 'Jayveecons',
        description:
          'Nxt Trendz application is an E-commerce application like Amazon, Flipkart where users can log in and see the list of products with search, filters, sort by, etc.',
        imageUrl: 'https://assets.ccbp.in/frontend/react-js/nxt-trendz-img.png',
        duration: '6 hrs',
        projectUrl: 'https://nxtz.ccbp.tech/',
      },
  ]

const Timelinelist = () => {
  return (
    <div>
       <TimelineView timelineItemsList={timelineItemsList} />
    </div>
  )
}

export default Timelinelist
