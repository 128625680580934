import React from 'react'
import './ProjectTimeLine.css'

const GroupImage2 = () => {
  return (
    
       <div className='timeline_container'>
      <div class="mask1">
       
        <img src="http://diea.in/img/Gallery/d4.jpeg"  alt="Cinque Terre" width="600" height="400" style={{marginTop:"15px",borderRadius:"30px",transition: 'transform 0.6s',width:"-webkit-fill-available"}} class="image_responsive"  onMouseOver={(e) => e.target.style.transform = 'scale(1.1)'}
                      onMouseOut={(e) => e.target.style.transform = 'scale(1)'}/>
       
      </div>
    </div>
    
  )
}

export default GroupImage2
