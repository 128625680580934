import React from 'react';


const AdminBlock = () => {
    const presidents = [
            {
              slNo: "1",
              name: "Sri Ramesh Menon P",
              designation:"President",
              mobile: "9449823445",
              company: "M.V. Technics",
              address: "B-54 Dyavasandra Industrial Estate, Whitefield Road, Bangalore-560048",
            },
            {
              slNo: "2",
              name: "Sri Sheshadri Gowda",
              designation:"Vice-President",
              mobile: "9845893689",
              company: "Tooltech",
              address: "C-102 Dyavasandra Industrial Estate, Whitefield Road, Bangalore-560048",
            },
            {
              slNo: "3",
              name: "Mr. Sheriff A A",
              designation:"Immediate Past President",
              mobile: "9844111687",
              company: "Excel Metal Process",
              address: "Shed No.B-107, Dyavasandra Industrial Estate, Whitefield Road, Bangalore-560048",
            },
            {
              slNo: "4",
              name: "Sri Gopinathan N C",
              designation:"Hon.Secretary",
              mobile: "9845065812",
              company: "Bangalore Carbons",
              address: "C-89 Dyavasandra Industrial Estate, Whitefield Road, Bangalore-560048",
            },
            {
              slNo: "5",
              name: "Sri Vijay Mehta",
              designation:"Joint Secretary",
              mobile: "9900504783",
              company: "Vardhaman Pressings",
              address: "C-69/A. Dyavasandra Industrial Estate, Whitefield Road, Bangalore-560048",
            },
            {
              slNo: "6",
              name: "Sri Ilango K",
              designation:"Treasurer",
              mobile: "9845109360",
              company: "Sri Engineering",
              address: "B-63 Dyavasandra Industrial Estate, Whitefield Road, Bangalore-560048",
            },
            {
              slNo: "7",
              name: "Sri Basavaraj T Ugranad",
              designation:"Council Member",
              mobile: "9448103600",
              company: "M. R. Engineers",
              address: "C-68 Dyavasandra Industrial Estate, Whitefield Road, Bangalore-560048",
            },
            {
              slNo: "8",
              name: "Sri Dr. Nagaraja S L",
              designation:"Council Member",
              mobile: "9845511533",
              company: "Pretomatics",
              address: "B-15 Dyavasandra Industrial Estate, Whitefield Road, Bangalore-560048",
            },
            {
              slNo: "9",
              name: "Sri Krishna Murthy Rohidekar",
              designation:"Council Member",
              mobile: "9886334386",
              company: "Rohid Enterprises",
              address: "Shed No.:G6, D. I. E., Bangalore-48.",
            },
            {
              slNo: "10",
              name: "Sri Narayana Shetty",
              designation:"Council Member",
              mobile: "9341288545",
              company: "Priya Pipe Products",
              address: "P-11 Dyavasandra Industrial Estate, Whitefield Road, Bangalore-560048",
            },
            {
              slNo: "11",
              name: "Sri Ramana",
              designation:"Council Member",
              mobile: "9880713143",
              company: "Classic Fastners Inc.",
              address: "Plot No.1/1 Dyavasandra Industrial Estate, Whitefield Road, Bangalore-560048",
            },
            {
              slNo: "12",
              name: "Sri Ravi",
              designation:"Council Member",
              mobile: "9900103229",
              company: "Bharat Press Components",
              address: "B-46, Dyavasandra Industrial Estate, Whitefield Road, Bangalore-560048",
            },
            {
              slNo: "13",
              name: "Sri Shivanand Rajamane",
              designation:"Council Member",
              mobile: "9986079318",
              company: "Meghana Motor Services",
              address: "Dyavasandra Industrial Estate, Whitefield Road, Bangalore-560048",
            },
            {
              slNo: "14",
              name: "Sri Nagaraja Kharadapudi",
              designation:"Council Member",
              mobile: "9900329577",
              company: "Sree Spiral Fabricators",
              address: "B133/1, D. I. E. Bangalore-48",
            },
          ];
          
    
  return (





    
      <div className="container">
        <h2 style={{ marginTop:'-30px' ,textAlign: 'center',fontSize:'25px'}}>LIST OF OFFICE BEARERS & COUNCIL MEMBERS OF DIEA FOR 2023-24</h2>
        <div class="elementor-element elementor-element-b88e1b2 elementor-widget-divider--view-line elementor-widget elementor-widget-divider" data-id="b88e1b2" data-element_type="widget" data-widget_type="divider.default"><div class="elementor-widget-container"><span class="elementor-divider-separator"></span></div></div><br/>
        <div className="table-responsive">
          <table className="table table-bordered" style={{ width: '100%' }}>
            <thead>
              <tr>
                <th>Sl. No</th>
                <th>Name</th>
                <th>Designation in Association</th>
                <th>Company</th>
                <th>Mobile</th>
              </tr>
            </thead>
            <tbody>
              {presidents.map((company, index) => (
                <tr key={index}>
                  <td>{company.slNo}</td>
                  <td>{company.name}</td>
                  <td>{company.designation}</td>
                  <td>{company.company}</td>
                  <td>{company.mobile}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    
    
  );
};

export default AdminBlock;
