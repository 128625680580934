import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import './Login.css'

const Login = ({ onLogin }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loggedIn, setLoggedIn] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
    setError(""); // Clear error message when user starts typing again
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    setError(""); // Clear error message when user starts typing again
  };

  const handleLogin = (event) => {
    event.preventDefault(); // Prevent form submission
    // Perform login logic
    if (username === "DieaAdmin" && password === "Admin$2024") {
      setLoggedIn(true);
      onLogin();
      navigate("/admin");
    } else {
      setError("Incorrect username or password"); // Set error message
    }
  };

  return (
    <>
    {/* <div>
      {loggedIn ? (
        <div>
          <h2>Welcome, {username}!</h2>
        </div>
      ) : (
        <div>
          <p className="title_login">Login</p>
          <form className="login-form" onSubmit={handleLogin}>
            <div className="form-group">
              <label htmlFor="username" className="label">Username:</label>
              <input
                className="input"
                type="text"
                id="username"
                value={username}
                onChange={handleUsernameChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="password">Password:</label>
              <input
                className="input"
                type="password"
                id="password"
                value={password}
                onChange={handlePasswordChange}
                required
              />
            </div>
            {error && <div className="error-message">{error}</div>}
            <div className="form-group">
              <button className="login-button" type="submit">
                Login
              </button>
            </div>
          </form>
        </div>
      )}
    </div> */}
    <div className="form_container">
    {loggedIn ? ( 
      <div>
      <h2 className="title_login">Welcome, {username}!</h2>
    </div>): (
      <form class="form" onSubmit={handleLogin}>
          <p class="title_login">Login</p>
              
          <label>
              <input required="" placeholder="" type="text" class="input" value={username}   onChange={handleUsernameChange}/>
              <span>Username</span>
          </label> 
              
          <label>
              <input required="" placeholder="" type="password" class="input" value={password} onChange={handlePasswordChange}/>
              <span>Password</span>
          </label>
          {error && <div className="error-message">{error}</div>}
          <button class="submit" type="submit">login</button>


      </form>)}
      </div>
</>


  );
};

export default Login;