import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useState } from "react";
import Home from "./Pages/Home";
import Soltution from "./Pages/Solution";
import ContactUs from "./Pages/ContactUs";
import Gallery from "./Pages/Gallery";
import Topheader from "./Components/Topheader";
import Footer from "./Components/Footer";
import Services from "./Pages/Services";
import Companies from "./Components/Companies";
import ScrollToTopButton from './Components/ScrollToTopButton'
import WhatsAppButton from './Components/WhatsAppButton'
import NavBar from "./Components/NavBar";
import Admin from './Components/Admin'
import Login from "./Components/Login";
import ThankYouPage from './Pages/ThankYou'



function App() {

  const [loggedIn, setLoggedIn] = useState(false);
  const [username, setUsername] = useState('');

  const handleLogin = (enteredUsername) => {
    setUsername(enteredUsername);
    setLoggedIn(true);
  };

  const handleLogout = () => {
    setUsername('');
    setLoggedIn(false);
  };

  return (
   <>
 
     <Router>
     <Topheader />
     <NavBar loggedIn={loggedIn} onLogout={handleLogout}/>
                     
     
      <Routes>
        
          <Route path="/" element={<Home/>}/>
          <Route path="/about" element={<Services />}/>
          <Route path="/solution" element={<Soltution/>}/>
          <Route path="/Gallery" element={<Gallery/>}/>
          <Route path="/Companies" element={<Companies/>}/>
          <Route path="/ContactUs" element={<ContactUs/>}/>
          <Route path='/login' element={<Login onLogin={handleLogin}/>}/>
          <Route path="/" element={<Home loggedIn={loggedIn} username={username} onLogout={handleLogout} />}/>
          <Route path="/admin" element={<Admin/>} />
          <Route path="/ThankYou" element={<ThankYouPage/>} />
      </Routes>
      <Footer/>
     </Router>
     <ScrollToTopButton/>
     <WhatsAppButton/>
     </>
   
   
  );
}

export default App;
