import React from 'react'
import './ProjectTimeLine.css'

const GroupImage = () => {
  return (
    
       <div className='timeline_container' style={{    marginTop: "-19px"}}>
      <div class="mask1">
        <img src="http://diea.in/img/Gallery/d7.jpeg" alt="Cinque Terre" width="600" height="400" style={{borderRadius:"30px",transition: 'transform 0.6s'}} class="image_responsive"  onMouseOver={(e) => e.target.style.transform = 'scale(1.1)'}
                      onMouseOut={(e) => e.target.style.transform = 'scale(1)'}/>
       
      </div>
    </div>
    
  )
}

export default GroupImage
