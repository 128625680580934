import React, { useState } from 'react';
import { FaYoutube } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaPhoneAlt } from "react-icons/fa";
import { IoLocation } from "react-icons/io5";
import { IoMdArrowForward } from "react-icons/io";
import { MdMapsHomeWork } from "react-icons/md";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import './Footer.css'
import { Link } from 'react-router-dom';
const Footer = () => {

    const [popupVisible, setPopupVisible] = useState(false);

    const openPopup = () => {
        setPopupVisible(true);
    };

    const closePopup = () => {
        setPopupVisible(false);
    };

    const handleOutsideClick = (event) => {
        if (event.target.classList.contains('popup-overlay')) {
            setPopupVisible(false);
        }
    };

  return (
    <div data-elementor-type="wp-post" data-elementor-id="6518" class="elementor elementor-6518">
        <section class="elementor-section elementor-top-section elementor-element elementor-element-c631fc1 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="c631fc1" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                <div class="elementor-container elementor-column-gap-no">
                    <div class="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-22d48c1" data-id="22d48c1" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <div class="elementor-element elementor-element-38cf17b elementor-position-left elementor-tablet-position-top elementor-view-default elementor-mobile-position-top elementor-vertical-align-top elementor-widget elementor-widget-icon-box" data-id="38cf17b"
                                data-element_type="widget" data-widget_type="icon-box.default">
                                <div class="elementor-widget-container">
                                    <div class="elementor-icon-box-wrapper">
                                        <div class="elementor-icon-box-icon">
                                            <a href="#" class="elementor-icon elementor-animation-" tabindex="-1">
				<FaPhoneAlt style={{fontSize:"18px"}} />
                		</a>
                                        </div>
                                        <div class="elementor-icon-box-content">
                                            <p class="elementor-icon-box-title">
                                                
						Phone: +91 9686976679					
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-51e56c9" data-id="51e56c9" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <div class="elementor-element elementor-element-51cfa67 elementor-position-left elementor-tablet-position-top elementor-view-default elementor-mobile-position-top elementor-vertical-align-top elementor-widget elementor-widget-icon-box" data-id="51cfa67"
                                data-element_type="widget" data-widget_type="icon-box.default">
                                <div class="elementor-widget-container">
                                    <div class="elementor-icon-box-wrapper">
                                        <div class="elementor-icon-box-icon">
                                            <span class="elementor-icon elementor-animation-">
				<IoLocation style={{fontSize:"18px"}} />
                		</span>
                                        </div>
                                        <div class="elementor-icon-box-content">
                                            <h3 class="elementor-icon-box-title">
                                                <span>
                                                Plot No.Spl.2(a),2nd Cross,Dyavasandra Industrial Estate, Whitefield Road, Mahadevpura Post, Bangalore-560048					</span>
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-07c3140" data-id="07c3140" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <div class="elementor-element elementor-element-054692a elementor-position-left elementor-tablet-position-top elementor-view-default elementor-mobile-position-top elementor-vertical-align-top elementor-widget elementor-widget-icon-box" data-id="054692a"
                                data-element_type="widget" data-widget_type="icon-box.default">
                                <div class="elementor-widget-container">
                                    <div class="elementor-icon-box-wrapper">
                                        <div class="elementor-icon-box-icon">
                                            <span class="elementor-icon elementor-animation-">
                                            <MdMapsHomeWork />			
                </span>
                                        </div>
                                        <div class="elementor-icon-box-content">
                                            <h3 class="elementor-icon-box-title">
                                                <span>
						Working Hrs: Mon - Sat: 9am to 6pm					</span>
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
             
            <section class="elementor-section elementor-top-section elementor-element elementor-element-72dffda elementor-section-boxed elementor-section-height-default elementor-section-height-default my_footer_bg" data-id="72dffda" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                <div class="elementor-container elementor-column-gap-no">
                    <div class="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-06ffef3" data-id="06ffef3" data-element_type="column">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <div class="elementor-element elementor-element-f666610 elementor-widget elementor-widget-heading" data-id="f666610" data-element_type="widget" data-widget_type="heading.default">
                               
                            </div>
                            <div class="elementor-element elementor-element-344ddb8 elementor-widget elementor-widget-heading" data-id="344ddb8" data-element_type="widget" data-widget_type="heading.default">
                                <div class="elementor-widget-container">
                                    <img src="./images/dL.jpg" alt="" className='responsive_logo' style={{height:"100px",maxWidth:"300px"}}/>
                                </div>
                            </div>
                          
                        </div>
                    </div>
                   

                    <div class="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-534dfc9" data-id="534dfc9" data-element_type="column">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <div class="elementor-element elementor-element-1b488cc elementor-widget elementor-widget-heading" data-id="1b488cc" data-element_type="widget" data-widget_type="heading.default">
                                <div class="elementor-widget-container">
                                    <h2 class="elementor-heading-title elementor-size-default">LINKS</h2>
                                </div>
                            </div>
                            <div class="elementor-element elementor-element-8c21778 elementor-mobile-align-left elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list" data-id="8c21778" data-element_type="widget" data-widget_type="icon-list.default">
                                <div class="elementor-widget-container">
                                    <ul class="elementor-icon-list-items">
                                        <li class="elementor-icon-list-item">
                                            <a href="/">

												<span class="elementor-icon-list-icon">
							<IoMdArrowForward/>		
                            		</span>
										<span class="elementor-icon-list-text">Home</span>
											</a>
                                        </li>
                                        <li class="elementor-icon-list-item">
                                            <a href="/about">

												<span class="elementor-icon-list-icon">
                                                <IoMdArrowForward/>	
                            			</span>
										<span class="elementor-icon-list-text">About</span>
											</a>
                                        </li>
                                        <li class="elementor-icon-list-item">
                                            <a href="/Companies">

												<span class="elementor-icon-list-icon">
                                                <IoMdArrowForward/>	
                            		</span>
										<span class="elementor-icon-list-text">Companies</span>
											</a>
                                        </li>
                                        <li class="elementor-icon-list-item">
                                            <a href="/Solution">

												<span class="elementor-icon-list-icon">
                                                <IoMdArrowForward/>	
                            		</span>
										<span class="elementor-icon-list-text">Service</span>
											</a>
                                        </li>
                                        <li class="elementor-icon-list-item">
                                            <a href="/Gallery">

												<span class="elementor-icon-list-icon">
                                                <IoMdArrowForward/>	
                            			</span>
										<span class="elementor-icon-list-text">Gallery</span>
											</a>
                                        </li>
                                        <li class="elementor-icon-list-item">
                                            <a href="/ContactUs">

												<span class="elementor-icon-list-icon">
                                                <IoMdArrowForward/>	
                            			</span>
										<span class="elementor-icon-list-text">Contact</span>
											</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                   
                                           <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d31100.6857982593!2d77.698631!3d12.998328!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae110a8e81a2a1%3A0x10adfaa3a1cfa278!2sITI%20Industrial%20Estate!5e0!3m2!1sen!2sin!4v1711091453509!5m2!1sen!2sin" width="1500" height="350" style={{border:'0'}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

                   
                    
                </div>

                <div className='responsive_small_container' style={{marginBottom:"-70px"}}>
        <div>
        <p style={{color:'white'}} className='responsive_diea_footer'>&copy; <Link to='#' style={{color:'white'}}>DIEA 2024</Link>, All Rights Reserved.</p>
        </div>
        <div className='responsive_diea_footer' >
        <a href='https://www.excerptech.com/' target='_black' style={{color:'white'}}>Designed By Excerpt Technologies</a>

        </div>
      </div>
                
            </section>
           

    

            
    </div>
  )
}

export default Footer
