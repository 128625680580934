// import React, { useState, useEffect } from 'react';
// import './Companies.css';
// import './Admin.css';
// import axios from 'axios';
// import Modal from 'react-modal';

// const tableHeadings = ["Serial No.", "Company", "CEO", "Phone", "Email"];
// const formatEmailContent = (subject, message, selectedEmails, ccEmails, bccEmails) => {
//   return `
//     <html>
//       <head>
//         <style>
//           body { font-family: Arial, sans-serif; line-height: 1.6; color: #333; }
//           .email-content { max-width: 600px; margin: 0 auto; padding: 20px; }
//           .subject { font-size: 18px; font-weight: bold; margin-bottom: 10px; }
//           .recipients { margin-bottom: 20px; }
//           .message { white-space: pre-wrap; }
//         </style>
//       </head>
//       <body>
//         <div class="email-content">
        
//           <div class="recipients">
           
//             ${ccEmails.length > 0 ? `<strong>CC:</strong> ${ccEmails.join(', ')}<br>` : ''}
//             ${bccEmails.length > 0 ? `<strong>BCC:</strong> ${bccEmails.join(', ')}<br>` : ''}
//           </div>
//           <div class="message">${message}</div>
//         </div>
//       </body>
//     </html>
//   `;
// };

// const Admin = () => {
//   const [users, setUsers] = useState([]);
//   const [status, setStatus] = useState("Submit");
//   const [filteredData, setFilteredData] = useState([]);
//   const [message, setMessage] = useState('');
//   const [selectedRows, setSelectedRows] = useState([]);
//   const [searchTerm, setSearchTerm] = useState('');
//   const [currentPage, setCurrentPage] = useState(1);
//   const [selectAll, setSelectAll] = useState(false);
//   const [selectedFile, setSelectedFile] = useState(null);
//   const [subject, setSubject] = useState('');
//   const [showModal, setShowModal] = useState(false);
//   const [ccEmails, setCcEmails] = useState([]);
//   const [bccEmails, setBccEmails] = useState([]);
//   const [showCcField, setShowCcField] = useState(false);
//   const [showBccField, setShowBccField] = useState(false);
//   const [ccInputValue, setCcInputValue] = useState('');
//   const [bccInputValue, setBccInputValue] = useState('');
//   const [ccSuggestions, setCcSuggestions] = useState([]);
//   const [bccSuggestions, setBccSuggestions] = useState([]);
//   const [isAnyCheckboxChecked, setIsAnyCheckboxChecked] = useState(false);
//   const [sendResults, setSendResults] = useState({ valid: [], invalid: [] });

//   useEffect(() => {
//     fetchCompanies();
//   }, []);

//   const fetchCompanies = async () => {
//     try {
//       const response = await fetch('/company');
//       if (response.ok) {
//         const data = await response.json();
//         const filteredData = data.filter(item => item.company_name);
//         const sortedData = filteredData.sort((a, b) => (a.company_name || '').localeCompare(b.company_name || ''));
//         setUsers(sortedData);
//         setFilteredData(sortedData);
//       } else {
//         console.error('Failed to fetch companies:', response.status);
//       }
//     } catch (error) {
//       console.error('Error fetching companies:', error);
//     }
//   };

//   const itemsPerPage = 10;
//   const startIndex = (currentPage - 1) * itemsPerPage;
//   const endIndex = startIndex + itemsPerPage;
//   const totalPages = Math.ceil(filteredData.length / itemsPerPage);

//   const handlePaginationClick = (page) => {
//     setCurrentPage(page);
//   };

//   const handleCheckboxChange = (email) => {
//     if (selectedRows.includes(email)) {
//       setSelectedRows(selectedRows.filter((row) => row !== email));
//       setIsAnyCheckboxChecked(selectedRows.length > 1);
//     } else {
//       setSelectedRows([...selectedRows, email]);
//       setIsAnyCheckboxChecked(true);
//     }
//   };

//   const handleSearchTermChange = (e) => {
//     const newSearchTerm = e.target.value;
//     setSearchTerm(newSearchTerm);

//     const filtered = users.filter((item) =>
//       item && item.company_name && item.company_name.toLowerCase().includes(newSearchTerm.toLowerCase())
//     );

//     setFilteredData(filtered);
//     setCurrentPage(1);
//   };

//   const handleMessageChange = (e) => {
//     setMessage(e.target.value);
//   };
  
//   const handleSubjectChange = (e) => {
//     setSubject(e.target.value);
//   };

//   const handleSelectAllChange = () => {
//     setSelectAll(!selectAll);

//     if (!selectAll) {
//       setSelectedRows(filteredData.map((item) => item.Email));
//       setIsAnyCheckboxChecked(true);
//     } else {
//       setSelectedRows([]);
//       setIsAnyCheckboxChecked(false);
//     }
//   };

//   const handleFileChange = (e) => {
//     setSelectedFile(e.target.files[0]);
//   };

//   const handleCancel = () => {
//     setMessage('');
//     setSubject('');
//     setSelectedFile(null);
//     setSelectedRows([]);
//     setCcEmails([]);
//     setBccEmails([]);
//     setSendResults({ valid: [], invalid: [] });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     setStatus("Sending...");
  
//     const ccList = ccEmails.join(', ');
//     const bccList = bccEmails.join(', ');
    
//     const batchSize = 10;
//     const delay = 20000; // 20 seconds delay between batches
//     const emailBatches = [];
//     for (let i = 0; i < selectedRows.length; i += batchSize) {
//       emailBatches.push(selectedRows.slice(i, i + batchSize));
//     }
    
//     try {
//       let allValidRecipients = [];
//       let allInvalidRecipients = [];

//       for (let i = 0; i < emailBatches.length; i++) {
//         const formattedContent = formatEmailContent(subject, message, emailBatches[i], ccEmails, bccEmails);
        
//         const formData = new FormData();
//         formData.append('emails', JSON.stringify(emailBatches[i]));
//         formData.append('cc', ccList);
//         formData.append('bcc', bccList);
//         formData.append('message', formattedContent);
//         formData.append('subject', subject);
//         if (selectedFile) {
//           formData.append('file', selectedFile);
//         }
    
//         const response = await axios.post("/emailall", formData, {
//           headers: {
//             'Content-Type': 'multipart/form-data',
//           },
//         });
//         if (response.status === 200) {
//           const result = response.data;
//           console.log(result.status);
//           console.log("Valid recipients:", result.validRecipients);
//           console.log("Invalid recipients:", result.invalidRecipients);
          
//           allValidRecipients = [...allValidRecipients, ...result.validRecipients];
//           allInvalidRecipients = [...allInvalidRecipients, ...result.invalidRecipients];
          
//           setSendResults({
//             valid: allValidRecipients,
//             invalid: allInvalidRecipients
//           });
//         } else {
//           console.error(response.data.error);
//         }

//         // Add delay between batches
//         if (i < emailBatches.length - 1) {
//           await new Promise(resolve => setTimeout(resolve, delay));
//         }
//       }
    
//       // Clear form after successful submission
//       handleCancel();
//     } catch (error) {
//       console.error('Error sending email:', error);
//     }
    
//     setStatus("Submit");
//     setIsAnyCheckboxChecked(false);
//     closeModal();
//   };

//   const openModal = () => {
//     setShowModal(true);
//   };

//   const closeModal = () => {
//     setShowModal(false);
//     handleCancel();
//     setIsAnyCheckboxChecked(false);
//   };

//   const toggleCcField = () => {
//     setShowCcField(!showCcField);
//   };

//   const toggleBccField = () => {
//     setShowBccField(!showBccField);
//   };

//   const filterSuggestions = (inputValue, suggestions) => {
//     if (!inputValue || !suggestions) {
//       return [];
//     }

//     return suggestions.filter(
//       (suggestion) =>
//         suggestion && suggestion.toLowerCase().includes(inputValue.toLowerCase())
//     );
//   };

//   const handleCcInputChange = (e) => {
//     const inputValue = e.target.value;
//     setCcInputValue(inputValue);
//     setCcEmails(inputValue.split(',').map((email) => email.trim()));
//     const filteredSuggestions = filterSuggestions(inputValue, users.map((user) => user.Email));
//     setCcSuggestions(filteredSuggestions);
//   };

//   const handleBccInputChange = (e) => {
//     const inputValue = e.target.value;
//     setBccInputValue(inputValue);
//     setBccEmails(inputValue.split(',').map((email) => email.trim()));
//     const filteredSuggestions = filterSuggestions(inputValue, users.map((user) => user.Email));
//     setBccSuggestions(filteredSuggestions);
//   };

//   return (
//     <div className="container">
//       <h2 style={{ marginBottom: '10px', marginTop: '100px', textAlign: 'center' }}><b>Emails</b></h2>
//       <button className={`compose-btn ${isAnyCheckboxChecked ? 'enabled' : ''}`} disabled={!isAnyCheckboxChecked} onClick={openModal}>Compose</button>
//       <div className="table-responsive">
//         <table className="table table-bordered" style={{ width: '100%', marginTop: '10px' }}>
//           <thead>
//             <tr>
//               <th>
//                 <input
//                   type="checkbox"
//                   checked={selectAll}
//                   onChange={handleSelectAllChange}
//                 />
//               </th>
//               {tableHeadings.map((heading, index) => (
//                 <th key={index}>{heading}</th>
//               ))}
//             </tr>
//           </thead>
//           <tbody>
//             {filteredData.slice().map((item, index) => (
//               <tr key={index}>
//                 <td>
//                   <input
//                     type="checkbox"
//                     checked={selectedRows.includes(item?.Email)}
//                     onChange={() => handleCheckboxChange(item?.Email)}
//                   />
//                 </td>
//                 <td style={{ color: "black" }}>{startIndex + index + 1}</td>
//                 <td style={{ color: "black" }}>{item.company_name}</td>
//                 <td style={{ color: "black" }}>{item.CEO}</td>
//                 <td style={{ color: "black" }}>{item.Phone}</td>
//                 <td style={{ color: "black" }}>{item.Email}</td>
//               </tr>
//             ))}
//           </tbody>
//         </table>
//       </div>

//       <Modal
//         isOpen={showModal}
//         onRequestClose={closeModal}
//         contentLabel="Compose Email"
//         overlayClassName="modal-overlay"
//       >
//         <div className="modal-content">
//           <div className="modal-header">
//             <h4>Compose Email</h4>
//             <button className="close" onClick={closeModal}>
//               &times;
//             </button>
//           </div>
//           <div className="modal-body" style={{height:'500vh'}}>
//             <div className="selected-emails">
//               <h4>Selected Emails:</h4>
//               <ul>
//                 {selectedRows.map((email, index) => (
//                   <li key={index}>{email}</li>
//                 ))}
//               </ul>
//             </div>
//             <form onSubmit={handleSubmit}>
//               <div className="message-box">
//                 <input
//                   name="subject"
//                   value={subject}
//                   onChange={handleSubjectChange}
//                   placeholder="Subject"
//                   className='responsive_textarea'
//                   required
//                 />
//                 <textarea
//                   name="message"
//                   value={message}
//                   onChange={handleMessageChange}
//                   placeholder="Write your message here"
//                   className='responsive_textarea'
//                   style={{marginTop:'10px'}}
//                   required
//                 ></textarea>
//                 <br />
//                 <button type="button" onClick={toggleCcField}>
//                   {showCcField ? 'Hide CC' : ' CC'}
//                 </button>
//                 {showCcField && (
//                   <div>
//                     <input
//                       name="cc"
//                       value={ccInputValue}
//                       onChange={handleCcInputChange}
//                       placeholder="CC"
//                       className='responsive_textarea'
//                       style={{marginTop:'10px'}}
//                     />
//                     {ccSuggestions.length > 0 && (
//                       <ul>
//                         {ccSuggestions.map((suggestion, index) => (
//                           <li key={index} onClick={() => setCcInputValue(suggestion)}>
//                             {suggestion}
//                           </li>
//                         ))}
//                       </ul>
//                     )}
//                   </div>
//                 )}
//                 <button type="button" onClick={toggleBccField} style={{marginLeft:'10px'}}>
//                   {showBccField ? 'Hide BCC' : ' BCC'}
//                 </button>
//                 {showBccField && (
//                   <div>
//                     <input
//                       name="bcc"
//                       value={bccInputValue}
//                       onChange={handleBccInputChange}
//                       placeholder="BCC"
//                       className='responsive_textarea'
//                       style={{marginTop:'10px'}}
//                     />
//                     {bccSuggestions.length > 0 && (
//                       <ul>
//                         {bccSuggestions.map((suggestion, index) => (
//                           <li key={index} onClick={() => setBccInputValue(suggestion)}>
//                             {suggestion}
//                           </li>
//                         ))}
//                       </ul>
//                     )}
//                   </div>
//                 )}
//                 <br />
//                 <input
//                   type="file"
//                   accept=".pdf, .png, .jpg, .jpeg,.docx,"
//                   onChange={handleFileChange}
//                   style={{ marginBottom: "10px", marginTop: "" }}
//                 />
//                 <br />
//                 <div className='button-container'>
//                   <button type="submit" className="pagintion_button" style={{ marginBottom: "10px", marginTop: "-15px" }} disabled={selectedRows.length === 0 || message.trim() === ''}>
//                     {status}
//                   </button>
//                   <button type="button" className="pagintion_button" style={{ marginBottom: "10px", marginTop: "-15px" }} onClick={closeModal}>
//                     Cancel
//                   </button>
//                 </div>
//               </div>
//             </form>
//             {sendResults.valid.length > 0 || sendResults.invalid.length > 0 ? (
//               <div className="send-results">
//                 <h4>Send Results:</h4>
//                 <p>Valid recipients: {sendResults.valid.join(', ')}</p>
//                 <p>Invalid recipients: {sendResults.invalid.join(', ')}</p>
//               </div>
//             ) : null}
//           </div>
//         </div>
//       </Modal>
//     </div>
//   );
// }

// export default Admin;



// import React, { useState, useEffect } from 'react';
// import './Companies.css';
// import './Admin.css';
// import axios from 'axios';
// import Modal from 'react-modal';

// const tableHeadings = ["Serial No.", "Company", "CEO", "Phone", "Email"];
// const formatEmailContent = (subject, message, ccEmails) => {
//   return `
//     <html>
//       <head>
//         <style>
//           body { font-family: Arial, sans-serif; line-height: 1.6; color: #333; }
//           .email-content { max-width: 600px; margin: 0 auto; padding: 20px; }
//           .subject { font-size: 18px; font-weight: bold; margin-bottom: 10px; }
//           .recipients { margin-bottom: 20px; }
//           .message { white-space: pre-wrap; }
//         </style>
//       </head>
//       <body>
//         <div class="email-content">
//           <div class="recipients">
//             ${ccEmails.length > 0 ? `<strong>CC:</strong> ${ccEmails.join(', ')}<br>` : ''}
//           </div>
//           <div class="message">${message}</div>
//         </div>
//       </body>
//     </html>
//   `;
// };

// const Admin = () => {
//   const [users, setUsers] = useState([]);
//   const [status, setStatus] = useState("Submit");
//   const [filteredData, setFilteredData] = useState([]);
//   const [message, setMessage] = useState('');
//   const [selectedRows, setSelectedRows] = useState([]);
//   const [searchTerm, setSearchTerm] = useState('');
//   const [currentPage, setCurrentPage] = useState(1);
//   const [selectAll, setSelectAll] = useState(false);
//   const [selectedFile, setSelectedFile] = useState(null);
//   const [subject, setSubject] = useState('');
//   const [showModal, setShowModal] = useState(false);
//   const [ccEmails, setCcEmails] = useState([]);
//   const [showCcField, setShowCcField] = useState(false);
//   const [ccInputValue, setCcInputValue] = useState('');
//   const [ccSuggestions, setCcSuggestions] = useState([]);
//   const [isAnyCheckboxChecked, setIsAnyCheckboxChecked] = useState(false);
//   const [sendResults, setSendResults] = useState({ valid: [], invalid: [] });

//   useEffect(() => {
//     fetchCompanies();
//   }, []);

//   const fetchCompanies = async () => {
//     try {
//       const response = await fetch('/company');
//       if (response.ok) {
//         const data = await response.json();
//         const filteredData = data.filter(item => item.company_name);
//         const sortedData = filteredData.sort((a, b) => (a.company_name || '').localeCompare(b.company_name || ''));
//         setUsers(sortedData);
//         setFilteredData(sortedData);
//       } else {
//         console.error('Failed to fetch companies:', response.status);
//       }
//     } catch (error) {
//       console.error('Error fetching companies:', error);
//     }
//   };

//   const itemsPerPage = 10;
//   const startIndex = (currentPage - 1) * itemsPerPage;
//   const endIndex = startIndex + itemsPerPage;
//   const totalPages = Math.ceil(filteredData.length / itemsPerPage);

//   const handlePaginationClick = (page) => {
//     setCurrentPage(page);
//   };

//   const handleCheckboxChange = (email) => {
//     if (selectedRows.includes(email)) {
//       setSelectedRows(selectedRows.filter((row) => row !== email));
//       setIsAnyCheckboxChecked(selectedRows.length > 1);
//     } else {
//       setSelectedRows([...selectedRows, email]);
//       setIsAnyCheckboxChecked(true);
//     }
//   };

//   const handleSearchTermChange = (e) => {
//     const newSearchTerm = e.target.value;
//     setSearchTerm(newSearchTerm);

//     const filtered = users.filter((item) =>
//       item && item.company_name && item.company_name.toLowerCase().includes(newSearchTerm.toLowerCase())
//     );

//     setFilteredData(filtered);
//     setCurrentPage(1);
//   };

//   const handleMessageChange = (e) => {
//     setMessage(e.target.value);
//   };
  
//   const handleSubjectChange = (e) => {
//     setSubject(e.target.value);
//   };

//   const handleSelectAllChange = () => {
//     setSelectAll(!selectAll);

//     if (!selectAll) {
//       setSelectedRows(filteredData.map((item) => item.Email));
//       setIsAnyCheckboxChecked(true);
//     } else {
//       setSelectedRows([]);
//       setIsAnyCheckboxChecked(false);
//     }
//   };

//   const handleFileChange = (e) => {
//     setSelectedFile(e.target.files[0]);
//   };

//   const handleCancel = () => {
//     setMessage('');
//     setSubject('');
//     setSelectedFile(null);
//     setSelectedRows([]);
//     setCcEmails([]);
//     setSendResults({ valid: [], invalid: [] });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     setStatus("Sending...");
  
//     const ccList = ccEmails.join(', ');
    
//     const batchSize = 10;
//     const delay = 20000; // 20 seconds delay between batches
//     const emailBatches = [];
//     for (let i = 0; i < selectedRows.length; i += batchSize) {
//       emailBatches.push(selectedRows.slice(i, i + batchSize));
//     }
    
//     try {
//       let allValidRecipients = [];
//       let allInvalidRecipients = [];

//       for (let i = 0; i < emailBatches.length; i++) {
//         const formattedContent = formatEmailContent(subject, message, ccEmails);
        
//         const formData = new FormData();
//         formData.append('bcc', JSON.stringify(emailBatches[i])); // Changed from 'emails' to 'bcc'
//         formData.append('cc', ccList);
//         formData.append('message', formattedContent);
//         formData.append('subject', subject);
//         if (selectedFile) {
//           formData.append('file', selectedFile);
//         }
    
//         const response = await axios.post("/emailall", formData, {
//           headers: {
//             'Content-Type': 'multipart/form-data',
//           },
//         });
//         if (response.status === 200) {
//           const result = response.data;
//           console.log(result.status);
//           console.log("Valid recipients:", result.validRecipients);
//           console.log("Invalid recipients:", result.invalidRecipients);
          
//           allValidRecipients = [...allValidRecipients, ...result.validRecipients];
//           allInvalidRecipients = [...allInvalidRecipients, ...result.invalidRecipients];
          
//           setSendResults({
//             valid: allValidRecipients,
//             invalid: allInvalidRecipients
//           });
//         } else {
//           console.error(response.data.error);
//         }

//         // Add delay between batches
//         if (i < emailBatches.length - 1) {
//           await new Promise(resolve => setTimeout(resolve, delay));
//         }
//       }
    
//       // Clear form after successful submission
//       handleCancel();
//     } catch (error) {
//       console.error('Error sending email:', error);
//     }
    
//     setStatus("Submit");
//     setIsAnyCheckboxChecked(false);
//     closeModal();
//   };

//   const openModal = () => {
//     setShowModal(true);
//   };

//   const closeModal = () => {
//     setShowModal(false);
//     handleCancel();
//     setIsAnyCheckboxChecked(false);
//   };

//   const toggleCcField = () => {
//     setShowCcField(!showCcField);
//   };

//   const filterSuggestions = (inputValue, suggestions) => {
//     if (!inputValue || !suggestions) {
//       return [];
//     }

//     return suggestions.filter(
//       (suggestion) =>
//         suggestion && suggestion.toLowerCase().includes(inputValue.toLowerCase())
//     );
//   };

//   const handleCcInputChange = (e) => {
//     const inputValue = e.target.value;
//     setCcInputValue(inputValue);
//     setCcEmails(inputValue.split(',').map((email) => email.trim()));
//     const filteredSuggestions = filterSuggestions(inputValue, users.map((user) => user.Email));
//     setCcSuggestions(filteredSuggestions);
//   };

//   return (
//     <div className="container">
//       <h2 style={{ marginBottom: '10px', marginTop: '100px', textAlign: 'center' }}><b>Emails</b></h2>
//       <button className={`compose-btn ${isAnyCheckboxChecked ? 'enabled' : ''}`} disabled={!isAnyCheckboxChecked} onClick={openModal}>Compose</button>
//       <div className="table-responsive">
//         <table className="table table-bordered" style={{ width: '100%', marginTop: '10px' }}>
//           <thead>
//             <tr>
//               <th>
//                 <input
//                   type="checkbox"
//                   checked={selectAll}
//                   onChange={handleSelectAllChange}
//                 />
//               </th>
//               {tableHeadings.map((heading, index) => (
//                 <th key={index}>{heading}</th>
//               ))}
//             </tr>
//           </thead>
//           <tbody>
//             {filteredData.slice().map((item, index) => (
//               <tr key={index}>
//                 <td>
//                   <input
//                     type="checkbox"
//                     checked={selectedRows.includes(item?.Email)}
//                     onChange={() => handleCheckboxChange(item?.Email)}
//                   />
//                 </td>
//                 <td style={{ color: "black" }}>{startIndex + index + 1}</td>
//                 <td style={{ color: "black" }}>{item.company_name}</td>
//                 <td style={{ color: "black" }}>{item.CEO}</td>
//                 <td style={{ color: "black" }}>{item.Phone}</td>
//                 <td style={{ color: "black" }}>{item.Email}</td>
//               </tr>
//             ))}
//           </tbody>
//         </table>
//       </div>

//       <Modal
//         isOpen={showModal}
//         onRequestClose={closeModal}
//         contentLabel="Compose Email"
//         overlayClassName="modal-overlay"
//       >
//         <div className="modal-content">
//           <div className="modal-header">
//             <h4>Compose Email</h4>
//             <button className="close" onClick={closeModal}>
//               &times;
//             </button>
//           </div>
//           <div className="modal-body" style={{height:'500vh'}}>
//             <div className="selected-emails">
//               <h4>Selected Emails:</h4>
//               <ul>
//                 {selectedRows.map((email, index) => (
//                   <li key={index}>{email}</li>
//                 ))}
//               </ul>
//             </div>
//             <form onSubmit={handleSubmit}>
//               <div className="message-box">
//                 <input
//                   name="subject"
//                   value={subject}
//                   onChange={handleSubjectChange}
//                   placeholder="Subject"
//                   className='responsive_textarea'
//                   required
//                 />
//                 <textarea
//                   name="message"
//                   value={message}
//                   onChange={handleMessageChange}
//                   placeholder="Write your message here"
//                   className='responsive_textarea'
//                   style={{marginTop:'10px'}}
//                   required
//                 ></textarea>
//                 <br />
//                 {/* <button type="button" onClick={toggleCcField}>
//                   {showCcField ? 'Hide CC' : ' CC'}
//                 </button>
//                 {showCcField && (
//                   <div>
//                     <input
//                       name="cc"
//                       value={ccInputValue}
//                       onChange={handleCcInputChange}
//                       placeholder="CC"
//                       className='responsive_textarea'
//                       style={{marginTop:'10px'}}
//                     />
//                     {ccSuggestions.length > 0 && (
//                       <ul>
//                         {ccSuggestions.map((suggestion, index) => (
//                           <li key={index} onClick={() => setCcInputValue(suggestion)}>
//                             {suggestion}
//                           </li>
//                         ))}
//                       </ul>
//                     )}
//                   </div>
//                 )} */}
//                 <br />
//                 <input
//                   type="file"
//                   accept=".pdf, .png, .jpg, .jpeg,.docx,"
//                   onChange={handleFileChange}
//                   style={{ marginBottom: "10px", marginTop: "" }}
//                 />
//                 <br />
//                 <div className='button-container'>
//                   <button type="submit" className="pagintion_button" style={{ marginBottom: "10px", marginTop: "-15px" }} disabled={selectedRows.length === 0 || message.trim() === ''}>
//                     {status}
//                   </button>
//                   <button type="button" className="pagintion_button" style={{ marginBottom: "10px", marginTop: "-15px" }} onClick={closeModal}>
//                     Cancel
//                   </button>
//                 </div>
//               </div>
//             </form>
//             {sendResults.valid.length > 0 || sendResults.invalid.length > 0 ? (
//               <div className="send-results">
//                 <h4>Send Results:</h4>
//                 <p>Valid recipients: {sendResults.valid.join(', ')}</p>
//                 <p>Invalid recipients: {sendResults.invalid.join(', ')}</p>
//               </div>
//             ) : null}
//           </div>
//         </div>
//       </Modal>
//     </div>
//   );
// }

// export default Admin;



import React, { useState, useEffect } from 'react';
import './Companies.css';
import './Admin.css';
import axios from 'axios';
import Modal from 'react-modal';

const tableHeadings = ["Serial No.", "Company", "CEO", "Phone", "Email"];
const formatEmailContent = (subject, message, ccEmails, bccEmails) => {
  return `
    <html>
      <head>
        <style>
          body { font-family: Arial, sans-serif; line-height: 1.6; color: #333; }
          .email-content { max-width: 600px; margin: 0 auto; padding: 20px; }
          .subject { font-size: 18px; font-weight: bold; margin-bottom: 10px; }
          .recipients { margin-bottom: 20px; }
          .message { white-space: pre-wrap; }
        </style>
      </head>
      <body>
        <div class="email-content">
     
          <div class="message">${message}</div>
        </div>
      </body>
    </html>
  `;
};

const Admin = () => {
  const [users, setUsers] = useState([]);
  const [status, setStatus] = useState("Submit");
  const [filteredData, setFilteredData] = useState([]);
  const [message, setMessage] = useState('');
  const [selectedRows, setSelectedRows] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectAll, setSelectAll] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [subject, setSubject] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [ccEmails, setCcEmails] = useState([]);
  const [isAnyCheckboxChecked, setIsAnyCheckboxChecked] = useState(false);
  const [sendResults, setSendResults] = useState({ valid: 0, invalid: 0 });
  const [sentEmails, setSentEmails] = useState([]);
  const [failedEmails, setFailedEmails] = useState([]);
  
  useEffect(() => {
    fetchCompanies();
  }, []);

  const fetchCompanies = async () => {
    try {
      const response = await fetch('/company');
      if (response.ok) {
        const data = await response.json();
        const filteredData = data.filter(item => item.company_name);
        const sortedData = filteredData.sort((a, b) => (a.company_name || '').localeCompare(b.company_name || ''));
        setUsers(sortedData);
        setFilteredData(sortedData);
      } else {
        console.error('Failed to fetch companies:', response.status);
      }
    } catch (error) {
      console.error('Error fetching companies:', error);
    }
  };

  const handleCheckboxChange = (email) => {
    setSelectedRows(prevSelectedRows => {
      const newSelectedRows = prevSelectedRows.includes(email)
        ? prevSelectedRows.filter((row) => row !== email)
        : [...prevSelectedRows, email];
      
      setIsAnyCheckboxChecked(newSelectedRows.length > 0);
      return newSelectedRows;
    });
  };
  
  const handleSelectAllChange = () => {
    setSelectAll(!selectAll);
    if (!selectAll) {
      const allEmails = filteredData.map((item) => item.Email);
      setSelectedRows(allEmails);
      setIsAnyCheckboxChecked(true);
    } else {
      setSelectedRows([]);
      setIsAnyCheckboxChecked(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus("Sending...");
  
    const ccList = ccEmails.join(', ');
    const formattedMessage = formatEmailContent(subject, message, ccEmails, selectedRows);
    try {
      const formData = new FormData();
      formData.append('bcc', JSON.stringify(selectedRows));
      formData.append('cc', ccList);
      formData.append('message', formattedMessage);
      formData.append('subject', subject);
      if (selectedFile) {
        formData.append('file', selectedFile);
      }
  
      const response = await axios.post("/emailall", formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
  
      if (response.status === 200) {
        const result = response.data;
        console.log("Email sending results:");
        console.log("----------------------");
        console.log(`Status: ${result.status}`);
        console.log(`Emails sent successfully: ${result.emailsSent}`);
        console.log(`Total emails attempted: ${result.totalEmails}`);
        console.log(`Failed emails: ${result.totalEmails - result.emailsSent}`);
  
        const sentEmails = result.sentEmails || [];
        const failedEmails = result.failedEmails || [];
  
        if (sentEmails.length > 0) {
          console.log("\nSuccessfully sent emails:");
          sentEmails.forEach((email, index) => {
            console.log(`${index + 1}. ${email}`);
          });
        }
  
        if (failedEmails.length > 0) {
          console.log("\nFailed emails:");
          failedEmails.forEach((email, index) => {
            console.log(`${index + 1}. ${email}`);
          });
        }
  
        setSendResults({
          valid: result.emailsSent,
          invalid: result.totalEmails - result.emailsSent
        });
  
      } else {
        console.error(response.data.error);
      }
  
      handleCancel();
    } catch (error) {
      console.error('Error sending email:', error);
    }
  
    setStatus("Submit");
    setIsAnyCheckboxChecked(false);
    closeModal();
  };
  
  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   setStatus("Sending...");

  //   const ccList = ccEmails.join(', ');
    
  //   try {
  //     const formData = new FormData();
  //     formData.append('bcc', JSON.stringify(selectedRows));
  //     formData.append('cc', ccList);
  //     formData.append('message', message);
  //     formData.append('subject', subject);
  //     if (selectedFile) {
  //       formData.append('file', selectedFile);
  //     }
  
  //     const response = await axios.post("/emailall", formData, {
  //       headers: {
  //         'Content-Type': 'multipart/form-data',
  //       },
  //     });

  //     if (response.status === 200) {
  //       const result = response.data;
  //       console.log("Email sending results:");
  //       console.log("----------------------");
  //       console.log(`Status: ${result.status}`);
  //       console.log(`Emails sent successfully: ${result.emailsSent}`);
  //       console.log(`Total emails attempted: ${result.totalEmails}`);
  //       console.log(`Failed emails: ${result.totalEmails - result.emailsSent}`);
        
  //       if (result.emailsSent > 0) {
  //         console.log("\nSuccessfully sent emails:");
  //         result.sentEmails.forEach((email, index) => {
  //           console.log(`${index + 1}. ${email}`);
  //         });
  //       }
        
  //       if (result.totalEmails - result.emailsSent > 0) {
  //         console.log("\nFailed emails:");
  //         result.failedEmails.forEach((email, index) => {
  //           console.log(`${index + 1}. ${email}`);
  //         });
  //       }
  
  //       setSendResults({
  //         valid: result.emailsSent,
  //         invalid: result.totalEmails - result.emailsSent
  //       });
  //     } else {
  //       console.error(response.data.error);
  //     }
  
  
  //     handleCancel();
  //   } catch (error) {
  //     console.error('Error sending email:', error);
  //   }
    
  //   setStatus("Submit");
  //   setIsAnyCheckboxChecked(false);
  //   closeModal();
  // };

  const handleCancel = () => {
    setMessage('');
    setSubject('');
    setSelectedFile(null);
    setSelectedRows([]);
    setCcEmails([]);
    setSendResults({ valid: 0, invalid: 0 });
  };

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    handleCancel();
    setIsAnyCheckboxChecked(false);
  };

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleSearchTermChange = (e) => {
    const newSearchTerm = e.target.value;
    setSearchTerm(newSearchTerm);

    const filtered = users.filter((item) =>
      item && item.company_name && item.company_name.toLowerCase().includes(newSearchTerm.toLowerCase())
    );

    setFilteredData(filtered);
  };

  return (
    <div className="container">
      <h2 style={{ marginBottom: '10px', marginTop: '100px', textAlign: 'center' }}><b>Emails</b></h2>
      <button className={`compose-btn ${isAnyCheckboxChecked ? 'enabled' : ''}`} disabled={!isAnyCheckboxChecked} onClick={openModal}>Compose</button>
      <div className="table-responsive">
        <table className="table table-bordered" style={{ width: '100%', marginTop: '10px' }}>
          <thead>
            <tr>
              <th>
                <input
                  type="checkbox"
                  checked={selectAll}
                  onChange={handleSelectAllChange}
                />
              </th>
              {tableHeadings.map((heading, index) => (
                <th key={index}>{heading}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {filteredData.map((item, index) => (
              <tr key={index}>
                <td>
                  <input
                    type="checkbox"
                    checked={selectedRows.includes(item?.Email)}
                    onChange={() => handleCheckboxChange(item?.Email)}
                  />
                </td>
                <td style={{ color: "black" }}>{index + 1}</td>
                <td style={{ color: "black" }}>{item.company_name}</td>
                <td style={{ color: "black" }}>{item.CEO}</td>
                <td style={{ color: "black" }}>{item.Phone}</td>
                <td style={{ color: "black" }}>{item.Email}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <Modal
        isOpen={showModal}
        onRequestClose={closeModal}
        contentLabel="Compose Email"
        overlayClassName="modal-overlay"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h4>Compose Email</h4>
            <button className="close" onClick={closeModal}>
              &times;
            </button>
          </div>
          <div className="modal-body">
            <div className="selected-emails">
              <h4>Selected Emails:</h4>
              <ul>
                {selectedRows.map((email, index) => (
                  <li key={index}>{email}</li>
                ))}
              </ul>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="message-box">
                <input
                  name="subject"
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                  placeholder="Subject"
                  className='responsive_textarea'
                  required
                />
                <textarea
                  name="message"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  placeholder="Write your message here"
                  className='responsive_textarea'
                  style={{marginTop:'10px'}}
                  required
                ></textarea>
                <br />
                <input
                  type="file"
                  accept=".pdf, .png, .jpg, .jpeg,.docx,"
                  onChange={handleFileChange}
                  style={{ marginBottom: "10px", marginTop: "" }}
                />
                <br />
                <div className='button-container'>
                  <button type="submit" className="pagintion_button" style={{ marginBottom: "10px", marginTop: "-15px" }} disabled={selectedRows.length === 0 || message.trim() === ''}>
                    {status}
                  </button>
                  <button type="button" className="pagintion_button" style={{ marginBottom: "10px", marginTop: "-15px" }} onClick={closeModal}>
                    Cancel
                  </button>
                </div>
              </div>
            </form>
            {sendResults.valid > 0 || sendResults.invalid > 0 ? (
              <div className="send-results">
                <h4>Send Results:</h4>
                <p>Emails sent successfully: {sendResults.valid}</p>
                <p>Failed to send: {sendResults.invalid}</p>
              </div>
            ) : null}
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default Admin;