import React from 'react';

const ThankYouPage = () => {
  return (
    <div style={styles.body}>  <div style={styles.container}>
      <div style={styles.content}>
        <h1 style={styles.title}>Thank You!</h1>
        <p style={styles.message}>Thank You for connecting with Us</p>
        <p style={styles.additionalMessage}>We will get back you.......</p>
      </div>
    </div></div>
  
  );
};

const styles = {
  body:{
    background: '#f0f0f0',
    backgroundImage: 'url("./images/bg.png")',
    backgroundRepeat: 'no-repeat',
    backgroundSize:'cover'
  },
  container: {
    display: 'flex',
    marginLeft:'750px', // Align content to the right
    alignItems: 'center', // Center vertically
    height: '70vh',
    

    // Center the background image
  },
  content: {
    textAlign: 'center',
    padding: '40px',
    borderRadius: '8px',
    backgroundColor: 'white',
    boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.1)', // Box shadow for depth
    maxWidth: '400px',
    width: '100%',
    marginRight: '20px', // Add some margin to separate it from the right edge
  },
  title: {
    fontSize: '2.5rem',
    color: '#333', // Dark title color
    marginBottom: '20px',
  },
  message: {
    fontSize: '1.2rem',
    color: '#666', // Medium gray message color
    marginBottom: '30px',
  },
  additionalMessage: {
    fontSize: '1rem',
    color: '#888', // Light gray additional message color
  },
};
export default ThankYouPage;