import {useState} from 'react'
import NavBar from './NavBar'
import { Link } from 'react-router-dom';
import { FaYoutube } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaPhoneAlt } from "react-icons/fa";
import { IoLocation } from "react-icons/io5";
import './Topheader.css'

const Topheader = () => {

 

    return (
        <body class="home page-template page-template-tpl-default-elementor page-template-tpl-default-elementor-php page page-id-17 wp-custom-logo theme-industry woocommerce-no-js menu-layer elementor-default elementor-kit-9 elementor-page elementor-page-17">
            <main class="boxed_wrapper ">
                <div data-elementor-type="wp-post" data-elementor-id="1675" class="elementor elementor-1675">

                    <section 
                        class="elementor-section elementor-top-section elementor-element elementor-element-97c28f2 elementor-section-boxed elementor-section-height-default elementor-section-height-default my_bg8"
                        data-id="97c28f2" data-element_type="section"
                        data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                        <div class="elementor-container elementor-column-gap-no">
                            <div class="elementor-column elementor-col-20 elementor-top-column elementor-element elementor-element-55a9cfc"
                                data-id="55a9cfc" data-element_type="column">
                                <div class="elementor-widget-wrap elementor-element-populated">
                                    <div class="elementor-element elementor-element-1d3ecdb elementor-widget elementor-widget-image"
                                        data-id="1d3ecdb" data-element_type="widget" data-widget_type="image.default">
                                        <div class="elementor-widget-container">
                                            <a href="">
                                                <img  style={{height:"76px",width:"300px"}}
                                                    src="./images/dL.jpg"
                                                    class="attachment-large size-large wp-image-129" alt=""/> </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="elementor-column elementor-col-20 elementor-top-column elementor-element elementor-element-82bbf68 elementor-hidden-tablet elementor-hidden-mobile"
                                data-id="82bbf68" data-element_type="column">
                                 <div class="elementor-widget-wrap" style={{height:"10px",marginLeft:"10px"}}>
                                <marquee  direction="up" >
                                         
                                            <p style={{color:"white" ,marginLeft:"45px"}}>Mechanical-Companie Automobile-Companies Chemical-Companies Electrical-Companies Petrol chemical-Companies Other-Companies</p>
                                         
                                     </marquee>
                                </div>
                            </div>
                            <div class="elementor-column elementor-col-20 elementor-top-column elementor-element elementor-element-662e66d responsive_phone"
                                data-id="662e66d" data-element_type="column">
                                <div class="elementor-widget-wrap elementor-element-populated">
                                    <div class="elementor-element elementor-element-d204df5 elementor-position-left elementor-view-default elementor-mobile-position-top elementor-vertical-align-top elementor-widget elementor-widget-icon-box"
                                        data-id="d204df5" data-element_type="widget" data-widget_type="icon-box.default">
                                        <div class="elementor-widget-container">
                                        <div class="elementor-icon-box-wrapper" style={{marginLeft:"7px"}}>
                                                <div class="elementor-icon-box-icon">
                                                    <span class="elementor-icon elementor-animation-">
                                                    <FaPhoneAlt style={{fontSize:"large"}}/>
                                                         </span>
                                                </div>
                                                <div class="elementor-icon-box-content">
                                                    <h3 class="elementor-icon-box-title">
                                                        <span>
                                                        +91 9686976679 </span>
                                                    </h3>
                                                </div>
                                            </div>
                                               
                                    
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="elementor-column elementor-col-20 elementor-top-column elementor-element elementor-element-58180e4 responsive_phone"
                                data-id="58180e4" data-element_type="column">
                                <div class="elementor-widget-wrap elementor-element-populated">
                                    <div class="elementor-element elementor-element-9f2423e elementor-position-left elementor-view-default elementor-mobile-position-top elementor-vertical-align-top elementor-widget elementor-widget-icon-box"
                                        data-id="9f2423e" data-element_type="widget" data-widget_type="icon-box.default">
                                        <div class="elementor-widget-container responsive_again_addreess">
                                            <div class="elementor-icon-box-wrapper">
                                                <div class="elementor-icon-box-icon">
                                                    <span class="elementor-icon elementor-animation-" style={{marginLeft:"-13px"}}>
                                                    <IoLocation style={{fontSize:"large"}} />
                                                         </span>
                                                </div>
                                                <div class="elementor-icon-box-content responsive_address" style={{textAlign:"justify"}}>
                                                    <h3 class="elementor-icon-box-title responsive_phone_address" style={{width:"330px"}}>
                                                        <span >
                                                        Plot No.Spl.2(a), 2nd Cross, Dyavasandra Industrial Estate, Whitefield Road, Mahadevpura Post, Bangalore-560048</span>
                                                    </h3>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="elementor-column elementor-col-20 elementor-top-column elementor-element elementor-element-90c4429 responsive_phone"
                                data-id="90c4429" data-element_type="column"
                                data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                <div class="elementor-widget-wrap elementor-element-populated">
                                    <div class="elementor-element elementor-element-f4c9ee3 elementor-align-right elementor-mobile-align-center elementor-widget elementor-widget-button"
                                        data-id="f4c9ee3" data-element_type="widget" data-widget_type="button.default">
                                        <div class="elementor-widget-container">
                                            <div class="elementor-button-wrapper">
                                                <Link class="elementor-button elementor-button-link elementor-size-sm" to="/ContactUs" style={{marginRight:"-19px"}}>
                                                    <span class="elementor-button-content-wrapper">
                                                        <span class="elementor-button-icon elementor-align-icon-left" style={{marginRight:"12px"}}>
                                                            <FaPhoneAlt className="phone"/>
                                                                </span>
                                                        <span class="elementor-button-text">ContactUs</span>
                                                    </span>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                     
                     
                    
                </div>

            </main>

        </body>
    )
}

export default Topheader
