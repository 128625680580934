
import './WhatsAppButton.css'
import { FaWhatsapp } from 'react-icons/fa';

const WhatsAppButton1 = () => {
  return (
    <div class="rs-video whatsapp-fixed">
    <div class="animate-border">
        <a alt="Whatsapp" class="video-vemo-icon btn-whatsapp" aria-label="WhatsApp" href="http://api.whatsapp.com/send?1=pt_BR&amp;phone=919686976679" target="_blank" rel="noopener noreferrer" onclick="gtag_report_conversion(undefined)"><FaWhatsapp style={{fontSize:"40px",marginTop:"-15px"}}/>
          <div class="sm-red-dot"></div>
          <div id="" class="">
            <p>
              
            </p>
            <div class="seta-direita">
            </div>
           </div>
        </a>
    </div>
    <div id="hover-message" class="">
        <p>
        
        </p>
       <div class="seta-direita">
       </div>
    </div>
</div>
  );
};

export default WhatsAppButton1;
