import {Chrono} from 'react-chrono'
import ProjectTimeLine from './ProjectTimeLine'
import CourseTimeline from './CourseTimeline'
import './ProjectTimeLine.css'

const TimelineView = props => {
  const {timelineItemsList} = props

  const getItem = each => {
    if (each.categoryId === 'PROJECT') {
      return <ProjectTimeLine data={each} />
    }
    return <CourseTimeline data={each} />
  }

  return (
    <div className='timeline_container'>
    
    <div>
      <img src="./images/tree1.png" alt="" className='timeline_responsive_tree' style={{borderRadius:"10px",marginTop:"20px",transition: 'transform 0.6s'}} onMouseOver={(e) => e.target.style.transform = 'scale(1.1)'}
        onMouseOut={(e) => e.target.style.transform = 'scale(1)'}/>
    </div>


    </div>
  )
}

export default TimelineView
