import React from 'react'
import './Gallery.css'

const Gallery = () => {
  return (
    <>
       

       <div class="container_gallery">
  <h2 class="gallery_heading" style={{marginLeft:'500px'}}>Gallery</h2>

  <div class="gallery-wrap" style={{marginBottom:"-14px"}}>
    <div class="item_gallery item-1"></div>
    <div class="item_gallery item-2"></div>
    <div class="item_gallery item-3"></div>
    <div class="item_gallery item-4"></div>
    <div class="item_gallery item-5"></div>
    <div class="item_gallery item-11"></div>
    <div class="item_gallery item-12"></div>

  </div><br />
  

  <div class="gallery-wrap">
    <div class="item_gallery item-6"></div>
    <div class="item_gallery item-7"></div>
    <div class="item_gallery item-8"></div>
    <div class="item_gallery item-9"></div>
    <div class="item_gallery item-1"></div>
    <div class="item_gallery item-13"></div>
    <div class="item_gallery item-14"></div>
  </div>
  <br />
  <h2 class="gallery_heading" style={{marginLeft:'400px'}}>Election Awareness Camp</h2>
  <div class="gallery-wrap">
    <div class="item_gallery item-16"></div>
    <div class="item_gallery item-17"></div>
    <div class="item_gallery item-18"></div>
    <div class="item_gallery item-19"></div>
    <div class="item_gallery item-21"></div>
    <div class="item_gallery item-23"></div>
    <div class="item_gallery item-24"></div>
  </div>

 </div>



    </>
  )
}

export default Gallery
