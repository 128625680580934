import React from 'react'
import Topheader from '../Components/Topheader'
import Footer from '../Components/Footer'
import { FaPlus } from "react-icons/fa6";
import { IoIosArrowForward } from "react-icons/io";
import './Solution.css'

const Solution = () => {
  return (
    <>
   
    <div data-elementor-type="wp-page" data-elementor-id="2841" class="elementor elementor-2841">
       	<section class="mr_page_title mr_index_index_title breadcrumb-area" >
		<div class="container">
	    <div class="row">
	        <div class="col-md-12">
	            <div class="breadcrumbs">
	                <h1><b>Services</b></h1>
	            </div>
	        </div>
	    </div>
	</div>
    <div class="breadcrumb-botton">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <ul class="left pull-left">
						<li class="breadcrumb-item "> <a href="/">Home &nbsp;</a></li><IoIosArrowForward/><li class="breadcrumb-item "> Services</li>                    </ul>
                       
                </div>
            </div>
        </div>
    </div>
</section>


		
			<div data-elementor-type="wp-page" data-elementor-id="480" class="elementor elementor-480" style={{marginTop:"30px"}}>
									<section class="elementor-section elementor-top-section elementor-element elementor-element-ce37f7a elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="ce37f7a" data-element_type="section">
						<div class="elementor-container elementor-column-gap-no">
					<div class="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-c3b11d8" data-id="c3b11d8" data-element_type="column">
			<div class="elementor-widget-wrap elementor-element-populated">
								<div class="elementor-element elementor-element-a22bad5 feature_hover elementor-widget elementor-widget-wpsection_service_block_two" data-id="a22bad5" data-element_type="widget" data-widget_type="wpsection_service_block_two.default">
				<div class="elementor-widget-container">
			

		<section class="latest-blog-area">
			<div class="container">
				<div class="row">
					<div class="col-md-12">
						<div class="single-solution-item">
							<div class="title-holder">
								<h3 class="blog-title">AutoMobiles</h3>
								<p class="blog-title"></p>
								<span class="border"></span>
							</div>
							<div class="img-holder">
								<img decoding="async" src="./images/se1.jpg" alt="Awesome Image"/>
								<div class="overlay-style-one">
									<div class="box">
										
									</div>
								</div>
								
							</div>
							<div class="text-holder">
							<p class="blog-title" style={{textAlign:'justify'}}>Automobile companies are industry leaders in designing,
 producing, and distributing vehicles globally, catering to diverse consumer needs.</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		</div>
				</div>
					</div>
		</div>
				<div class="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-f636a72" data-id="f636a72" data-element_type="column">
			<div class="elementor-widget-wrap elementor-element-populated">
								<div class="elementor-element elementor-element-287f82f feature_hover elementor-widget elementor-widget-wpsection_service_block_two" data-id="287f82f" data-element_type="widget" data-widget_type="wpsection_service_block_two.default">
				<div class="elementor-widget-container">
			

		<section class="latest-blog-area">
			<div class="container">
				<div class="row">
					<div class="col-md-12">
						<div class="single-solution-item">
							<div class="title-holder">
								<h3 class="blog-title">Electricals</h3>
								<p class="blog-title"></p>
								<span class="border"></span>
							</div>
							<div class="img-holder">
								<img decoding="async" src="./images/se2.jpg" alt="Awesome Image"/>
								<div class="overlay-style-one">
									<div class="box">
										
									</div>
								</div>
								{/* <div class="icon-holder">
									<span class=" flaticon-halloween"></span>
								</div> */}
							</div>
							<div class="text-holder">
							<p class="blog-title" style={{textAlign:'justify'}}>Electrical companies play a pivotal role in modernizing infrastructure,  efficient power generation, and in a distribution networks worldwide.</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		</div>
				</div>
					</div>
		</div>
				<div class="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-7a2d814" data-id="7a2d814" data-element_type="column">
			<div class="elementor-widget-wrap elementor-element-populated">
								<div class="elementor-element elementor-element-7253d24 feature_hover elementor-widget elementor-widget-wpsection_service_block_two" data-id="7253d24" data-element_type="widget" data-widget_type="wpsection_service_block_two.default">
				<div class="elementor-widget-container">
			

		<section class="latest-blog-area">
			<div class="container">
				<div class="row">
					<div class="col-md-12">
						<div class="single-solution-item">
							<div class="title-holder">
								<h3 class="blog-title">Petro-Chemicals</h3>
								<p class="blog-title"></p>
								<span class="border"></span>
							</div>
							<div class="img-holder">
								<img decoding="async" src="http://diea.in/img/PetrolGas.jpg" alt="Awesome Image"/>
								<div class="overlay-style-one">
									<div class="box">
										
									</div>
								</div>
								{/* <div class="icon-holder">
									<span class=" flaticon-halloween"></span>
								</div> */}
							</div>
							<div class="text-holder">
							<p class="blog-title" style={{textAlign:'justify'}}>The Petrochemical is companies are instrumental in producing a wide range of chemical products derived from petroleum and natural gas.</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		</div>
				</div>
					</div>
		</div>
							</div>
		</section>
				<section class="elementor-section elementor-top-section elementor-element elementor-element-6138f8f elementor-reverse-tablet elementor-reverse-mobile elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="6138f8f" data-element_type="section">
						<div class="elementor-container elementor-column-gap-no">
					<div class="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-5a9ad2c" data-id="5a9ad2c" data-element_type="column">
			<div class="elementor-widget-wrap elementor-element-populated">
								<div class="elementor-element elementor-element-ca3a1fe elementor-widget elementor-widget-wpsection_service_block_two" data-id="ca3a1fe" data-element_type="widget" data-widget_type="wpsection_service_block_two.default">
				<div class="elementor-widget-container">
			

		<section class="latest-blog-area">
			<div class="container">
				<div class="row">
					<div class="col-md-12">
						<div class="single-solution-item">
							<div class="title-holder">
								<h3 class="blog-title">Mechanical-Engineering</h3>
								<p class="blog-title"></p>
								<span class="border"></span>
							</div>
							<div class="img-holder">
								<img decoding="async" src="./images/se3.jpg" alt="Awesome Image"/>
								<div class="overlay-style-one">
									<div class="box">
										
									</div>
								</div>
								{/* <div class="icon-holder">
									<span class=" flaticon-gasoline"></span>
								</div> */}
							</div>
							<div class="text-holder">
							<p class="blog-title" style={{textAlign:'justify'}}>Mechanical companies are pivotal in engineering, manufacturing, and supplying machinery and mechanical components across diverse industries. </p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		</div>
				</div>
					</div>
		</div>
				<div class="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-0d00a93" data-id="0d00a93" data-element_type="column">
			<div class="elementor-widget-wrap elementor-element-populated">
								<div class="elementor-element elementor-element-0823559 elementor-widget elementor-widget-wpsection_service_block_two" data-id="0823559" data-element_type="widget" data-widget_type="wpsection_service_block_two.default">
				<div class="elementor-widget-container">
			

		<section class="latest-blog-area">
			<div class="container">
				<div class="row">
					<div class="col-md-12">
						<div class="single-solution-item">
							<div class="title-holder">
								<h3 class="blog-title">Chemical-Engineering</h3>
								<p class="blog-title"></p>
								<span class="border"></span>
							</div>
							<div class="img-holder">
								<img decoding="async" src="http://diea.in/img/Chemical.jpg" alt="Awesome Image"/>
								<div class="overlay-style-one">
									<div class="box">
										
									</div>
								</div>
								{/* <div class="icon-holder">
									<span class=" flaticon-folder"></span>
								</div> */}
							</div>
							<div class="text-holder">
							<p class="blog-title"style={{textAlign:'justify'}}>Chemical companies are essential in producing a vast array of chemicals used in various applications, including pharmaceuticals, and manufacturing.</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		</div>
				</div>
					</div>
		</div>
				<div class="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-c267b58" data-id="c267b58" data-element_type="column">
			<div class="elementor-widget-wrap elementor-element-populated">
								<div class="elementor-element elementor-element-efb1f84 elementor-widget elementor-widget-wpsection_service_block_two" data-id="efb1f84" data-element_type="widget" data-widget_type="wpsection_service_block_two.default">
				<div class="elementor-widget-container">
			

		<section class="latest-blog-area">
			<div class="container">
				<div class="row">
					<div class="col-md-12">
						<div class="single-solution-item">
							<div class="title-holder">
								<h3 class="blog-title">Others... </h3>
								<p class="blog-title"></p>
								<span class="border"></span>
							</div>
							<div class="img-holder">
								<img decoding="async" src="http://diea.in/img/Others.jpg" alt="Awesome Image"/>
								<div class="overlay-style-one">
									<div class="box">
										
									</div>
								</div>
								
							</div>
							<div class="text-holder">
							<p class="blog-title"style={{textAlign:'justify'}}>Others companies is a heterogeneous group encompassing a wide range of industries, 
from technology and finance to retail and hospitality.</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		</div>
				</div>
					</div>
		</div>
							</div>
		</section>
							</div>

        
    </div>                  

</>
  )
}

export default Solution
